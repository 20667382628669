export const GET_EMPLOYEES = gql`
  query getEmployees($filters: EmployeeFiltersInput, $pageInfo: PageInfoInput) {
    employees(filters: $filters, pageInfo: $pageInfo) {
      items {
        employeeId
        firstName
        lastName
        roleType
        status
        coverImage
        company {
          companyName
        }
      }
      pageInfo {
        offset
        limit
      }
      totalCount
    }
  }
`

export const GET_EMPLOYEES_ONLY = gql`
  query getEmployeesOnly(
    $filters: EmployeeFiltersInput
    $pageInfo: PageInfoInput
  ) {
    employees(filters: $filters, pageInfo: $pageInfo) {
      items {
        employeeId
        firstName
        lastName
        roleType
        status
        coverImage
      }
      pageInfo {
        offset
        limit
      }
      totalCount
    }
  }
`
