import React, { createContext, useContext, useReducer } from 'react'

import { useAuth } from 'src/auth'
import { usePhoneDeviceDispatch } from 'src/context/PhoneDeviceContext'
import { RoleType } from 'src/graphql/types/employees'
import { PhoneDeviceActionType } from 'src/lib/phone-device.reducer'
const WebSocketsContext = createContext({ newNotification: null })
const WebSocketsDispatch = createContext(null)
import {
  subscribeToNotifications,
  subscribeToCallChannel,
} from 'src/lib/websockets'

import { WebSocketsActionType, websocketsReducer } from './websockets.reducer'

export const WebSocketsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(websocketsReducer, {
    newNotification: null,
  })

  const phoneDispatch = usePhoneDeviceDispatch()

  const { client, currentUser } = useAuth()

  const subscribe = async () => {
    const auth0User = await client.getUser()
    if (!auth0User) {
      return
    }
    if (currentUser?.roles?.includes(RoleType.RECRUITER)) {
      await subscribeToNotifications({
        supabaseToken: auth0User.supabaseAccessToken,
        authId: auth0User.sub,
        onMessage: (message) => {
          dispatch({
            type: WebSocketsActionType.NEW_NOTIFICATION,
            payload: message,
          })
        },
      })
      await subscribeToCallChannel({
        supabaseToken: auth0User.supabaseAccessToken,
        authId: auth0User.sub,
        onMessage: (message) => {
          console.log('#####', message, '#####')
          if (message?.new?.callStatus) {
            phoneDispatch({
              type: PhoneDeviceActionType.SET_CALL_STATE,
              payload: {
                callState: message?.new?.callStatus,
                activityLogId: message?.new?.activityLogId,
              },
            })
          }
        },
      })
    }
  }
  subscribe()

  return (
    <WebSocketsContext.Provider value={state}>
      <WebSocketsDispatch.Provider value={dispatch}>
        {children}
      </WebSocketsDispatch.Provider>
    </WebSocketsContext.Provider>
  )
}

export function useWebsockets() {
  return useContext(WebSocketsContext)
}

export function useWebsocketsDispatch() {
  return useContext(WebSocketsDispatch)
}
