export const downloadFile = async ({ url, fileName }) => {
  if (!url) {
    return
  }
  const response = await fetch(url, {
    method: 'GET',
  })
  const buffer = await response.arrayBuffer()

  const defaultExtension = '.pdf'

  const href = window.URL.createObjectURL(new Blob([buffer]))

  // If fileName is provided but lacks an extension, append the correct one.
  if (fileName && !fileName.includes('.')) {
    fileName += defaultExtension
  }

  const link = document.createElement('a')
  link.href = href
  link.download = fileName ?? ''
  link.click()
}
