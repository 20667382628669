/**************** PHONE FORMATTER ****************/

import dayjs from 'dayjs'
import { CountryConfig } from 'src/lib/country.utils'
import { formatSsnString } from 'src/lib/ssn.utils'

/**************** DATE FORMATTER ****************/

// Example: 12022020 -> 12/02/2020
export const formatDate = async (key, e, form) => {
  const value = e.target.value
  let numbers = value.replace(/\D/g, '')
  if (numbers.length > 8) {
    numbers = numbers.slice(0, 8)
  }
  const char = { 2: '/', 4: '/' }
  let formattedValue = ''
  for (let i = 0; i < numbers.length; i++) {
    formattedValue += (char[i] || '') + numbers[i]
  }

  const date = new Date(formattedValue)
  const dateIsValid = !isNaN(date.getTime())

  const result =
    formattedValue.length === 10 && dateIsValid
      ? new Date(formattedValue)
      : formattedValue

  await form.setFieldValue(key, result)
}

// Example: Date() -> 12/02/2020
export const formatDateString = (value) => {
  //check if value is a date
  if (value instanceof Date) {
    return dayjs(value).format('MM/DD/YYYY')
  } else {
    return value
  }
}

// Example: Date() -> 12/2020
export const formatMonthString = (value) => {
  //check if value is a date
  if (value instanceof Date) {
    return dayjs(value).format('MM/YYYY')
  } else {
    return value
  }
}

// Example: 122020 -> 12/2020
export const formatMonthYear = async (key, e, form) => {
  const value = e.target.value
  let numbers = value.replace(/\D/g, '')
  if (numbers.length > 6) {
    numbers = numbers.slice(0, 6)
  }
  const char = { 2: '/' }
  let formattedValue = ''
  for (let i = 0; i < numbers.length; i++) {
    formattedValue += (char[i] || '') + numbers[i]
  }

  const date = formatMonthYearToDate(formattedValue)
  const dateIsValid = date && !isNaN(date.getTime())

  const result =
    formattedValue.length === 7 && dateIsValid ? date : formattedValue

  await form.setFieldValue(key, result)
}

// Example: 12/2020 -> Date(12/01/2020)
export const formatMonthYearToDate = (value) => {
  const formattedDate = value
    ? value.split('/')[0] + '/01/' + value.split('/')[1]
    : undefined
  const result = formattedDate ? new Date(formattedDate) : undefined
  return result
}

/**************** SSN FORMATTER ****************/

// Example: 123456789 -> 123-45-6789
export const formatSsn = async (issuingCountry, ssn, form) => {
  let value = ssn
  let numbers = value.replace(/\D/g, '')
  if (issuingCountry === CountryConfig.US.code && numbers.length > 9) {
    numbers = numbers.slice(0, 9)
  } else if (issuingCountry === CountryConfig.MX.code && numbers.length > 11) {
    numbers = numbers.slice(0, 11)
  }

  const formattedValue = formatSsnString(issuingCountry, numbers)

  await form.setFieldValue('ssn', formattedValue)
  await form.setFieldValue('issuingCountry', issuingCountry)
}
