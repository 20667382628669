import { Menu } from '@mantine/core'
import { MXFlag, USFlag } from 'mantine-flagpack'
import IconChevronDown from 'src/icons/IconChevronDown'
import ApplicationTextInput from './ApplicationTextInput'
import { useEffect, useState } from 'react'
import {
  getCountryCode,
  formatPhone,
  formatMexicanPhoneNumberString,
  formatPhoneNumberString,
  removeCountryCode,
} from 'src/lib/phone.utils'
import { CountryConfig } from 'src/lib/country.utils'

const ApplicationPhoneInput = ({ handleChange, ...props }) => {
  const country = getCountryCode(props.phone)
  const [countryCode, setCountryCode] = useState(
    country || CountryConfig.US.phoneCode
  )
  const formattedPhone = formatPhone(props.phone) //This removes the country code and formats the number accordingly
  const leftSectionWidth = '80px'
  const leftSectionStyles = `flex flex-row items-center gap-1 text-nowrap px-2 font-medium text-doubleNickel-gray-400 hover:cursor-pointer text-base`

  //If the country code changes, we reformat and save the new phone number
  useEffect(() => {
    if (!props.phone) return
    let phone = removeCountryCode(props.phone)
    if (countryCode === CountryConfig.MX.phoneCode) {
      phone = formatMexicanPhoneNumberString(phone)
    } else {
      phone = formatPhoneNumberString(phone)
    }
    phone = countryCode + phone
    handleChange(phone)
  }, [countryCode])

  const countryConfig =
    Object.values(CountryConfig).find(
      (config) => config.phoneCode === countryCode
    ) || CountryConfig.US

  return (
    <ApplicationTextInput
      placeholder={countryConfig.phoneFormatPlaceholder}
      pattern="\d*"
      value={formattedPhone}
      onChange={(event) => {
        if (
          (countryCode === CountryConfig.MX.phoneCode &&
            event.target.value.length > 12) ||
          (countryCode === CountryConfig.US.phoneCode &&
            event.target.value.length > 16)
        )
          return
        //This intercepts the input's changes and re-adds the country code before updating the parent
        const phone = event.target.value
        const result = phone === '' ? phone : countryCode + phone
        handleChange(result)
      }}
      leftSectionWidth={leftSectionWidth}
      leftSection={
        <Menu width={200} shadow="md">
          <Menu.Target>
            <div className={leftSectionStyles}>
              {countryCode}
              <IconChevronDown className="h-4 fill-none stroke-doubleNickel-gray-400" />
            </div>
          </Menu.Target>

          <Menu.Dropdown>
            <Menu.Item
              onClick={() => setCountryCode(CountryConfig.US.phoneCode)}
            >
              <div className="flex flex-row items-center gap-2">
                <USFlag w={30} radius="lg" />
                <div>United States ( {CountryConfig.US.phoneCode} )</div>
              </div>
            </Menu.Item>
            <Menu.Item
              onClick={() => setCountryCode(CountryConfig.MX.phoneCode)}
            >
              <div className="flex flex-row items-center gap-2">
                <MXFlag w={30} radius="lg" />
                <div>Mexico ( {CountryConfig.MX.phoneCode} )</div>
              </div>
            </Menu.Item>
          </Menu.Dropdown>
        </Menu>
      }
      {...props}
    />
  )
}

export default ApplicationPhoneInput
