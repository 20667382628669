import { toast as rwToast } from '@redwoodjs/web/dist/toast'

export const toast = (text, variant = 'success') =>
  rwToast.custom(() => <Toast text={text} variant={variant} />, {
    position: 'bottom-center',
  })

const Toast = ({ text, variant }) => {
  return (
    <div
      className={`rounded-lg p-3 text-sm font-semibold
          ${
            variant == 'success' &&
            'bg-doubleNickel-green-100 text-doubleNickel-success-600'
          }
          ${
            variant == 'warning' &&
            'bg-doubleNickel-warning-50 text-doubleNickel-warning-600'
          }
          ${
            variant == 'error' &&
            'bg-doubleNickel-error-50 text-doubleNickel-error-600'
          }
          `}
    >
      {text}
    </div>
  )
}

export default Toast
