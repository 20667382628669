import { useState } from 'react'

import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import { useQuery } from '@redwoodjs/web'

import { Signature } from 'src/components/Inputs/Signature/Signature'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { GET_SIGNATURE_LINK } from 'src/graphql/consent.graphql'

import ApplicationScreenWrapper from '../components/ApplicationScreenWrapper'
import { useFormContext } from '../utils/FormContext'

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const ReviewScreen = () => {
  const { goBack, applicationId, onSignatureComplete, application } =
    useFormContext()

  const schema = z.object({})
  const [signatureComplete, setSignatureComplete] = useState(false)
  const form = useForm({
    initialValues: {},
    validate: zodResolver(schema),
  })

  const { data: signatureLinkResponse } = useQuery(GET_SIGNATURE_LINK, {
    variables: {
      applicationId,
    },
    onError,
  })

  const submitApplication = () => {
    if (signatureComplete) {
      onSignatureComplete()
    }
  }

  return (
    <ApplicationScreenWrapper
      title={'Sign your application'}
      goBack={goBack}
      saveButtontext={'Submit'}
      saveButtonDisabled={!form.isValid() || !signatureComplete}
      application={application}
      handleCancel={goBack}
      hideButtons={true}
      handleSubmit={() => {
        submitApplication()
      }}
    >
      <div className="col-span-3 flex h-[200vh] w-[100vw] grid-cols-subgrid flex-col gap-10">
        {signatureLinkResponse && (
          <Signature
            signatureUrl={signatureLinkResponse.signatureLink.signatureLink}
            onSignatureComplete={() => {
              setSignatureComplete(true)
              onSignatureComplete()
            }}
            onSignatureError={() => {
              onError()
            }}
          />
        )}
      </div>
    </ApplicationScreenWrapper>
  )
}

export default ReviewScreen
