import { useCallback } from 'react'

import { Menu } from '@mantine/core'

import { useMutation } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { ProfileMenuButton } from 'src/components/Navigation/ProfileMenuButton/ProfileMenuButton'
import StyledMenu from 'src/components/Overlays/StyledMenu/StyledMenu'
import { BEFORE_LOGOUT } from 'src/graphql/users.graphql'
import IconLogout from 'src/icons/IconLogout'

export const ProfileMenu = () => {
  const { logOut } = useAuth()

  const [beforeLogout] = useMutation(BEFORE_LOGOUT, {
    onCompleted: async () => {
      await logOut({
        logoutParams: {
          returnTo: window.location.origin,
        },
      })
    },
  })

  const handleLogout = useCallback(async () => {
    await beforeLogout()
  }, [beforeLogout])

  return (
    <StyledMenu>
      <Menu.Target>
        <ProfileMenuButton />
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item onClick={handleLogout}>
          <div className="flex flex-row items-center gap-2 hover:stroke-doubleNickel-brand-500  ">
            <IconLogout className="h-4 w-4 fill-none" />
            Logout
          </div>
        </Menu.Item>
      </Menu.Dropdown>
    </StyledMenu>
  )
}
