import { useQuery } from '@redwoodjs/web'
import { Input, Loader, Modal } from '@mantine/core'
import { useEffect, useState } from 'react'

import { StepStatus } from 'src/graphql/types/steps'
import { GET_APPLICANTS } from 'src/graphql/applicants.graphql'
import { toast } from '../Toast/Toast'
import { useInView } from 'react-intersection-observer'
import { NetworkStatus } from '@apollo/client'

import { buildFullName, formatSnakeValue } from 'src/lib/formatters'
import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import { formatInternationalPhoneNumber } from 'src/lib/phone.utils'
import { navigate } from '@redwoodjs/router'

const SearchModal = ({ opened, close }) => {
  const [search, setSearch] = useState('')
  const resultsPerPage = 10
  const page = 1

  const { ref, inView } = useInView({
    /* Optional options */
    threshold: 0,
  })

  const {
    data: applicantsData,
    loading,
    fetchMore,
    networkStatus,
  } = useQuery(GET_APPLICANTS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      pageInfo: {
        offset: (page - 1) * resultsPerPage,
        limit: resultsPerPage,
      },
      filters: {
        searchText: search,
      },
      stepFilters: {
        OR: [
          { status: StepStatus.IN_PROGRESS },
          { status: StepStatus.PENDING_REVIEW },
        ],
      },
    },
    onError: (error) => {
      console.log('error', error)
      toast('Something went wrong, please try again.', 'error')
    },
  })

  const applicants = applicantsData?.applicants?.items || []

  useEffect(() => {
    if (inView) {
      fetchMore({
        variables: {
          pageInfo: {
            offset: applicants.length ?? 0,
            limit: resultsPerPage,
          },
        },
      })
    }
  }, [inView])

  const calculateBadeColor = (stage) => {
    switch (stage) {
      case 'PROSPECT':
        return 'gray'
      case 'HIRED':
        return 'green'
      case 'TERMINATED':
        return 'red'
      case 'DISQUALIFIED':
        return 'orange'
      case 'NOT_INTERESTED':
        return 'orange'
      default:
        return 'gray'
    }
  }

  return (
    <Modal
      opened={opened}
      onClose={close}
      yOffset={100}
      overlayProps={{
        backgroundOpacity: 0.2,
        blur: 5,
      }}
      size="45%"
      withCloseButton={false}
      classNames={{
        body: 'pt-4 px-0',
      }}
    >
      <Input
        variant="unstyled"
        placeholder="Search applicants"
        value={search}
        onChange={(event) => setSearch(event.currentTarget.value)}
        className="px-4"
        size="lg"
        rightSection={loading && <Loader size="xs" />}
      />

      {applicants.map((applicant) => (
        <a
          key={applicant.applicantId}
          className="flex flex-col gap-1 px-4 py-3 hover:bg-doubleNickel-gray-100"
          target="_blank"
          href={`/applicants/${applicant.applicantId}`}
        >
          <div className="flex flex-row gap-2 text-base font-medium text-doubleNickel-gray-900">
            {buildFullName(applicant)}
            <StyledBadge
              color={calculateBadeColor(applicant.applicantStage)}
              textTransform="capitalize"
            >
              {applicant.applicantStage === 'PROSPECT'
                ? formatSnakeValue(applicant.hiringStage)
                : formatSnakeValue(applicant.applicantStage)}
            </StyledBadge>
          </div>

          <div className="text-xs text-doubleNickel-gray-500">
            {applicant.jobListing?.title} ·{' '}
            {formatInternationalPhoneNumber(applicant.phone)} ·{' '}
            {applicant.yearsOfExperience
              ? applicant.yearsOfExperience + ' years of experience'
              : 'No experience information available'}{' '}
            · {applicant.callCount} calls made
          </div>
        </a>
      ))}
      {networkStatus === NetworkStatus.fetchMore && (
        <Loader
          size="sm"
          className="flex w-full items-center justify-center "
        />
      )}
      {applicants.length < applicantsData?.applicants.totalCount &&
        !loading && (
          <div ref={ref} className="opacity-0">
            Inview placeholder
          </div>
        )}
    </Modal>
  )
}

export default SearchModal
