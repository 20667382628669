import { useEffect, useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { Combobox, TextInput, useCombobox, Loader } from '@mantine/core'
import { useDebouncedState, useInViewport } from '@mantine/hooks'

import { SEARCH_FMCSA_COMPANY_BY_NAME } from 'src/graphql/fmcsa.graphql'
import IconAlertCircle from 'src/icons/IconAlertCircle'
import { formatAddress } from 'src/lib/address.utils'

export interface CompanyAutocompleteProps {
  label?: string
  placeholder?: string
  onSelect?: (selectedItem) => void
  onChange: (value: string) => void
  required?: boolean
  error?: string
  loadMore?: boolean
  value?: string
  editing?: boolean
  variant?: string
  classNames?: any
}

const CompanyAutocomplete = ({
  label,
  placeholder,
  onSelect,
  onChange,
  required = false,
  error = null,
  loadMore = true,
  value = '',
  editing,
  variant,
  classNames,
  ...props
}: CompanyAutocompleteProps) => {
  const combobox = useCombobox()
  // const [inputvalue, setInputvalue] = useState(value)
  const [inputDebounced, setInputDebounced] = useDebouncedState('', 400)
  const [results, setResults] = useState([])
  const { ref, inViewport } = useInViewport()
  const MINIMUM_CHARACTERS = 2

  const [
    searchQuery,
    { loading: searching, data: searchResults, error: searchError },
  ] = useLazyQuery(SEARCH_FMCSA_COMPANY_BY_NAME, {
    onCompleted: (data) => {
      const newCompanies = data?.searchResults?.companies
      if (data.searchResults.pageInfo.offset === 0) {
        setResults(newCompanies)
      } else {
        setResults((state) => [...state, ...newCompanies])
      }
    },
  })

  const options = results.map((item, index) => {
    const { companyName, dotNumber, alternativeCarrierName, address } = item

    return (
      <Combobox.Option
        className="flex flex-col"
        value={item}
        key={`${dotNumber}-${companyName}-${index}`}
      >
        <span className="flex-1 text-xs font-medium text-doubleNickel-gray-700">{`${companyName} - ${
          alternativeCarrierName || ''
        }`}</span>
        <span className="flex-1 text-xs font-medium text-doubleNickel-gray-400">
          {formatAddress(address)}
        </span>
      </Combobox.Option>
    )
  })

  const handleOptionSubmit = (option) => {
    if (onSelect) {
      onSelect(option)
    }
    combobox.closeDropdown()
  }

  useEffect(() => {
    const trimmed = inputDebounced.trim()
    if (trimmed.length >= MINIMUM_CHARACTERS) {
      searchQuery({ variables: { name: inputDebounced } })
    }
    setResults([])
  }, [inputDebounced])

  useEffect(() => {
    if (loadMore) {
      const { pageInfo, lastPage } = searchResults?.searchResults || {}
      const trimmed = inputDebounced.trim()
      if (
        trimmed.length >= MINIMUM_CHARACTERS &&
        results.length &&
        inViewport &&
        !searching &&
        !lastPage &&
        Number.isInteger(pageInfo?.offset)
      ) {
        searchQuery({
          variables: {
            name: inputDebounced,
            pageInfo: { offset: pageInfo.offset + 1, limit: pageInfo.limit },
          },
        })
      }
    }
  }, [inViewport])

  const renderComboboxOptions = () => {
    if (inputDebounced.trim().length < MINIMUM_CHARACTERS) {
      return (
        <Combobox.Empty className="text-left">
          Enter at least {MINIMUM_CHARACTERS} characters
        </Combobox.Empty>
      )
    }

    if (options.length === 0 && !searching) {
      if (searchError) {
        return (
          <Combobox.Empty className="flex flex-row items-center text-left text-doubleNickel-warning-500">
            <IconAlertCircle className="fill-none stroke-doubleNickel-warning-500" />
            &nbsp; Autocomplete is unavailable
          </Combobox.Empty>
        )
      }

      return (
        <Combobox.Empty className="text-left">
          No suggestions found
        </Combobox.Empty>
      )
    }

    return options
  }

  return (
    <Combobox
      onOptionSubmit={handleOptionSubmit}
      store={combobox}
      withinPortal={false}
      position="bottom-start"
    >
      <Combobox.Target>
        <TextInput
          variant={variant || (editing ? 'default' : 'unstyled')}
          label={label}
          required={required}
          error={error}
          placeholder={placeholder}
          classNames={
            classNames || {
              root: 'flex-1',
              label: 'text-xs font-medium text-doubleNickel-gray-700',
              input: `cursor-pointer
              ${editing ? 'caret-inherit' : 'caret-transparent'}
              [&[data-disabled]]:bg-doubleNickel-white [&[data-disabled]]:opacity-100 [&[data-disabled]]:text-doubleNickel-gray-600`,
            }
          }
          value={value}
          onChange={(event) => {
            if (editing) {
              onChange(event.currentTarget.value)
              setInputDebounced(event.currentTarget.value)
              combobox.resetSelectedOption()
              combobox.openDropdown()
            }
          }}
          onClick={(event) => {
            if (editing) {
              combobox.openDropdown()
              event.stopPropagation()
            }
          }}
          onFocus={() => editing && combobox.openDropdown()}
          onBlur={() => combobox.closeDropdown()}
          {...props}
        />
      </Combobox.Target>

      <Combobox.Dropdown
        className="min-w-96"
        styles={{ dropdown: { maxWidth: '100vh' } }}
        translate="no"
      >
        <Combobox.Options mah={300} className="overflow-y-auto">
          {renderComboboxOptions()}
          <Combobox.Empty
            ref={ref}
            className={`flex min-h-1 flex-row items-center ${
              !options.length && !searching ? 'hidden' : ''
            }`}
          >
            {searching && (
              <div>
                Searching...&nbsp;
                <Loader size={18} />
              </div>
            )}
          </Combobox.Empty>
        </Combobox.Options>
      </Combobox.Dropdown>
    </Combobox>
  )
}

export default CompanyAutocomplete
