const IconPrint = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.0001 5.83317V4.33317C15.0001 3.39975 15.0001 2.93304 14.8184 2.57652C14.6586 2.26292 14.4037 2.00795 14.0901 1.84816C13.7335 1.6665 13.2668 1.6665 12.3334 1.6665H7.66675C6.73333 1.6665 6.26662 1.6665 5.9101 1.84816C5.59649 2.00795 5.34153 2.26292 5.18174 2.57652C5.00008 2.93304 5.00008 3.39975 5.00008 4.33317V5.83317M5.00008 14.9998C4.22511 14.9998 3.83762 14.9998 3.5197 14.9147C2.65697 14.6835 1.9831 14.0096 1.75193 13.1469C1.66675 12.829 1.66675 12.4415 1.66675 11.6665V9.83317C1.66675 8.43304 1.66675 7.73297 1.93923 7.19819C2.17892 6.72779 2.56137 6.34534 3.03177 6.10565C3.56655 5.83317 4.26662 5.83317 5.66675 5.83317H14.3334C15.7335 5.83317 16.4336 5.83317 16.9684 6.10565C17.4388 6.34534 17.8212 6.72779 18.0609 7.19819C18.3334 7.73297 18.3334 8.43304 18.3334 9.83317V11.6665C18.3334 12.4415 18.3334 12.829 18.2482 13.1469C18.0171 14.0096 17.3432 14.6835 16.4805 14.9147C16.1625 14.9998 15.7751 14.9998 15.0001 14.9998M12.5001 8.74984H15.0001M7.66675 18.3332H12.3334C13.2668 18.3332 13.7335 18.3332 14.0901 18.1515C14.4037 17.9917 14.6586 17.7368 14.8184 17.4232C15.0001 17.0666 15.0001 16.5999 15.0001 15.6665V14.3332C15.0001 13.3998 15.0001 12.933 14.8184 12.5765C14.6586 12.2629 14.4037 12.0079 14.0901 11.8482C13.7335 11.6665 13.2668 11.6665 12.3334 11.6665H7.66675C6.73333 11.6665 6.26662 11.6665 5.9101 11.8482C5.59649 12.0079 5.34153 12.2629 5.18174 12.5765C5.00008 12.933 5.00008 13.3998 5.00008 14.3332V15.6665C5.00008 16.5999 5.00008 17.0666 5.18174 17.4232C5.34153 17.7368 5.59649 17.9917 5.9101 18.1515C6.26662 18.3332 6.73333 18.3332 7.66675 18.3332Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconPrint
