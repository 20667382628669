export enum RoleType {
  ADMIN = 'ADMIN',
  RECRUITER = 'RECRUITER',
  DRIVER = 'DRIVER',
}

export enum EmployeeStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}
