const IconPieChart = (props) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3334 11.8269C14.5642 11.8269 14.6797 11.8269 14.7732 11.8781C14.8504 11.9204 14.9222 11.9998 14.9566 12.0808C14.9982 12.179 14.9877 12.2835 14.9668 12.4925C14.8581 13.5758 14.4852 14.6198 13.8765 15.5307C13.144 16.627 12.1028 17.4815 10.8846 17.9861C9.66647 18.4907 8.32602 18.6227 7.03282 18.3655C5.73961 18.1082 4.55172 17.4733 3.61937 16.5409C2.68702 15.6086 2.05208 14.4207 1.79485 13.1275C1.53761 11.8343 1.66964 10.4938 2.17422 9.27566C2.67881 8.05749 3.53329 7.0163 4.62962 6.28375C5.54055 5.67509 6.58446 5.30222 7.66784 5.19352C7.87681 5.17255 7.98129 5.16207 8.07948 5.20373C8.16049 5.2381 8.23988 5.30992 8.28216 5.38709C8.33342 5.48064 8.33342 5.59605 8.33342 5.82688V11.1602C8.33342 11.3936 8.33342 11.5103 8.37883 11.5994C8.41878 11.6778 8.48252 11.7415 8.56092 11.7815C8.65005 11.8269 8.76673 11.8269 9.00009 11.8269H14.3334Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6668 2.49355C11.6668 2.26271 11.6668 2.1473 11.718 2.05375C11.7603 1.97658 11.8397 1.90476 11.9207 1.87039C12.0189 1.82873 12.1234 1.83921 12.3323 1.86017C13.856 2.013 15.2884 2.68713 16.3808 3.7795C17.4732 4.87187 18.1473 6.3043 18.3001 7.82797C18.3211 8.03694 18.3316 8.14143 18.2899 8.23962C18.2555 8.32062 18.1837 8.40001 18.1066 8.4423C18.013 8.49355 17.8976 8.49355 17.6668 8.49355L12.3334 8.49355C12.1001 8.49355 11.9834 8.49355 11.8943 8.44814C11.8159 8.40819 11.7521 8.34445 11.7122 8.26605C11.6668 8.17692 11.6668 8.06024 11.6668 7.82688V2.49355Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconPieChart
