import { createClient } from '@supabase/supabase-js'

let supabase = null

const setBaseConfig = ({ authId, supabaseToken }) => {
  if (!authId) {
    return null
  }
  if (!supabaseToken) {
    return null
  }

  if (!supabase) {
    supabase = createClient(
      process.env.SUPABASE_URL,
      process.env.SUPABASE_ANON_KEY,
      {
        global: {
          headers: {
            Authorization: `Bearer ${supabaseToken}`,
          },
        },
      }
    )
    supabase.realtime.setAuth(supabaseToken)
  }
}

export const subscribeToNotifications = async ({
  supabaseToken,
  authId,
  onMessage,
}) => {
  setBaseConfig({ authId, supabaseToken })

  return supabase
    .channel('notifications')
    .on(
      'postgres_changes',
      {
        event: 'INSERT',
        schema: 'public',
        table: 'notifications',
      },
      onMessage
    )
    .subscribe()
}

export const subscribeToCallChannel = async ({
  supabaseToken,
  authId,
  onMessage,
}) => {
  setBaseConfig({ authId, supabaseToken })
  return supabase
    .channel('CallChannel')
    .on(
      'postgres_changes',
      {
        event: 'INSERT',
        schema: 'public',
        table: 'CallChannel',
      },
      onMessage
    )
    .on(
      'postgres_changes',
      {
        event: 'UPDATE',
        schema: 'public',
        table: 'CallChannel',
      },
      onMessage
    )
    .subscribe()
}
