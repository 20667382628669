const IconJobs = (props) => {
  return (
    <svg
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      width="19"
      height="18"
      viewBox="0 0 19 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 5.99349C13.3333 5.21851 13.3333 4.83102 13.2481 4.51311C13.0169 3.65038 12.3431 2.97651 11.4803 2.74534C11.1624 2.66016 10.7749 2.66016 9.99996 2.66016C9.22498 2.66016 8.8375 2.66016 8.51958 2.74534C7.65685 2.97651 6.98298 3.65038 6.75181 4.51311C6.66663 4.83102 6.66663 5.21851 6.66663 5.99349M10.6666 14.7435H14.75C14.9833 14.7435 15.1 14.7435 15.1891 14.6981C15.2675 14.6581 15.3313 14.5944 15.3712 14.516C15.4166 14.4269 15.4166 14.3102 15.4166 14.0768V12.0768C15.4166 11.8435 15.4166 11.7268 15.3712 11.6377C15.3313 11.5593 15.2675 11.4955 15.1891 11.4556C15.1 11.4102 14.9833 11.4102 14.75 11.4102H10.6666C10.4333 11.4102 10.3166 11.4102 10.2275 11.4556C10.1491 11.4955 10.0853 11.5593 10.0454 11.6377C9.99996 11.7268 9.99996 11.8435 9.99996 12.0768V14.0768C9.99996 14.3102 9.99996 14.4269 10.0454 14.516C10.0853 14.5944 10.1491 14.6581 10.2275 14.6981C10.3166 14.7435 10.4333 14.7435 10.6666 14.7435ZM5.66663 17.6602H14.3333C15.7334 17.6602 16.4335 17.6602 16.9683 17.3877C17.4387 17.148 17.8211 16.7655 18.0608 16.2951C18.3333 15.7604 18.3333 15.0603 18.3333 13.6602V9.99349C18.3333 8.59336 18.3333 7.89329 18.0608 7.35851C17.8211 6.88811 17.4387 6.50566 16.9683 6.26597C16.4335 5.99349 15.7334 5.99349 14.3333 5.99349H5.66663C4.26649 5.99349 3.56643 5.99349 3.03165 6.26597C2.56124 6.50566 2.17879 6.88811 1.93911 7.35851C1.66663 7.89329 1.66663 8.59336 1.66663 9.99349V13.6602C1.66663 15.0603 1.66663 15.7604 1.93911 16.2951C2.17879 16.7655 2.56124 17.148 3.03165 17.3877C3.56643 17.6602 4.26649 17.6602 5.66663 17.6602Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconJobs
