import {
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useEffect,
} from 'react'

import { Menu, Loader, Box } from '@mantine/core'

import { useQuery } from '@redwoodjs/web'

import { GET_VOICEMAIL_TEMPLATES } from 'src/graphql/voiceMailTemplate.graphql'
import IconAlertCircle from 'src/icons/IconAlertCircle'
import IconChevronDown from 'src/icons/IconChevronDown'
import IconClose from 'src/icons/IconClose'

interface VoiceMailTemplatesProps {
  selectedVoiceMailTemplateId?: string
  onClear?: () => void
  onSelect?: (voiceMailTemplateId: string) => void
}
const VoiceMailTemplateSelect = forwardRef(
  (
    { selectedVoiceMailTemplateId, onSelect, onClear }: VoiceMailTemplatesProps,
    ref
  ) => {
    const initialState = {
      title: null,
      voiceMailTemplateId: null,
    }
    const [selected, setSelected] = useState(initialState)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const setInitialState = useCallback(() => setSelected(initialState), [])

    const {
      data: voiceMailTemplatesList,
      loading,
      error,
    } = useQuery(GET_VOICEMAIL_TEMPLATES, {
      variables: {
        pageInfo: {
          limit: 100,
          offset: 0,
        },
        orderBy: {
          field: 'title',
          direction: 'asc',
        },
      },
      onCompleted: () => {
        if (selectedVoiceMailTemplateId) {
          const selectedTemplate =
            voiceMailTemplatesList.voiceMailTemplates.items.find(
              (item) => item.voiceMailTemplateId === selectedVoiceMailTemplateId
            )
          if (selectedTemplate) {
            setSelected(selectedTemplate)
          }
        }
      },
    })

    useImperativeHandle(
      ref,
      () => {
        return {
          resetTemplate() {
            setInitialState()
          },
        }
      },
      []
    )

    const handleClear = useCallback(() => {
      setInitialState()
      onClear && onClear()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleItemClick = useCallback((item) => {
      setSelected(item)
    }, [])

    useEffect(() => {
      if (selected?.voiceMailTemplateId) {
        onSelect(selected.voiceMailTemplateId)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected])

    const renderItems = () => {
      if (voiceMailTemplatesList?.voiceMailTemplates?.items?.length) {
        return voiceMailTemplatesList.voiceMailTemplates.items.map((item) => (
          <Menu.Item
            key={item.voiceMailTemplateId}
            onClick={() => handleItemClick(item)}
            title={item.title}
          >
            {item.title}
          </Menu.Item>
        ))
      }

      if (loading) {
        return (
          <Menu.Item>
            <span className="flex flex-row items-center">
              <Loader size={16} />
              &nbsp; Loading...
            </span>
          </Menu.Item>
        )
      }

      if (error) {
        return (
          <Menu.Item>
            <span className="flex flex-row items-center text-doubleNickel-error-500">
              <IconAlertCircle className="fill-none stroke-doubleNickel-error-500" />
              &nbsp; Something went wrong
            </span>
          </Menu.Item>
        )
      }

      return <Menu.Item>No Voicemail found</Menu.Item>
    }

    return (
      <Menu
        shadow="md"
        classNames={{
          item: 'text-xs font-medium text-doubleNickel-gray-500',
        }}
        position="bottom-start"
        floatingStrategy="absolute"
        disabled={false}
      >
        <Menu.Target>
          {selected.title ? (
            <Box
              className="flex flex-row items-center gap-3 text-xs font-medium text-doubleNickel-gray-500"
              onClick={(event) => {
                event.stopPropagation()
                handleClear()
              }}
              data-testid="clear-trigger"
            >
              {selected.title}
              <IconClose className="h-3 fill-none stroke-doubleNickel-gray-600" />
            </Box>
          ) : (
            <Box
              className="flex flex-row items-center gap-1 text-xs font-medium text-doubleNickel-gray-500"
              data-testid="select-trigger"
            >
              Select Voicemail
              <IconChevronDown className="h-3 fill-none stroke-doubleNickel-gray-600" />
            </Box>
          )}
        </Menu.Target>

        <Menu.Dropdown mah={308} className="overflow-y-auto">
          {renderItems()}
        </Menu.Dropdown>
      </Menu>
    )
  }
)

export default VoiceMailTemplateSelect
