import { useState } from 'react'

import {
  Accordion,
  Group,
  Radio,
  RadioGroup,
  Button as MantineButton,
} from '@mantine/core'
import dayjs from 'dayjs'
import { v1 as uuid } from 'uuid'

import IconAdd from 'src/icons/IconAdd'

import ApplicationAccordionItem from '../components/ApplicationAccordionItem'
import ApplicationRadioQuestion from '../components/ApplicationRadioQuestion'
import ApplicationScreenWrapper from '../components/ApplicationScreenWrapper'
import ApplicationTextInput from '../components/ApplicationTextInput'
import { formatMonthString, formatMonthYear } from '../utils/formatters'
import { useFormContext } from '../utils/FormContext'
import { findErrors } from '../utils/validators'
import ApplicationAddressInput from '../components/ApplicationAddressInput'
import { formatAddress } from 'src/lib/address.utils'

const defaultAccident = {
  city: undefined,
  state: undefined,
  country: undefined,
  description: undefined,
  isPreventable: false,
  isCommercialMotorVehicleInvolved: false,
  accidentDate: undefined,
}

export const MainAccidentsScreen = () => {
  const [accordionValue, setAccordionValue] = useState(null)
  const [controlValue, setControlValue] = useState('false')

  const { form, goBack, handleNext, updateLoading, application } =
    useFormContext()

  const getAccidentTitle = (accident, index) => {
    if (
      accident.accidentDate !== undefined &&
      dayjs(accident.accidentDate).format('MMM, YYYY') !== 'Invalid Date'
    ) {
      return `Event in ${dayjs(accident.accidentDate).format('MMM, YYYY')}`
    } else {
      return `Event ${index + 1}`
    }
  }

  return (
    <ApplicationScreenWrapper
      title={'Accidents & Violations'}
      description="This includes both preventable and non-preventable accidents or violations"
      goBack={goBack}
      application={application}
      updateLoading={updateLoading}
      handleCancel={goBack}
      handleSubmit={() => {
        setAccordionValue(null)
        //Remove keys attribute from the accident object before submitting
        const temp = form.values.accidents.map((accident) => {
          const { key, ...rest } = accident
          return rest
        })
        handleNext({
          accidents: [...temp],
        })
      }}
    >
      <div
        className={`${form.values.accidents.length > 0 ? 'hidden' : ''} w-full`}
      >
        <ApplicationRadioQuestion
          question="Have you had any accidents or violations?"
          value={controlValue}
          handleChange={(e) => {
            if (e === 'false') {
              form.setFieldValue('accidents', [])
            } else {
              setAccordionValue(`accident-${form.values.accidents.length + 1}`)
              const key = uuid()
              const accident = {
                ...defaultAccident,
                key,
              }
              form.setFieldValue('accidents', [
                ...form.values.accidents,
                accident,
              ])
            }
          }}
        />
      </div>
      <Accordion
        chevronPosition="left"
        variant="separated"
        value={accordionValue}
        onChange={setAccordionValue}
        radius={'md'}
        classNames={{
          root: 'w-full sm:col-span-3',
          control: 'h-16',
          item: 'bg-transparent border-doubleNickel-gray-300 shadow-sm',
        }}
      >
        {form.values.accidents.map((accident, index) => (
          <ApplicationAccordionItem
            value={`accident-${index + 1}`}
            key={accident.key}
            title={getAccidentTitle(form.values.accidents[index], index)}
            hasErrors={findErrors(form.errors, 'accidents', index) > 0}
            isCurrent={false}
            handleDelete={() => form.removeListItem('accidents', index)}
          >
            <ApplicationTextInput
              label="When did this event occur?"
              placeholder={'MM / YYYY'}
              required
              pattern="\d*"
              value={formatMonthString(
                form.values.accidents[index].accidentDate
              )}
              error={form.errors[`accidents.${index}.accidentDate`]}
              onChange={(event) => {
                formatMonthYear(`accidents.${index}.accidentDate`, event, form)
              }}
            />
            <ApplicationAddressInput
              label="What city did this event occur in?"
              placeholder="Example: Los Angeles"
              required
              value={form.values.accidents[index]}
              error={
                form.errors[`accidents.${index}.city`] ||
                form.errors[`accidents.${index}.state`]
              }
              type={'(cities)'}
              handleChange={(value) => {
                form.setFieldValue(`accidents.${index}.city`, value.city)
                form.setFieldValue(`accidents.${index}.state`, value.state)
                form.setFieldValue(`accidents.${index}.country`, value.country)
              }}
            />

            <ApplicationTextInput
              label="Please include a short description of the event"
              placeholder="Example: Rear-ended another vehicle"
              required
              {...form.getInputProps(`accidents.${index}.description`)}
            />
            <RadioGroup
              label="Were you driving a commercial vehicle?"
              value={form.values.accidents[
                index
              ].isCommercialMotorVehicleInvolved.toString()}
              onChange={(value) =>
                form.setFieldValue(
                  `accidents.${index}.isCommercialMotorVehicleInvolved`,
                  value === 'true'
                )
              }
              className="flex w-full flex-col justify-between sm:flex-row "
            >
              <Group mt="xs">
                <Radio value="true" label="Yes" />
                <Radio value="false" label="No" />
              </Group>
            </RadioGroup>
            <RadioGroup
              label="Was the event preventable?"
              value={form.values.accidents[index].isPreventable.toString()}
              onChange={(value) =>
                form.setFieldValue(
                  `accidents.${index}.isPreventable`,
                  value === 'true'
                )
              }
              className="flex w-full flex-col justify-between sm:flex-row "
            >
              <Group mt="xs">
                <Radio value="true" label="Yes" />
                <Radio value="false" label="No" />
              </Group>
            </RadioGroup>
          </ApplicationAccordionItem>
        ))}
      </Accordion>

      {form.values.accidents.length > 0 && (
        <div className={`col-span-3 grid-cols-subgrid`}>
          <MantineButton
            size="sm"
            className="h-11"
            variant="light"
            onClick={() => {
              setAccordionValue(`accident-${form.values.accidents.length + 1}`)
              const key = uuid()
              const accident = {
                ...defaultAccident,
                key,
              }
              form.setFieldValue('accidents', [
                ...form.values.accidents,
                accident,
              ])
            }}
          >
            Add another accident/violation
          </MantineButton>
        </div>
      )}
    </ApplicationScreenWrapper>
  )
}
