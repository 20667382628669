export interface CountryConfigItem {
  code: string
  label: string
  phoneCode: string
  phoneFormatPlaceholder: string
  ssnFormatPlaceholder: string
  ssnTitle: string
  abbreviation: string
  validation: {
    ssn: {
      value: RegExp
      message: string
    }
  }
}

export const CountryConfig: Record<string, CountryConfigItem> = {
  US: {
    code: 'US',
    label: 'USA',
    phoneCode: '+1',
    phoneFormatPlaceholder: '(555) 555 - 5555',
    ssnFormatPlaceholder: '555-55-5555',
    ssnTitle: 'Social Security Number',
    abbreviation: 'SSN',
    validation: {
      ssn: {
        value: /^\d{9}$/,
        message: 'Invalid SSN',
      },
    },
  },
  MX: {
    code: 'MX',
    label: 'Mexico',
    phoneCode: '+52',
    phoneFormatPlaceholder: '55-5555-5555',
    ssnFormatPlaceholder: '55555555555',
    ssnTitle: 'Número de Seguridad Social',
    abbreviation: 'NSS',
    validation: {
      ssn: {
        value: /^\d{11}$/,
        message: 'Invalid NSS',
      },
    },
  },
  // Add other countries here
}

export const findIssuingCountry = (
  issuingCountry: string
): CountryConfigItem | undefined => {
  return Object.values(CountryConfig).find(
    (config) => config.code === issuingCountry
  )
}
