import React, { createContext, useContext, useReducer } from 'react'

import { defaultFilters } from '../components/FiltersSiderbar/Filters'
import { filtersReducer } from '../context/applicant-filters.reducer'

const FiltersContext = createContext(defaultFilters)
const FiltersDispatchContext = createContext(null)

export const FiltersProvider = ({ children }) => {
  const [filters, dispatch] = useReducer(filtersReducer, defaultFilters)

  return (
    <FiltersContext.Provider value={filters}>
      <FiltersDispatchContext.Provider value={dispatch}>
        {children}
      </FiltersDispatchContext.Provider>
    </FiltersContext.Provider>
  )
}

export function useFilters() {
  return useContext(FiltersContext)
}

export function useFiltersDispatch() {
  return useContext(FiltersDispatchContext)
}
