const IconClose = (props) => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 1L1 7M1 1L7 7"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconClose
