import { CountryConfig } from 'src/lib/country.utils'

export const getCountryCode = (phoneNumber) => {
  if (!phoneNumber) return null
  // Regular expression to match the country code part of the phone number
  const countryCodeRegex = /^\+1|^\+(\d{2})/
  const match = phoneNumber.match(countryCodeRegex)

  if (match) {
    return match[0] === CountryConfig.US.phoneCode
      ? CountryConfig.US.phoneCode
      : `+${match[1]}`
  } else {
    return null
  }
}

export const removeCountryCode = (phoneNumber) => {
  if (!phoneNumber) return phoneNumber
  // Regular expression to match the country code part of the phone number
  const countryCodeRegex = /^\+1|^\+(\d{2})/
  const match = phoneNumber.match(countryCodeRegex)

  if (match) {
    return phoneNumber.replace(match[0], '')
  } else {
    return phoneNumber
  }
}

// Example: (555) 555 - 5555 -> 5555555555
export const unformatPhoneNumber = (value) => {
  if (!value) return value
  return value.replace(/[^\d+]/g, '')
}

// Example: 5555555555 -> 55-5555-5555 (no form)
export const formatMexicanPhoneNumberString = (value) => {
  if (!value) return value
  let numbers = value.replace(/\D/g, '')
  if (numbers.length > 10) {
    numbers = numbers.slice(0, 10)
  }
  const char = { 2: '-', 6: '-' }
  let formattedValue = ''
  for (let i = 0; i < numbers.length; i++) {
    formattedValue += (char[i] || '') + numbers[i]
  }
  return formattedValue
}

export const formatPhoneNumberString = (value) => {
  if (!value) return value
  let numbers = value.replace(/\D/g, '')
  if (numbers.length > 10) {
    numbers = numbers.slice(0, 10)
  }
  const char = { 0: '(', 3: ') ', 6: ' - ' }
  let formattedValue = ''
  for (let i = 0; i < numbers.length; i++) {
    formattedValue += (char[i] || '') + numbers[i]
  }
  return formattedValue
}

export const formatPhone = (phone) => {
  if (!phone) return phone
  const countryCode = getCountryCode(phone)
  const phoneNumber = removeCountryCode(phone)

  if (countryCode === CountryConfig.MX.phoneCode) {
    return formatMexicanPhoneNumberString(phoneNumber)
  } else {
    return formatPhoneNumberString(phoneNumber)
  }
}

export const formatInternationalPhoneNumber = (
  phoneNumber: string | null | undefined
) => {
  if (!phoneNumber) return undefined

  // Remove all non-digit characters except the plus sign
  phoneNumber = phoneNumber.replace(/[^\d+]/g, '')

  if (!phoneNumber || phoneNumber === '+') return undefined

  // Check for American or Mexican country codes
  if (phoneNumber.startsWith(CountryConfig.US.phoneCode)) {
    // Format American phone number
    return phoneNumber.replace(/(\+1)(\d{3})(\d{3})(\d{4})/, '$1 ($2) $3-$4')
  } else if (phoneNumber.startsWith(CountryConfig.MX.phoneCode)) {
    // Format Mexican phone number
    return phoneNumber.replace(/(\+52)(\d{2})(\d{4})(\d{4})/, '$1 $2-$3-$4')
  } else {
    // Assume the phone number is American and add the country code
    return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, '+1 ($1) $2-$3')
  }
}
