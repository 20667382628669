const IconChevronLeft = (props) => {
  return (
    <svg
      width="8"
      height="12"
      viewBox="0 0 8 12"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 11L1.5 6L6.5 1"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconChevronLeft
