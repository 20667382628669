import ApplicationScreenWrapper from '../components/ApplicationScreenWrapper'
import { useFormContext } from '../utils/FormContext'

import ApplicationCompletedScreen from './ApplicationCompletedScreen'

const SubmitScreen = () => {
  const { application } = useFormContext()

  return (
    <ApplicationScreenWrapper
      hideButtons={true}
      hideBackButton={true}
      application={application}
    >
      <div className="col-span-3 flex h-[20dvh] grid-cols-subgrid flex-col gap-10">
        <ApplicationCompletedScreen
          title="Application submitted"
          subTitle="You will receive a confirmation email. Please contact our support team if you have any questions."
        />
      </div>
    </ApplicationScreenWrapper>
  )
}

export default SubmitScreen
