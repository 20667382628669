import { useState } from 'react'

import { useMutation } from '@redwoodjs/web'

import { toast } from 'src/components/Overlays/Toast/Toast'
import { CREATE_DOCUMENT, UPDATE_DOCUMENT } from 'src/graphql/documents.graphql'
import {
  DocumentCategory,
  DocumentUserStatus,
  FileContentType,
  UploadStatus,
} from 'src/graphql/types/documents'
import { appendCorrectFileExtension } from 'src/pages/ApplicantDetailsPage/tabs/DocumentsTab/components/UploadDocumentModal'

const fileContentTypeByFileType = {
  'image/png': FileContentType.PNG,
  'image/jpeg': FileContentType.JPG,
  'image/jpg': FileContentType.JPG,
  'image/tiff': FileContentType.TIF,
  'image/gif': FileContentType.GIF,
  'image/heic': FileContentType.HEIC,
  'application/pdf': FileContentType.PDF,
  'text/html': FileContentType.HTML,
  'application/msword': FileContentType.DOC,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    FileContentType.DOC,
  'application/zip': FileContentType.ZIP,
  'text/csv': FileContentType.CSV,
  'text/plain': FileContentType.TXT,
}

const useUploadDocument = (
  refetchQueries,
  applicantId,
  step = null,
  voe = null
) => {
  const [isUploading, setIsUploading] = useState(false)

  const onError = () => {
    toast('Something went wrong, please try again.', 'error')
    setIsUploading(false)
  }

  /************ MUTATIONS ************/
  const [updateDocument] = useMutation(UPDATE_DOCUMENT, {
    refetchQueries: refetchQueries,
    onError,
  })

  const [createDocument] = useMutation(CREATE_DOCUMENT, {
    onError,
  })

  /************ MAIN HANDLER ************/
  const handleCreateDocuments = async (files, onDocumentsCreated) => {
    const documents = []
    let index = 0
    setIsUploading(true)

    files.map(async (file) => {
      const fileNameWithExtension = appendCorrectFileExtension(
        file.name,
        file.type
      )

      createDocument({
        variables: {
          input: {
            fileType: fileContentTypeByFileType[file.type],
            category: DocumentCategory.OTHER,
            userStatus: DocumentUserStatus.COMPLETED,
            fileName: fileNameWithExtension,
            expirationDate: file?.expirationDate
              ? new Date(file.expirationDate)
              : null,
            applicantId,
            stepId: step ? step.stepId : null,
            voeId: voe ? voe.voeId : null,
          },
        },
        update: async (cache, { data }) => {
          const createDocument = data.createDocument
          const { uploadUrl } = createDocument

          await fetch(uploadUrl, {
            method: 'PUT',
            body: file,
            headers: {
              'Content-Type': file.type,
            },
          })

          updateDocument({
            variables: {
              id: createDocument.documentId,
              input: {
                uploadStatus: UploadStatus.COMPLETED,
              },
            },
            update: (cache, { data }) => {
              const updateDocument = data.updateDocument
              documents.push(updateDocument.documentId)
              setIsUploading(false)
              if (onDocumentsCreated && index === files.length - 1) {
                onDocumentsCreated(documents)
              }
              index++
            },
          })
        },
      })
    })
  }

  return { handleCreateDocuments, isUploading }
}

export default useUploadDocument
