import { Menu } from '@mantine/core'

const StyledMenu = ({ children, ...props }) => {
  const dropdownStyle = 'rounded-lg shadow-sm'
  const itemStyle =
    'text-sm text-doubleNickel-gray-700 hover:bg-doubleNickel-brand-25 hover:text-doubleNickel-brand-500 hover:stroke-doubleNickel-brand-500 stroke-doubleNickel-gray-700 fill-none overflow-hidden hyphens-auto'

  return (
    <Menu
      width={200}
      position="bottom-start"
      classNames={{
        dropdown: dropdownStyle,
        item: itemStyle,
      }}
      {...props}
    >
      {children}
    </Menu>
  )
}

export default StyledMenu
