import { useState } from 'react'
import { ActionIcon, Modal, Tooltip } from '@mantine/core'
import DocumentPreview from 'src/components/Overlays/DocumentPreview/DocumentPreview'
import IconDocument from 'src/icons/IconDocument'
import { cn } from 'src/utils'

const AttachmentsRow = ({
  attachments,
  applicantId,
  disabled = false,
  disabledText = '',
}) => {
  const [documentForPreview, setDocumentForPreview] = useState(null)

  /************** HANDLERS  **************/
  const handlePreview = (document) => {
    setDocumentForPreview(document)
  }

  return (
    <>
      {documentForPreview && (
        <Modal
          size="90rem"
          lockScroll={false}
          classNames={{
            content: 'min-h-96 h-[100%]',
            body: 'flex h-[95%] flex-col overflow-auto',
          }}
          transitionProps={{ transition: 'fade', duration: 200 }}
          opened={documentForPreview}
          onClose={() => setDocumentForPreview(null)}
          withCloseButton={false}
        >
          <DocumentPreview
            documentId={documentForPreview.documentId}
            applicantId={applicantId}
            showStatus={true}
            showClosebutton={true}
            showBackButton={false}
            onBack={() => setDocumentForPreview(null)}
            showActions={false}
          />
        </Modal>
      )}
      <div className="flex w-full flex-row gap-2 overflow-scroll text-nowrap text-xs">
        {attachments.map((attachment) => (
          <Tooltip
            label={disabled ? disabledText : attachment.fileName}
            key={attachment.documentId}
          >
            <div
              className={cn(
                'flex cursor-pointer flex-row gap-1 rounded border border-doubleNickel-gray-200 p-2 shadow hover:bg-doubleNickel-gray-200',
                disabled && 'opacity-50'
              )}
              onClick={() => {
                if (!disabled) handlePreview(attachment)
              }}
            >
              <ActionIcon variant="transparent" size={16}>
                <IconDocument className="fill-none stroke-doubleNickel-gray-500" />
              </ActionIcon>
              {attachment.fileName}
            </div>
          </Tooltip>
        ))}
      </div>
    </>
  )
}

export default AttachmentsRow
