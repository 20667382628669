const IconRightArrows = (props) => {
  return (
    <svg
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      width="15"
      height="13"
      viewBox="0 0 15 13"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.5 11.5801L6.5 6.58008L1.5 1.58008M8.5 11.5801L13.5 6.58008L8.5 1.58008"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconRightArrows
