import { RecruitersContextProvider } from './RecruitersContext'
import { CurrentCompanyContextProvider } from './CurrentCompanyContext'

const DashboardContexts = ({ children }) => {
  return (
    <RecruitersContextProvider>
      <CurrentCompanyContextProvider>{children}</CurrentCompanyContextProvider>
    </RecruitersContextProvider>
  )
}

export default DashboardContexts
