import React from 'react'

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import humanize from 'humanize-string'
import { StepType as StepTypeApi } from 'types/graphql'
import { StepType } from 'web/src/graphql/types/steps'
import {
  DocumentCategory,
  DocumentCategoryLabel,
} from 'src/graphql/types/documents'

dayjs.extend(relativeTime)

const MAX_STRING_LENGTH = 150

export const formatEnum = (values: string | string[] | null | undefined) => {
  let output = ''

  if (Array.isArray(values)) {
    const humanizedValues = values.map((value) => humanize(value))
    output = humanizedValues.join(', ')
  } else if (typeof values === 'string') {
    output = humanize(values)
  }

  return output
}

export const jsonDisplay = (obj: unknown) => {
  return (
    <pre>
      <code>{JSON.stringify(obj, null, 2)}</code>
    </pre>
  )
}

export const truncate = (value: string | number) => {
  let output = value?.toString() ?? ''

  if (output.length > MAX_STRING_LENGTH) {
    output = output.substring(0, MAX_STRING_LENGTH) + '...'
  }

  return output
}

export const jsonTruncate = (obj: unknown) => {
  return truncate(JSON.stringify(obj, null, 2))
}

export const timeTag = (dateTime?: string) => {
  let output: string | JSX.Element = ''

  if (dateTime) {
    output = (
      <time dateTime={dateTime} title={dateTime}>
        {new Date(dateTime).toUTCString()}
      </time>
    )
  }

  return output
}

export const checkboxInputTag = (checked: boolean) => {
  return <input type="checkbox" checked={checked} disabled />
}

export const formatSnakeValue = (value: string) => {
  if (!value) return value

  return value
    .toLowerCase() // Convert to lower case
    .replace(/_/g, ' ') // Replace underscores with spaces
    .replace(/\b\w/g, (l) => l.toUpperCase()) // Capitalize the first letter of each word
}

export const formatCamelValue = (value) => {
  if (!value) return value

  return value
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
}

export const formatExperience = (
  experience: number | null | undefined,
  shortMode = false
) => {
  if (experience == null) return null

  const years = Math.floor(experience)
  const months = Math.round((experience - years) * 12)
  const yearLabel = shortMode ? 'y' : years === 1 ? 'year' : 'years'
  const monthLabel = shortMode ? 'm' : months === 1 ? 'month' : 'months'

  const parts = []
  if (years > 0) parts.push(`${years} ${yearLabel}`)
  if (months > 0) parts.push(`${months} ${monthLabel}`)

  return (
    parts.join(shortMode ? ', ' : ', ') || (shortMode ? '< 1 m' : '< 1 month')
  )
}

export const formatBytes = (bytes) => {
  if (bytes < 1024) {
    return bytes + ' B'
  } else if (bytes < 1048576) {
    return (bytes / 1024).toFixed(1) + ' KB'
  } else {
    return (bytes / 1048576).toFixed(1) + ' MB'
  }
}

export const formatSidebarAddress = (address) => {
  return address ? `${address.city}, ${address.state}` : '-'
}

export const buildFullName = (user) => {
  if (!user) return null // Return null if the user object is not provided

  // Destructure firstName, middleName, and lastName from the user object
  const { firstName, middleName, lastName } = user

  // Build the full name. Include middleName only if it exists.
  return `${firstName}${middleName ? ` ${middleName}` : ''} ${lastName}`
}

export const formatDate = (date: string | Date): string => {
  const inputDate = dayjs(date)
  const now = dayjs()

  if (now.diff(inputDate, 'day') < 7) {
    // For dates less than 7 days old, return a relative time string
    const descriptor = inputDate < now ? 'ago' : 'from now'
    return inputDate.fromNow(true) + ' ' + descriptor
  } else {
    // For older dates, return a formatted date string
    return inputDate.format('MM/DD/YYYY')
  }
}

export const StepTypeLabels = {
  [StepType.DOT_APP]: { fullName: 'DOT Application', shortName: 'DOT App' },
  [StepType.MVR]: { fullName: 'MVR', shortName: 'MVR' },
  [StepType.PSP]: { fullName: 'PSP', shortName: 'PSP' },
  [StepType.BACKGROUND_CHECK]: {
    fullName: 'Background Check',
    shortName: 'Background',
  },
  [StepType.CDL]: { fullName: 'CDL', shortName: 'CDL' },
  [StepType.CDLIS]: { fullName: 'CDLIS', shortName: 'CDLIS' },
  [StepType.VERIFICATION_OF_EMPLOYMENT]: { fullName: 'VOE', shortName: 'VOE' },
  [StepType.DOT_MEDICAL]: { fullName: 'DOT Med Card', shortName: 'Med Card' },
  [StepType.CLEARINGHOUSE]: {
    fullName: 'Clearinghouse',
    shortName: 'Clearinghouse',
  },
  [StepType.DRUG_AND_ALCOHOL_TESTING]: {
    fullName: 'Drug & Alcohol Testing',
    shortName: 'Drug & Alcohol',
  },
  [StepType.ROAD_TEST]: { fullName: 'Road Test', shortName: 'Road Test' },
}

export const formatStepType = (type: StepTypeApi, useShortName = false) => {
  if (!type) return type

  if (type in StepTypeLabels) {
    return useShortName
      ? StepTypeLabels[type].shortName
      : StepTypeLabels[type].fullName
  }

  return formatSnakeValue(type)
}

export const formatDocumentCategory = (category: DocumentCategory) => {
  if (!category) return category

  if (category in DocumentCategoryLabel) {
    return DocumentCategoryLabel[category]
  }

  return formatSnakeValue(category)
}
