export const validateAllFieldsOld = (formValues) => {
  let hasError = false

  Object.keys(formValues).forEach((fieldName) => {
    const validation = form.validateField(fieldName)

    if (validation.hasError) {
      form.setErrors((prevErrors) => ({
        ...prevErrors,
        [fieldName]: validation?.error ? validation.error : null,
      }))
    }
    hasError = hasError || validation.hasError
  })
  return hasError
}

export const otherValidateFunction = (form, fieldName, index = null) => {
  const value = index ? `${fieldName}.${index}` : fieldName

  const validation = form.validateField(value)
  // if (validation.hasError) {

  //   form.setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [value]: validation?.error ? validation.error : null,
  //   }))

  // }
  return validation.hasError
}

//TODO refactor!
export const validateAllFields = (form, formValues) => {
  let hasError = false
  // //This loops through each key in the given object
  Object.keys(formValues).forEach((fieldName) => {
    //If the value of the key is an array, it will validate each element in the array
    if (Array.isArray(formValues[fieldName])) {
      formValues[fieldName].forEach((address, index) => {
        Object.keys(address).forEach((subFieldName) => {
          const newError = otherValidateFunction(
            form,
            `${fieldName}.${index}.${subFieldName}`
          )
          hasError = hasError || newError
        })
      })
    }
    //If the value of the key is an object, it will validate each value in the object
    else if (typeof formValues[fieldName] === 'object') {
      if (Object.keys(formValues[fieldName]).length > 0) {
        Object.keys(formValues[fieldName]).forEach((subFieldName) => {
          const newError = otherValidateFunction(
            form,
            `${fieldName}.${subFieldName}`
          )
          hasError = hasError || newError
        })
      } else {
        const newError = otherValidateFunction(form, fieldName)
        hasError = hasError || newError
      }
    }
    //If the value of the key is a string, it will run the otherValidateFunction
    else {
      const newError = otherValidateFunction(form, fieldName)
      hasError = hasError || newError
    }
  })
  return hasError
}

export const findErrors = (obj, field, index) => {
  let count = 0
  for (const key in obj) {
    if (key.startsWith(`${field}.${index}`)) {
      count++
    }
  }
  return count
}
