import { useState } from 'react'

import { Accordion, Button as MantineButton } from '@mantine/core'

import ApplicationAccordionItem from '../components/ApplicationAccordionItem'
import ApplicationAddressInput from '../components/ApplicationAddressInput'
import ApplicationScreenWrapper from '../components/ApplicationScreenWrapper'
import ApplicationTextInput from '../components/ApplicationTextInput'
import { formatMonthString, formatMonthYear } from '../utils/formatters'
import { useFormContext } from '../utils/FormContext'
import { findErrors } from '../utils/validators'
import { v1 as uuid } from 'uuid'

export const CurrentAddressScreen = () => {
  const { form, goBack, handleNext, updateLoading, application } =
    useFormContext()

  const [accordionValue, setAccordionValue] = useState('item-0')

  return (
    <ApplicationScreenWrapper
      title={'Address Information'}
      description={
        'Please provide any addresses you have lived at in the past 3 years.'
      }
      goBack={goBack}
      updateLoading={updateLoading}
      application={application}
      handleCancel={goBack}
      handleSubmit={() => {
        setAccordionValue(null)
        //Remove IDs from addresses
        const temp = form.values.addresses.map((address) => {
          const { id, ...rest } = address
          return rest
        })
        handleNext({
          addresses: [...temp],
        })
      }}
    >
      <Accordion
        chevronPosition="left"
        variant="separated"
        value={accordionValue}
        onChange={setAccordionValue}
        radius={'md'}
        classNames={{
          root: 'w-full sm:col-span-3',
          control: 'h-16',
          item: 'bg-transparent border-doubleNickel-gray-300 shadow-sm',
        }}
      >
        <ApplicationAccordionItem
          value={`item-0`}
          key={`item-0`}
          title={
            form.values.addresses[0].street !== ''
              ? form.values.addresses[0].street
              : `Current Address`
          }
          hasErrors={findErrors(form.errors, 'addresses', 0) > 0}
          isCurrent={true}
        >
          <ApplicationAddressInput
            required
            displayStreetOnly
            variant="unstyled"
            placeholder="Current Address"
            value={form.values.addresses[0]}
            error={form.errors[`addresses.${0}.street`]}
            handleChange={(value) => {
              form.setFieldValue(`addresses.${0}.street`, value.street)
              form.setFieldValue(`addresses.${0}.city`, value.city)
              form.setFieldValue(`addresses.${0}.state`, value.state)
              form.setFieldValue(`addresses.${0}.zipCode`, value.zipCode)
              form.setFieldValue(`addresses.${0}.country`, value.country)
            }}
          />
          <div className="flex w-full flex-row gap-2 sm:gap-4">
            <ApplicationTextInput
              pattern="\d*"
              placeholder={'City'}
              required
              value={form.values.addresses[0].city}
              error={form.errors[`addresses.${0}.city`]}
              onChange={(event) => {
                form.setFieldValue(`addresses.${0}.city`, event.target.value)
              }}
            />
            <ApplicationTextInput
              pattern="\d*"
              placeholder={'State'}
              required
              value={form.values.addresses[0].state}
              error={form.errors[`addresses.${0}.state`]}
              onChange={(event) => {
                form.setFieldValue(`addresses.${0}.state`, event.target.value)
              }}
            />
          </div>
          <div className="flex w-full flex-row gap-2 sm:gap-4">
            <ApplicationTextInput
              pattern="\d*"
              placeholder={'Country'}
              required
              value={form.values.addresses[0].country}
              error={form.errors[`addresses.${0}.country`]}
              onChange={(event) => {
                form.setFieldValue(`addresses.${0}.country`, event.target.value)
              }}
            />
            <ApplicationTextInput
              pattern="\d*"
              placeholder={'Zipcode'}
              required
              value={form.values.addresses[0].zipCode}
              error={form.errors[`addresses.${0}.zipCode`]}
              onChange={(event) => {
                form.setFieldValue(`addresses.${0}.zipCode`, event.target.value)
              }}
            />
          </div>
          <div className="flex w-full flex-row gap-2 sm:gap-4">
            <ApplicationTextInput
              pattern="\d*"
              placeholder={'Start Date (MM/YYYY)'}
              required
              value={formatMonthString(form.values.addresses[0].startDate)}
              error={form.errors[`addresses.${0}.startDate`]}
              onChange={(event) => {
                formatMonthYear(`addresses.${0}.startDate`, event, form)
              }}
            />
          </div>
        </ApplicationAccordionItem>

        {form.values.addresses.slice(1).map((address, index) => {
          return (
            <ApplicationAccordionItem
              value={`item-${index + 1}`}
              key={address.id}
              title={
                form.values.addresses[index + 1].street !== ''
                  ? form.values.addresses[index + 1].street
                  : `Address ${index + 2}`
              }
              hasErrors={findErrors(form.errors, 'addresses', index + 1) > 0}
              isCurrent={false}
              handleDelete={() => form.removeListItem('addresses', index + 1)}
            >
              <ApplicationAddressInput
                required
                variant="unstyled"
                displayStreetOnly
                placeholder="Street Address"
                value={form.values.addresses[index + 1]}
                error={form.errors[`addresses.${index + 1}.street`]}
                handleChange={(value) => {
                  form.setFieldValue(
                    `addresses.${index + 1}.street`,
                    value.street
                  )
                  form.setFieldValue(`addresses.${index + 1}.city`, value.city)
                  form.setFieldValue(
                    `addresses.${index + 1}.state`,
                    value.state
                  )
                  form.setFieldValue(
                    `addresses.${index + 1}.zipCode`,
                    value.zipCode
                  )
                  form.setFieldValue(
                    `addresses.${index + 1}.country`,
                    value.country
                  )
                }}
              />
              <div className="flex w-full flex-row gap-2 sm:gap-4">
                <ApplicationTextInput
                  pattern="\d*"
                  placeholder={'City'}
                  required
                  value={form.values.addresses[index + 1].city}
                  error={form.errors[`addresses.${index + 1}.city`]}
                  onChange={(event) => {
                    form.setFieldValue(
                      `addresses.${index + 1}.city`,
                      event.target.value
                    )
                  }}
                />
                <ApplicationTextInput
                  pattern="\d*"
                  placeholder={'State'}
                  required
                  value={form.values.addresses[index + 1].state}
                  error={form.errors[`addresses.${index + 1}.state`]}
                  onChange={(event) => {
                    form.setFieldValue(
                      `addresses.${index + 1}.state`,
                      event.target.value
                    )
                  }}
                />
              </div>
              <div className="flex w-full flex-row gap-2 sm:gap-4">
                <ApplicationTextInput
                  pattern="\d*"
                  placeholder={'Country'}
                  required
                  value={form.values.addresses[index + 1].country}
                  error={form.errors[`addresses.${index + 1}.country`]}
                  onChange={(event) => {
                    form.setFieldValue(
                      `addresses.${index + 1}.country`,
                      event.target.value
                    )
                  }}
                />
                <ApplicationTextInput
                  pattern="\d*"
                  placeholder={'Zipcode'}
                  required
                  value={form.values.addresses[index + 1].zipCode}
                  error={form.errors[`addresses.${index + 1}.zipCode`]}
                  onChange={(event) => {
                    form.setFieldValue(
                      `addresses.${index + 1}.zipCode`,
                      event.target.value
                    )
                  }}
                />
              </div>

              <ApplicationTextInput
                pattern="\d*"
                placeholder={'Start Date (MM/YYYY)'}
                required
                value={formatMonthString(
                  form.values.addresses[index + 1].startDate
                )}
                error={form.errors[`addresses.${index + 1}.startDate`]}
                onChange={(event) => {
                  formatMonthYear(
                    `addresses.${index + 1}.startDate`,
                    event,
                    form
                  )
                }}
              />
              <ApplicationTextInput
                pattern="\d*"
                placeholder={'End Date (MM/YYYY)'}
                required
                value={formatMonthString(
                  form.values.addresses[index + 1].endDate
                )}
                error={form.errors[`addresses.${index + 1}.endDate`]}
                onChange={(event) => {
                  formatMonthYear(`addresses.${index + 1}.endDate`, event, form)
                }}
              />
            </ApplicationAccordionItem>
          )
        })}
      </Accordion>

      <div className={`col-span-3 grid-cols-subgrid`}>
        <MantineButton
          size="sm"
          className="h-11"
          variant="light"
          onClick={() => {
            setAccordionValue(`item-${form.values.addresses.length}`)
            form.setFieldValue('addresses', [
              ...form.values.addresses,
              {
                id: uuid(),
                street: '',
                city: '',
                state: '',
                zipCode: '',
                startDate: '',
                endDate: '',
              },
            ])
          }}
        >
          Add another address
        </MantineButton>
      </div>
    </ApplicationScreenWrapper>
  )
}
