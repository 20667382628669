import { Loader } from '@mantine/core'

const SkeletonLoader = () => {
  return (
    <div className="flex h-screen w-full flex-row items-center justify-center">
      <Loader />
    </div>
  )
}

export default SkeletonLoader
