import { useEffect } from 'react'

import { Select, TextInput, Group } from '@mantine/core'
import { useForm } from '@mantine/form'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import DateInput from 'src/components/Inputs/DateInput/DateInput'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { UPDATE_DOCUMENT } from 'src/graphql/documents.graphql'
import {
  DocumentCategory,
  DocumentUserStatus,
} from 'src/graphql/types/documents'
import { formatSnakeValue, formatDocumentCategory } from 'src/lib/formatters'

const categories = Object.values(DocumentCategory).map((category) => ({
  label: formatDocumentCategory(category),
  value: category,
}))
const statuses = Object.values(DocumentUserStatus)

const onError = () => {
  toast('Something went wrong, please try again.', 'error')
}

const DocumentEditScreen = ({ document, documentId, onRender, onCancel }) => {
  const form = useForm({
    initialValues: {
      category: document.category,
      userStatus: document.userStatus,
      fileName: document.fileName,
      expirationDate: document.expirationDate
        ? new Date(document.expirationDate)
        : null,
    },
  })

  useEffect(() => {
    form.setValues({
      category: document.category,
      userStatus: document.userStatus,
      fileName: document.fileName,
      expirationDate: document.expirationDate
        ? new Date(document.expirationDate)
        : null,
    })
  }, [document])

  const [updateDocument] = useMutation(UPDATE_DOCUMENT, {
    refetchQueries: onRender,
    onCompleted: () => {
      toast('Document updated successfully', 'success')
      onCancel()
    },
    onError,
  })

  const onFormSubmit = (values) => {
    updateDocument({
      variables: {
        id: documentId,
        input: {
          expirationDate: values.expirationDate
            ? new Date(values.expirationDate)
            : null,
          fileName: values.fileName,
          userStatus: values.userStatus,
          category: values.category,
        },
      },
    })
  }

  return (
    <div className="flex h-full flex-col gap-6">
      <div className="flex w-full flex-row items-center justify-start gap-2 ">
        <div className="text-lg font-semibold text-doubleNickel-gray-900">
          Edit Document
        </div>
      </div>
      <form
        onSubmit={form.onSubmit((values) => onFormSubmit(values))}
        className="h-full"
      >
        <div className="flex h-full flex-col justify-between">
          <div className="grid grid-cols-2 gap-4">
            <TextInput
              required
              placeholder={
                form.getInputProps('file').value
                  ? form.getInputProps('file').value.name
                  : 'Enter a file name'
              }
              label="File name"
              {...form.getInputProps('fileName')}
            />
            <DateInput
              label="Expiration Date"
              clearable
              value={form.values.expirationDate}
              onChange={(date) => form.setFieldValue('expirationDate', date)}
            />
            <Select
              withAsterisk
              label="Category"
              placeholder="Select category"
              data={categories}
              searchable
              required
              {...form.getInputProps('category')}
            />

            <Select
              withAsterisk
              label="Status"
              placeholder="Select status"
              data={statuses.map((status) => ({
                label: formatSnakeValue(status),
                value: status,
              }))}
              required
              {...form.getInputProps('userStatus')}
            />
          </div>
          <Group className=" grid grid-cols-2 gap-4 border-t pt-4">
            <Button text="Cancel" variant="outline" onClick={onCancel} />
            <Button
              type="submit"
              disabled={!form.isValid()}
              text="Save"
              variant="filled"
            />
          </Group>
        </div>
      </form>
    </div>
  )
}

export default DocumentEditScreen
