export const GET_NOTIFICATIONS = gql`
  query Notifications(
    $pageInfo: PageInfoInput
    $filters: NotificationsFiltersInput
  ) {
    notifications(pageInfo: $pageInfo, filters: $filters) {
      totalCount
      items {
        notificationId
        createdAt
        updatedAt
        type
        employeeId
        title
        message
        read
        applicant {
          applicantId
          firstName
          lastName
        }
        voe {
          voeId
        }
        activityLog {
          activityLogId
          documents {
            documentId
            status
            fileName
          }
        }
        company {
          companyId
          companyName
        }
      }
    }
  }
`

export const MARK_AS_READ = gql`
  mutation MarkAsRead($id: String!) {
    markAsRead(id: $id)
  }
`

export const REPLY_SMS = gql`
  mutation ReplySMS($id: String!, $message: String!) {
    replySMS(id: $id, message: $message)
  }
`
