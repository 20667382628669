import { FC } from 'react'

import { ActionIcon, Tooltip } from '@mantine/core'

import IconAdd from 'src/icons/IconAdd'

interface ZoomControlsProps {
  zoomIn: () => void
  zoomOut: () => void
  setDocumentRotation: (any) => void
}

const ZoomControls: FC<ZoomControlsProps> = ({
  zoomIn,
  zoomOut,
  setDocumentRotation,
}) => {
  return (
    <div className="flex flex-col items-end justify-end gap-2">
      <Tooltip label="Zoom In" position="left" withArrow>
        <ActionIcon variant="filled" onClick={() => zoomIn()} color="grey">
          <IconAdd className="fill-none stroke-doubleNickel-white" />
        </ActionIcon>
      </Tooltip>
      <Tooltip label="Zoom Out" position="left" withArrow>
        <ActionIcon variant="filled" onClick={() => zoomOut()} color="grey">
          <div className="text-xl text-white"> - </div>
        </ActionIcon>
      </Tooltip>
      <Tooltip label="Rotate" position="left" withArrow>
        <ActionIcon
          variant="filled"
          onClick={() =>
            setDocumentRotation((prev) => (prev === 270 ? 0 : prev + 90))
          }
          color="grey"
        >
          <div className="text-xl text-white"> ↻ </div>
        </ActionIcon>
      </Tooltip>
    </div>
  )
}

export default ZoomControls
