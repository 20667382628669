import { Modal } from '@mantine/core'

import Button from '../../Buttons/Button/Button'
import StyledAlert from '../../Feedback/StyledAlert/StyledAlert'

const MicPermissionDeniedModal = ({ isOpen, onClose }) => {
  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      size="40%"
      classNames={{
        title: 'text-md font-semibold',
      }}
      title={'Microphone Access Denied'}
      centered
    >
      <div className="flex flex-col items-center gap-4">
        <div className="w-full">
          <StyledAlert
            title="We are unable to access your microphone"
            variant="error"
            description={
              'Please follow the steps below to enable microphone access.'
            }
          />
        </div>
        <div className="text-center">
          <p>
            1. Attempt to call again.
            <br />
            2. Click on microphone icon on the address bar.
            <br />
            3. Select "Allow" from the available dropdown.
          </p>
        </div>
        <div className="flex w-full flex-row gap-3 border-t pt-5">
          <Button
            variant="outline"
            text="Cancel"
            className="flex-1"
            onClick={onClose}
          />
          <Button text="Continue" className="flex-1" onClick={onClose} />
        </div>
      </div>
    </Modal>
  )
}

export default MicPermissionDeniedModal
