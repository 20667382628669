export const CREATE_TRACKING_LINK = gql`
  mutation createTrackingLink($input: CreateTrackingLinkInput!) {
    createTrackingLink(input: $input) {
      linkType
      linkName
      jobListingId
    }
  }
`

export const DELETE_TRACKING_LINK = gql`
  mutation deleteTrackingLink($id: String!) {
    deleteTrackingLink(id: $id) {
      trackingLinkId
    }
  }
`

export const GET_TRACKING_LINKS = gql`
  query getTrackingLinks(
    $jobListingId: String! # $pageInfo: PageInfoInput
  ) {
    trackingLinks(jobListingId: $jobListingId) {
      items {
        trackingLinkId
        linkType
        linkName
        link
      }
      pageInfo {
        offset
        limit
      }
      totalCount
    }
  }
`

export const GET_TRACKING_LINK = gql`
  query getTrackingLink($id: String!) {
    trackingLink(id: $id) {
      trackingLinkId
      createdAt
      updatedAt
      linkStatus
      linkType
      linkName
      jobListingId
      link
    }
  }
`

export const GET_TRACKING_LINK_BY_LINK = gql`
  query getTrackingLinkByLink($link: String!) {
    trackingLinkByLink(link: $link) {
      company {
        companyId
        companyName
      }
      trackingLinkId
      createdAt
      updatedAt
      linkStatus
      linkType
      linkName
      jobListingId
      link
    }
  }
`
