import { Select, Tooltip } from '@mantine/core'

import { useMutation } from '@redwoodjs/web'

import { toast } from 'src/components/Overlays/Toast/Toast'
import { UPDATE_DOCUMENT } from 'src/graphql/documents.graphql'
import { DocumentCategory } from 'src/graphql/types/documents'
import { formatDocumentCategory } from 'src/lib/formatters'

const categories = Object.values(DocumentCategory).map((category) => ({
  label: formatDocumentCategory(category),
  value: category,
}))

const DocumentCategoryMenu = ({
  documentId,
  category,
  refetchQueries,
  ...props
}) => {
  const [updateDocument] = useMutation(UPDATE_DOCUMENT, {
    refetchQueries: refetchQueries,
    onCompleted: () => {
      toast('Document updated successfully', 'success')
    },
    onError: () => {
      toast('Something went wrong, please try again.', 'error')
    },
  })

  const handleUpdate = (category) => {
    updateDocument({
      variables: {
        id: documentId,
        input: {
          category: category,
        },
      },
    })
  }

  return (
    <Tooltip label={'Document category'} position="right" withArrow>
      <Select
        data={categories}
        value={category}
        searchable
        classNames={{
          input: 'rounded-lg',
        }}
        comboboxProps={{ shadow: 'md' }}
        onChange={(value) => handleUpdate(value)}
        {...props}
      />
    </Tooltip>
  )
}

export default DocumentCategoryMenu
