import GooglePlacePicker from 'src/pages/ApplicantDetailsPage/tabs/ApplicationTab/components/AddressInput/GooglePlacePicker'

const ApplicationAddressInput = (props) => {
  const isMobile = window.innerWidth < 768
  const labelStyles = 'text-sm text-doubleNickel-gray-600 font-medium'
  const wrapperStyles = 'border-b-[1px] border-doubleNickel-gray-300'
  const inputStyles = `text-base font-medium`
  const rootStyles = `w-full`

  return (
    <GooglePlacePicker
      variant={isMobile ? 'unstyled' : 'unstyled'}
      classNames={{
        label: labelStyles,
        wrapper: wrapperStyles,
        input: inputStyles,
      }}
      className={rootStyles}
      {...props}
    />
  )
}

export default ApplicationAddressInput
