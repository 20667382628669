import { useEffect } from 'react'
import { Divider } from '@mantine/core'
import { WebSocketsActionType } from 'src/pages/ApplicantsPage/context/websockets.reducer'
import {
  useWebsockets,
  useWebsocketsDispatch,
} from 'src/pages/ApplicantsPage/context/WebSocketsContext'

import NotificationTab from './NotificationTab'

const Notifications = () => {
  const websocketContext = useWebsockets()
  const websocketDispatch = useWebsocketsDispatch()

  useEffect(() => {
    if (
      websocketContext?.newNotification?.type &&
      ['SMS', 'VOE_RESPONSE', 'ORDER_UPDATE', 'NOTE'].includes(
        websocketContext?.newNotification?.type
      )
    ) {
      websocketDispatch({
        type: WebSocketsActionType.CLEAR_NEW_NOTIFICATION,
      })
    }
  }, [websocketContext])

  return (
    <div className="flex h-[100%] flex-col gap-4">
      <Divider />
      <NotificationTab />
    </div>
  )
}

export default Notifications
