import CompanyAutocomplete from 'src/pages/ApplicantDetailsPage/tabs/ApplicationTab/components/CompanyAutocomplete/CompanyAutocomplete'

const ApplicationCompanyInput = (props) => {
  const labelStyles = 'text-sm text-doubleNickel-gray-600 font-medium'
  const wrapperStyles = 'border-b-[1px] border-doubleNickel-gray-300'
  const inputStyles = `text-base font-medium`
  const rootStyles = `w-full`

  return (
    <CompanyAutocomplete
      variant="unstyled"
      classNames={{
        label: labelStyles,
        wrapper: wrapperStyles,
        input: inputStyles,
      }}
      className={rootStyles}
      editing
      {...props}
    />
  )
}

export default ApplicationCompanyInput
