export const replaceRecruitersIdWithName = (content, recruiters) => {
  if (!content) return ''
  return content.replace(/{{employee:(.*?)}}/g, (match, id) => {
    const employee = recruiters.find((recruiter) => recruiter.value === id)
    return employee ? `${employee.label}` : match
  })
}

export const replaceNamesWithRecruitersId = (content, recruiters) => {
  const regex = /@([A-Za-zÀ-ÖØ-öø-ÿ]+) ([A-Za-zÀ-ÖØ-öø-ÿ]+)/g
  return content.replace(regex, (match, firstName, lastName) => {
    const employee = recruiters.find(
      (recruiter) => recruiter.label === `${firstName} ${lastName}`
    )
    return employee ? `@{{employee:${employee.value}}}` : match
  })
}
