const IconEmployment = (props) => {
  return (
    <svg
      width="16"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      height="19"
      viewBox="0 0 16 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3334 2.49382C12.1084 2.49382 12.4958 2.49382 12.8138 2.579C13.6765 2.81017 14.3504 3.48404 14.5815 4.34677C14.6667 4.66468 14.6667 5.05217 14.6667 5.82715V13.4938C14.6667 14.8939 14.6667 15.594 14.3942 16.1288C14.1545 16.5992 13.7721 16.9816 13.3017 17.2213C12.7669 17.4938 12.0668 17.4938 10.6667 17.4938H5.33337C3.93324 17.4938 3.23318 17.4938 2.6984 17.2213C2.22799 16.9816 1.84554 16.5992 1.60586 16.1288C1.33337 15.594 1.33337 14.8939 1.33337 13.4938V5.82715C1.33337 5.05217 1.33337 4.66468 1.41856 4.34677C1.64973 3.48404 2.3236 2.81017 3.18633 2.579C3.50424 2.49382 3.89173 2.49382 4.66671 2.49382M5.50004 11.6605L7.16671 13.3271L10.9167 9.57715M6.00004 4.16048H10C10.4668 4.16048 10.7001 4.16048 10.8784 4.06965C11.0352 3.98976 11.1627 3.86228 11.2425 3.70547C11.3334 3.52721 11.3334 3.29386 11.3334 2.82715V2.16048C11.3334 1.69377 11.3334 1.46042 11.2425 1.28216C11.1627 1.12535 11.0352 0.997871 10.8784 0.917976C10.7001 0.827148 10.4668 0.827148 10 0.827148H6.00004C5.53333 0.827148 5.29997 0.827148 5.12172 0.917976C4.96491 0.997871 4.83743 1.12535 4.75754 1.28216C4.66671 1.46042 4.66671 1.69377 4.66671 2.16048V2.82715C4.66671 3.29386 4.66671 3.52721 4.75754 3.70547C4.83743 3.86228 4.96491 3.98976 5.12172 4.06965C5.29997 4.16048 5.53333 4.16048 6.00004 4.16048Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconEmployment
