import { Indicator, Tooltip, UnstyledButton } from '@mantine/core'

import { NavLink, useLocation } from '@redwoodjs/router'

interface SidebarLinkProps {
  icon: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string
    }
  >
  label: string
  route: string
  notification?: boolean
  expanded: boolean
  isActive?: boolean
}

const SidebarLink = ({
  icon: Icon,
  label,
  route,
  notification = false,
  expanded,
  isActive = undefined,
}: SidebarLinkProps) => {
  const location = useLocation()
  isActive = isActive ?? route.split('/')[1] === location.pathname.split('/')[1]

  return (
    <Tooltip
      key={label}
      label={label}
      position="right"
      transitionProps={{ duration: 0 }}
    >
      <Indicator
        color="orange"
        disabled={!notification}
        size={5}
        position="middle-end"
      >
        <NavLink
          to={route}
          className={`text-based flex h-10 flex-row items-center text-sm font-medium text-doubleNickel-gray-600 ${
            expanded
              ? 'min-w-[12rem] gap-4 transition-all duration-500'
              : 'min-w-[2rem] transition-all  duration-500'
          }  p-3 `}
          activeClassName="bg-white rounded-lg"
        >
          <UnstyledButton>
            <Icon
              className={`h-4 w-4 fill-none ${
                isActive
                  ? 'stroke-doubleNickel-brand-500'
                  : 'stroke-doubleNickel-gray-600'
              }`}
            />
          </UnstyledButton>
          {expanded && (
            <div
              className={`${
                isActive
                  ? 'text-doubleNickel-brand-500'
                  : 'text-doubleNickel-gray-600'
              }`}
            >
              {label}
            </div>
          )}
        </NavLink>
      </Indicator>
    </Tooltip>
  )
}

export default SidebarLink
