export enum PhoneDeviceActionType {
  CALL,
  SET_PHONE_DEVICE,
  SET_CALL_STATE,
  OPEN_DEVICE_MODAL,
  CLOSE_DEVICE_MODAL,
}

import { PhoneDeviceContextState } from 'src/context/PhoneDeviceContext'

export const phoneDeviceReducer = (
  state: PhoneDeviceContextState = {
    phoneDevice: null,
    callState: null,
    call: null,
    phone: null,
    applicantId: null,
    applicantFullName: null,
    openDeviceModal: function (): void {
      throw new Error('Function not implemented.')
    },
    closeDeviceModal: function (): void {
      throw new Error('Function not implemented.')
    },
    openedDeviceModal: false,
  },
  action
) => {
  const { payload } = action
  switch (action.type) {
    case PhoneDeviceActionType.CALL: {
      return {
        ...state,
        ...payload,
        openedDeviceModal: true,
      }
    }
    case PhoneDeviceActionType.SET_PHONE_DEVICE: {
      return {
        ...state,
        phoneDevice: payload,
      }
    }
    case PhoneDeviceActionType.SET_CALL_STATE: {
      return {
        ...state,
        ...payload,
      }
    }
    case PhoneDeviceActionType.OPEN_DEVICE_MODAL: {
      return {
        ...state,
        ...payload,
        openedDeviceModal: true,
      }
    }
    case PhoneDeviceActionType.CLOSE_DEVICE_MODAL: {
      return {
        ...state,
        openedDeviceModal: false,
      }
    }
    default: {
      return state
    }
  }
}
