import { StepFilter, ApplicantStageFilter } from 'types/graphql'

import {
  ApplicantStage,
  DisqualifiedReason,
  HiringStage,
  NotInterestedReason,
  TerminatedReason,
} from 'src/graphql/types/applicants'
import { StepType, StepStatus } from 'src/graphql/types/steps'
import { formatSnakeValue, formatStepType } from 'src/lib/formatters'

import {
  MultiSelectFilter,
  Option,
} from '../MultiSelectFilter/MultiSelectFilter'

export interface Filters {
  applicantStages?: ApplicantStageFilters
  yearsOfExperience?: RangeFilter
  callCount?: RangeFilter
  recruiters?: MultiSelectFilter
  jobListings?: MultiSelectFilter
  searchText?: SearchFilter
  applicationDate?: DateRangeFilter
  steps?: StepFilters
}

export interface Filter {
  type:
    | 'multi-select'
    | 'range'
    | 'search'
    | 'date-range'
    | 'step-filter'
    | 'applicant-stage-filter'
  key: string
  title?: string
}

export interface MultiSelectFilter extends Filter {
  options: Option[]
  selectedValues?: string[]
}

export interface SearchFilter extends Filter {
  searchText: string
}

export interface RangeFilter extends Filter {
  lte: number
  gte: number
  min: number
  max: number
}

export interface DateRangeFilter extends Filter {
  lte: Date
  gte: Date
}

export interface MultiOption extends Option {
  options: Option[]
}

export interface StepFilters extends Filter {
  options: MultiOption[]
  selectedValues?: StepFilter[]
}

export interface ApplicantStageFilters extends Filter {
  options: MultiOption[]
  selectedValues?: ApplicantStageFilter[]
}

export const defaultFilters: Filters = {
  applicantStages: {
    type: 'applicant-stage-filter',
    title: 'Applicant Stage',
    key: 'applicantStages',
    selectedValues: [
      {
        applicantStage: ApplicantStage.PROSPECT,
      },
    ],
    options: Object.values(ApplicantStage).map((applicantStage) => {
      if (applicantStage === ApplicantStage.PROSPECT) {
        return {
          value: applicantStage,
          label: formatSnakeValue(applicantStage),
          selected: true,
          options: Object.values(HiringStage).map((hiringStage) => ({
            value: hiringStage,
            label: formatSnakeValue(hiringStage),
            selected: false,
          })),
        }
      }
      if (applicantStage === ApplicantStage.HIRED) {
        return {
          value: applicantStage,
          label: formatSnakeValue(applicantStage),
          selected: false,
          options: [],
        }
      }
      if (applicantStage === ApplicantStage.DISQUALIFIED) {
        return {
          value: applicantStage,
          label: formatSnakeValue(applicantStage),
          selected: false,
          options: Object.values(DisqualifiedReason).map((reason) => ({
            value: reason,
            label: formatSnakeValue(reason),
            selected: false,
          })),
        }
      }
      if (applicantStage === ApplicantStage.NOT_INTERESTED) {
        return {
          value: applicantStage,
          label: formatSnakeValue(applicantStage),
          selected: false,
          options: Object.values(NotInterestedReason).map((reason) => ({
            value: reason,
            label: formatSnakeValue(reason),
            selected: false,
          })),
        }
      }
      if (applicantStage === ApplicantStage.TERMINATED) {
        return {
          value: applicantStage,
          label: formatSnakeValue(applicantStage),
          selected: false,
          options: Object.values(TerminatedReason).map((reason) => ({
            value: reason,
            label: formatSnakeValue(reason),
            selected: false,
          })),
        }
      }
    }),
  } as ApplicantStageFilters,
  yearsOfExperience: {
    type: 'range',
    title: 'Years of experience',
    min: 0,
    max: 30,
    lte: null,
    gte: null,
    key: 'yearsOfExperience',
  } as RangeFilter,
  callCount: {
    type: 'range',
    title: 'Call count',
    min: 0,
    max: 20,
    lte: null,
    gte: null,
    key: 'callCount',
  } as RangeFilter,
  recruiters: {
    type: 'multi-select',
    title: 'Recruiter',
    options: [],
    key: 'recruiters',
  } as MultiSelectFilter,
  jobListings: {
    type: 'multi-select',
    title: 'Position',
    options: [],
    key: 'jobListings',
  } as MultiSelectFilter,
  searchText: {
    type: 'search',
    key: 'searchText',
    searchText: '',
  } as SearchFilter,
  applicationDate: {
    type: 'date-range',
    lte: null,
    gte: null,
    key: 'applicationDate',
  } as DateRangeFilter,
  steps: {
    type: 'step-filter',
    title: 'Steps',
    key: 'steps',
    options: Object.values(StepType).map((type) => ({
      value: type,
      label: formatStepType(type),
      selected: false,
      options: Object.values(StepStatus).map((status) => ({
        value: status,
        label: formatSnakeValue(status),
        selected: false,
      })),
    })),
  } as StepFilters,
}
