export const GET_VOICEMAIL_TEMPLATES = gql`
  query GET_VOICEMAIL_TEMPLATES(
    $pageInfo: PageInfoInput
    $orderBy: OrderByInput
  ) {
    voiceMailTemplates(pageInfo: $pageInfo, orderBy: $orderBy) {
      totalCount
      pageInfo {
        offset
        limit
      }
      items {
        voiceMailTemplateId
        title
        createdAt
        updatedAt
        path
        createdBy {
          employeeId
          firstName
          lastName
        }
      }
    }
  }
`

export const EDIT_VOICEMAIL_TEMPLATE = gql`
  mutation EDIT_VOICEMAIL_TEMPLATE(
    $voiceMailTemplateId: String!
    $input: UpdateVoiceMailTemplateInput!
  ) {
    updateVoiceMailTemplate(
      voiceMailTemplateId: $voiceMailTemplateId
      input: $input
    ) {
      voiceMailTemplateId
      uploadUrl
    }
  }
`

export const CREATE_VOICEMAIL_TEMPLATE = gql`
  mutation CREATE_VOICEMAIL_TEMPLATE($input: CreateVoiceMailTemplateInput!) {
    createVoiceMailTemplate(input: $input) {
      voiceMailTemplateId
      uploadUrl
    }
  }
`

export const DELETE_VOICEMAIL_TEMPLATE = gql`
  mutation DELETE_VOICEMAIL_TEMPLATE($voiceMailTemplateId: String!) {
    deleteVoiceMailTemplate(voiceMailTemplateId: $voiceMailTemplateId) {
      voiceMailTemplateId
    }
  }
`
export const GET_DOWNLOAD_URL = gql`
  query DownloadUrl($voiceMailTemplateId: String!) {
    voiceMailDownloadUrl(voiceMailTemplateId: $voiceMailTemplateId) {
      url
      fileName
    }
  }
`

export const PLAY_VOICEMAIL_IN_ACTIVE_CALL = gql`
  mutation PLAY_VOICEMAIL_IN_ACTIVE_CALL($input: PlayVoicemailTemplateInput!) {
    playVoiceMailInActiveCall(input: $input) {
      sent
      companyId
      voiceMailTemplateId
    }
  }
`
