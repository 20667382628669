export const GET_DOCUMENTS = gql`
  query GetDocuments(
    $applicantId: String!
    $filters: DocumentFiltersInput
    $pageInfo: PageInfoInput
  ) {
    documents(
      applicantId: $applicantId
      filters: $filters
      pageInfo: $pageInfo
    ) {
      totalCount
      items {
        documentId
        createdAt
        updatedAt
        status
        category
        userStatus
        fileName
        fileType
        path
        expirationDate
        isRenewed
        uploadedBy {
          employeeId
          firstName
          lastName
        }
        voe {
          voeId
        }
      }
    }
  }
`

export const CREATE_DOCUMENT = gql`
  mutation CreateDocument($input: CreateDocumentInput!) {
    createDocument(input: $input) {
      documentId
      uploadUrl
    }
  }
`

export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($id: String!, $applicantId: String!) {
    deleteDocument(id: $id, applicantId: $applicantId) {
      documentId
    }
  }
`

export const GET_DOCUMENT_DOWNLOAD_URL = gql`
  query DocumentDownloadUrl($id: String!, $applicantId: String!) {
    documentDownloadUrl(id: $id, applicantId: $applicantId) {
      url
      fileName
      fileType
      userStatus
      category
      expirationDate
    }
  }
`

export const UPDATE_DOCUMENT = gql`
  mutation UpdateDocument($id: String!, $input: UpdateDocumentInput!) {
    updateDocument(id: $id, input: $input) {
      documentId
      createdAt
      updatedAt
      deletedAt
      status
      userStatus
      uploadStatus
      category
      fileType
      expirationDate
      fileName
      path
      uploadUrl
    }
  }
`
export const SET_DOCUMENT_RENEWED = gql`
  mutation SetDocumentRenewed(
    $documentId: String!
    $applicantId: String!
    $isRenewed: Boolean!
  ) {
    setDocumentRenewed(
      documentId: $documentId
      applicantId: $applicantId
      isRenewed: $isRenewed
    ) {
      documentId
      isRenewed
    }
  }
`

export const GET_EXPIRING_DOCUMENT_SUMMARY = gql`
  query DocumentsExpirationSummary($filters: DocumentsExpirationFilters) {
    documentsExpirationSummary(filters: $filters) {
      name
      value
    }
  }
`

export const GET_EXPIRING_DOCUMENT_BY_CATEGORY = gql`
  query DocumentsExpirationByCategory(
    $filters: DocumentsExpirationFilters
    $pageInfo: PageInfoInput
  ) {
    documentsExpirationByCategory(filters: $filters, pageInfo: $pageInfo) {
      items {
        documentId
        fileName
        category
        expirationDate
        applicant {
          applicantId
          firstName
          lastName
          fullName
        }
      }
      totalCount
    }
  }
`
