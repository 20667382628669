import { Address } from 'types'

// This function is used to parse the address from AddressInput (@vis.gl/react-google-maps library)
export function parseGoogleAddress(place) {
  let streetNumber = ''
  let route = ''
  let subpremise = ''
  let city = ''
  let state = ''
  let country = ''
  let zipCode = ''
  if (place.address_components) {
    for (const component of place.address_components) {
      if (component.types.includes('street_number')) {
        streetNumber = component.long_name
      } else if (component.types.includes('route')) {
        route = component.long_name
      } else if (component.types.includes('subpremise')) {
        subpremise = component.long_name
      } else if (
        component.types.includes('locality') ||
        component.types.includes('sublocality')
      ) {
        city = component.long_name
      } else if (component.types.includes('administrative_area_level_1')) {
        state = component.short_name
      } else if (component.types.includes('country')) {
        country = component.short_name
      } else if (component.types.includes('postal_code')) {
        zipCode = component.long_name
      }
    }
  }

  const streetAddress = `${streetNumber} ${route} ${subpremise}`
  return {
    street: streetAddress.trim(),
    city,
    state,
    country,
    zipCode,
  }
}

// This function is used to parse the address from GooglePlacePicker (@googlemaps/extended-component-library/react library)
export function parseReactGoogleAddress(place) {
  let streetNumber = ''
  let route = ''
  let subpremise = ''
  let city = ''
  let state = ''
  let country = ''
  let zipCode = ''
  if (place.addressComponents) {
    for (const component of place.addressComponents) {
      if (component.types.includes('street_number')) {
        streetNumber = component.longText
      } else if (component.types.includes('route')) {
        route = component.longText
      } else if (component.types.includes('subpremise')) {
        subpremise = component.longText
      } else if (
        component.types.includes('locality') ||
        component.types.includes('sublocality')
      ) {
        city = component.longText
      } else if (component.types.includes('administrative_area_level_1')) {
        state = component.shortText
      } else if (component.types.includes('country')) {
        country = component.shortText
      } else if (component.types.includes('postal_code')) {
        zipCode = component.longText
      }
    }
  }

  const streetAddress = `${streetNumber} ${route} ${subpremise}`

  return {
    street: streetAddress.trim(),
    city,
    state,
    country,
    zipCode,
  }
}

export function formatAddress(address: Address) {
  let result = ''
  if (
    !address ||
    (!address.street && !address.city && !address.state && !address.zipCode)
  ) {
    return result
  }

  if (address.street) {
    result += address.street
  }
  if (address.city) {
    result += (result ? ', ' : '') + address.city
  }
  if (address.state) {
    result += (result ? ', ' : '') + address.state
  }
  if (address.country) {
    result += (result ? ', ' : '') + address.country
  }
  if (address.zipCode) {
    result += (result ? ', ' : '') + address.zipCode
  }
  return result
}
