import cloneDeep from 'lodash.clonedeep'
import { ApplicantStageFilter, StepFilter } from 'types/graphql'

import { ApplicantStage } from 'src/graphql/types/applicants'

import { defaultFilters } from '../components/FiltersSiderbar/Filters'

export enum ApplicantFiltersActionType {
  FILTERS_CHANGED,
  FILTER_REMOVED,
  SEARCHBOX_CHANGED,
  APPLICANTION_DATE_CHANGED,
  CLEAN_SEARCHBOX,
  CLEAN_RANGE_FILTER,
  CLEAN_MULTI_SELECT,
  CLEAN_STEP_FILTER,
  CLEAR_APPLICANT_STAGE_FILTER,
  CLEAR_ALL,
  FILTER_BY_HIRING_STAGE_METRIC,
  LOAD_JOB_LISTINGS,
  FILTER_BY_JOB_LISTING_ID,
}

export const filtersReducer = (filters, action) => {
  const { payload } = action
  switch (action.type) {
    case ApplicantFiltersActionType.FILTERS_CHANGED: {
      return {
        ...payload,
      }
    }
    case ApplicantFiltersActionType.SEARCHBOX_CHANGED: {
      return {
        ...filters,
        searchText: { ...filters.searchText, searchText: payload.text },
      }
    }
    case ApplicantFiltersActionType.APPLICANTION_DATE_CHANGED: {
      return {
        ...filters,
        applicationDate: {
          ...filters.applicationDate,
          gte: payload.from,
          lte: payload.to,
        },
      }
    }
    case ApplicantFiltersActionType.CLEAN_SEARCHBOX: {
      return {
        ...filters,
        searchText: { ...filters.searchText, searchText: '' },
      }
    }
    case ApplicantFiltersActionType.CLEAN_RANGE_FILTER: {
      return {
        ...filters,
        [action.payload.filterKey]: {
          ...filters[action.payload.filterKey],
          gte: null,
          lte: null,
          gt: null,
          lt: null,
        },
      }
    }
    case ApplicantFiltersActionType.CLEAN_MULTI_SELECT: {
      const { key, value } = payload
      const valueIdx = filters[key].selectedValues.findIndex(
        (selectedValue) => value === selectedValue
      )

      const selectedValues = [...filters[key].selectedValues]
      selectedValues.splice(valueIdx, 1)

      const options = filters[key].options.map((option) => {
        if (option.value == value) {
          return {
            ...option,
            selected: false,
          }
        } else {
          return option
        }
      })

      return {
        ...filters,
        [key]: {
          ...filters[key],
          selectedValues,
          options,
        },
      }
    }
    case ApplicantFiltersActionType.CLEAN_STEP_FILTER: {
      const filtersCopy = cloneDeep(filters)
      const filter = payload

      const stepType = (filter.value as StepFilter).type
      const subOption = (filter.value as StepFilter).status

      // Update the selected status of step types and their statuses in options
      filtersCopy.steps.options = filtersCopy.steps.options.map((option) => {
        if (option.value === stepType) {
          const optionCopy = {
            ...option,
            options: option.options.map((statusOption) => ({
              ...statusOption,
              selected:
                statusOption.value === subOption
                  ? false
                  : statusOption.selected,
            })),
          }
          optionCopy.selected = optionCopy.options.some(
            (statusOption) => statusOption.selected
          )
          return optionCopy
        }
        return option
      })

      // Remove the selected status from the selectedValues
      filtersCopy.steps.selectedValues =
        filtersCopy.steps.selectedValues.filter(
          (selected) =>
            !(selected.type === stepType && selected.status === subOption)
        )
      return filtersCopy
    }
    case ApplicantFiltersActionType.CLEAR_APPLICANT_STAGE_FILTER: {
      const filtersCopy = cloneDeep(filters)
      const filter = payload

      const applicantStage = (filter.value as ApplicantStageFilter)
        .applicantStage

      filtersCopy.applicantStages.options =
        filtersCopy.applicantStages.options.map((option) => {
          if (option.value === applicantStage) {
            const optionCopy = {
              ...option,
              options: option.options?.map((subOption) => {
                if (
                  option.value === ApplicantStage.PROSPECT &&
                  subOption.value === filter.value.hiringStage
                ) {
                  return {
                    ...subOption,
                    selected: false,
                  }
                }
                if (
                  option.value === ApplicantStage.DISQUALIFIED &&
                  subOption.value === filter.value.disqualifiedReason
                ) {
                  return {
                    ...subOption,
                    selected: false,
                  }
                }
                if (
                  option.value === ApplicantStage.TERMINATED &&
                  subOption.value === filter.value.terminatedReason
                ) {
                  return {
                    ...subOption,
                    selected: false,
                  }
                }
                if (
                  option.value === ApplicantStage.NOT_INTERESTED &&
                  subOption.value === filter.value.notInterestedReason
                ) {
                  return {
                    ...subOption,
                    selected: false,
                  }
                }
                return subOption
              }),
            }
            optionCopy.selected = optionCopy.options.some(
              (subOption) => subOption.selected
            )
            return optionCopy
          }
          return option
        })

      // Remove the selected status from the selectedValues
      filtersCopy.applicantStages.selectedValues =
        filtersCopy.applicantStages.selectedValues.filter((selectedValue) => {
          if (selectedValue.applicantStage === ApplicantStage.PROSPECT) {
            return selectedValue.hiringStage !== filter.value.hiringStage
          }
          if (selectedValue.applicantStage === ApplicantStage.NOT_INTERESTED) {
            return (
              selectedValue.notInterestedReason !==
              filter.value.notInterestedReason
            )
          }
          if (selectedValue.applicantStage === ApplicantStage.DISQUALIFIED) {
            return (
              selectedValue.disqualifiedReason !==
              filter.value.disqualifiedReason
            )
          }
          if (selectedValue.applicantStage === ApplicantStage.TERMINATED) {
            return (
              selectedValue.terminatedReason !== filter.value.terminatedReason
            )
          }
          if (
            selectedValue.applicantStage === filter.value.applicantStage &&
            filter.value.applicantStage === ApplicantStage.HIRED
          ) {
            return false
          }
          return true
        })
      return filtersCopy
    }
    case ApplicantFiltersActionType.CLEAR_ALL: {
      const filtersCopy = cloneDeep(defaultFilters)
      filtersCopy.applicantStages.options =
        defaultFilters.applicantStages.options.map((option) => {
          return {
            ...option,
            selected: false,
          }
        })
      filtersCopy.applicantStages.selectedValues = []
      return filtersCopy
    }
    case ApplicantFiltersActionType.FILTER_BY_HIRING_STAGE_METRIC: {
      const filtersCopy = cloneDeep(filters)
      filtersCopy.applicantStages.selectedValues = []
      filtersCopy.applicantStages.options =
        defaultFilters.applicantStages.options.map((option) => {
          if (option.value === ApplicantStage.PROSPECT) {
            option.options = option.options.map((hiringStage) => {
              if (hiringStage.value === payload.name) {
                filtersCopy.applicantStages.selectedValues = [
                  {
                    applicantStage: ApplicantStage.PROSPECT,
                    hiringStage: payload.name,
                  },
                ]
                return {
                  ...hiringStage,
                  selected: hiringStage.value === payload.name,
                }
              }
              return {
                ...hiringStage,
                selectedValues: [],
                selected: false,
              }
            })
          }
          return option
        })
      return filtersCopy
    }
    default: {
      return filters
    }
  }
}
