import React from 'react'
import { useState, useEffect } from 'react'

import {
  ActionIcon,
  Modal,
  Select,
  Group,
  Text,
  TextInput,
} from '@mantine/core'
import {
  Dropzone,
  IMAGE_MIME_TYPE,
  PDF_MIME_TYPE,
  MS_WORD_MIME_TYPE,
} from '@mantine/dropzone'
import { useForm } from '@mantine/form'
import { Step, VerificationOfEmployment } from 'types/graphql'

import { useMutation } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import DateInput from 'src/components/Inputs/DateInput/DateInput'
import { toast } from 'src/components/Overlays/Toast/Toast'
import { CREATE_DOCUMENT, UPDATE_DOCUMENT } from 'src/graphql/documents.graphql'
import {
  DocumentCategory,
  DocumentUserStatus,
  UploadStatus,
  FileContentType,
} from 'src/graphql/types/documents'
import { stepTypeToDocumentCategoryMapping } from 'src/graphql/types/steps'
import IconClose from 'src/icons/IconClose'
import IconFile from 'src/icons/IconFile'
import IconUpload from 'src/icons/IconUpload'
import {
  formatSnakeValue,
  formatBytes,
  formatDocumentCategory,
} from 'src/lib/formatters'

const MEGABYTE_TO_BYTE = 1024 ** 2
const MEGABYTE_LIMIT = 100

const categories = Object.values(DocumentCategory).map((category) => ({
  value: category,
  label: formatDocumentCategory(category),
}))

const statuses = [
  DocumentUserStatus.PENDING_REVIEW,
  DocumentUserStatus.COMPLETED,
]

const fileContentTypeByFileType = {
  'image/png': FileContentType.PNG,
  'image/jpeg': FileContentType.JPG,
  'image/jpg': FileContentType.JPG,
  'image/tiff': FileContentType.TIF,
  'image/gif': FileContentType.GIF,
  'image/heic': FileContentType.HEIC,
  'application/pdf': FileContentType.PDF,
  'text/html': FileContentType.HTML,
  'application/msword': FileContentType.DOC,
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    FileContentType.DOC,
  'application/zip': FileContentType.ZIP,
  'text/csv': FileContentType.CSV,
  'text/plain': FileContentType.TXT,
}

const SelectedFile = ({ file, onClose }) => {
  if (!file) return null
  return (
    <div className="flex flex-row items-center gap-3">
      <IconFile className="h-10 w-8 fill-none" />
      <div className="items-center gap-1.5">
        <Text className="text-md font-medium text-doubleNickel-gray-900">
          {file.name}
        </Text>
        <Text className="text-sm font-medium text-doubleNickel-gray-500">
          {formatBytes(file.size)}
        </Text>
      </div>
      <ActionIcon
        variant="subtle"
        className="ml-auto h-6 w-6 rounded-md bg-doubleNickel-white"
        onClick={onClose}
      >
        <IconClose className="h-3 w-3 fill-doubleNickel-white stroke-doubleNickel-gray-500" />
      </ActionIcon>
    </div>
  )
}

export const appendCorrectFileExtension = (fileName, mimeType) => {
  const extensionsByMimeType = {
    'image/png': '.png',
    'image/jpeg': '.jpg',
    'application/pdf': '.pdf',
  }

  const extension = extensionsByMimeType[mimeType]
  if (!extension) return fileName // No extension found for MIME type

  const fileNameParts = fileName.split('.')
  const currentExtension =
    fileNameParts.length > 1 ? '.' + fileNameParts.pop() : ''

  // If the current extension is correct, return the filename as is
  if (currentExtension.toLowerCase() === extension.toLowerCase()) {
    return fileName
  }

  // Append the correct extension
  return fileName + extension
}

export interface UploadDocumentModalProps {
  applicantId: string
  isOpen: boolean
  onClose: () => void
  onRender
  onUpdateComplete?: (document) => void
  step: Step
  voe: VerificationOfEmployment
  showExpirationDate?: boolean
  disableCategorySelection?: boolean
}

const UploadDocumentModal = ({
  applicantId,
  isOpen,
  onClose,
  onRender,
  onUpdateComplete,
  step,
  voe,
  showExpirationDate = true,
  disableCategorySelection = false,
}: UploadDocumentModalProps) => {
  const form = useForm({
    initialValues: {
      category: voe ? DocumentCategory.VERIFICATION_OF_EMPLOYMENT : '',
      userStatus: DocumentUserStatus.COMPLETED,
      fileName: voe ? voe.companyName : '',
      expirationDate: null,
      file: null,
    },

    validate: {
      category: (value) => (value ? null : 'Category is required'),
      userStatus: (value) => (value ? null : 'Status is required'),
      file: (value) => (value ? null : 'File is required'),
    },
  })

  const [isUploading, setIsUploading] = useState(false)

  const onError = () => {
    toast('Something went wrong, please try again.', 'error')
    setIsUploading(false)
  }

  useEffect(() => {
    if (step && step.type) {
      // Use document category conversion or default to step type
      const stepDocumentCategory =
        stepTypeToDocumentCategoryMapping[step.type] || step.type
      const categoryValue = categories.find(
        (cat) => cat.value === stepDocumentCategory
      )?.value
      form.setFieldValue('category', categoryValue)
    }
  }, [step])

  useEffect(() => {
    if (voe) {
      form.setFieldValue(
        'category',
        DocumentCategory.VERIFICATION_OF_EMPLOYMENT
      )
    }
  }, [voe])

  const [updateDocument] = useMutation(UPDATE_DOCUMENT, {
    refetchQueries: onRender,
    onCompleted: ({ updateDocument }) => {
      setIsUploading(false)
      if (onUpdateComplete) {
        onUpdateComplete(updateDocument)
      }
      onClose()
      toast('Document created successfully', 'success')
    },
    onError,
  })

  const [createDocument] = useMutation(CREATE_DOCUMENT, {
    onCompleted: async ({ createDocument }) => {
      const { uploadUrl } = createDocument

      await fetch(uploadUrl, {
        method: 'PUT',
        body: form.values.file,
        headers: {
          'Content-Type': form.values.file.type,
        },
      })

      updateDocument({
        variables: {
          id: createDocument.documentId,
          input: {
            uploadStatus: UploadStatus.COMPLETED,
          },
        },
      })
    },
    onError,
  })

  const onFormSubmit = (values) => {
    const { file, fileName, expirationDate, ...createDocumentInput } = values
    const updatedFileName = fileName || file.name
    const fileNameWithExtension = appendCorrectFileExtension(
      updatedFileName,
      file.type
    )
    toast('Document is being stored, please wait', 'warning')
    setIsUploading(true)
    createDocument({
      variables: {
        input: {
          ...createDocumentInput,
          fileType: fileContentTypeByFileType[file.type],
          fileName: fileNameWithExtension,
          expirationDate: expirationDate ? new Date(expirationDate) : null,
          applicantId,
          stepId: step ? step.stepId : null,
          voeId: voe ? voe.voeId : null,
        },
      },
    })
  }

  const onDrop = (files) => {
    form.setFieldValue('file', files[0])
  }

  const onReject = (fileRejections) => {
    fileRejections.forEach((rejection) => {
      if (rejection.errors[0].code === 'file-too-large') {
        toast(
          `File size exceeds the maximum limit of ${MEGABYTE_LIMIT}MB`,
          'error'
        )
      } else {
        toast(`Unknown error occurred: ${rejection.errors[0].code}`, 'error')
      }
    })
  }

  const cleanSelectedFile = () => {
    form.setFieldValue('file', null)
  }

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      classNames={{ title: 'text-lg font-bold' }}
      title="Upload a document"
      centered
    >
      <div className="flex flex-col gap-6">
        <form onSubmit={form.onSubmit((values) => onFormSubmit(values))}>
          {!form.values.file && (
            <div className="flex-row flex-col py-4">
              <Dropzone
                accept={[
                  ...IMAGE_MIME_TYPE,
                  ...PDF_MIME_TYPE,
                  ...MS_WORD_MIME_TYPE,
                ]}
                maxFiles={1}
                maxSize={MEGABYTE_LIMIT * MEGABYTE_TO_BYTE}
                onDrop={onDrop}
                onReject={onReject}
                {...form.getInputProps('file')}
              >
                <Group className="flex flex-col">
                  <Dropzone.Accept>
                    <p>Drag and drop the file here</p>
                  </Dropzone.Accept>
                  <Dropzone.Reject>
                    <p>File does not meet requirements</p>
                  </Dropzone.Reject>
                  <Dropzone.Idle>
                    <ActionIcon
                      variant="subtle"
                      className="h-10 w-10 rounded-lg border border-doubleNickel-gray-200 bg-doubleNickel-white shadow-sm"
                    >
                      <IconUpload className="fill-none stroke-doubleNickel-gray-700" />
                    </ActionIcon>
                  </Dropzone.Idle>
                  <div className="flex flex-col items-center gap-1">
                    <Text className="text-sm text-doubleNickel-gray-600">
                      <span className="font-semibold text-doubleNickel-brand-500">
                        {'Click to upload '}
                      </span>
                      or drag and drop
                    </Text>
                    <Text
                      size="sm"
                      className="text-xs text-doubleNickel-gray-600"
                    >
                      PDF, PNG, JPG or JPEG (Max {MEGABYTE_LIMIT}MB)
                    </Text>
                  </div>
                </Group>
              </Dropzone>
            </div>
          )}

          {form.values.file && (
            <>
              <SelectedFile
                file={form.values.file}
                onClose={cleanSelectedFile}
              />
              <div className="mb-8 mt-3 grid grid-cols-2 gap-4">
                <TextInput
                  className="col-span-2"
                  placeholder={
                    form.getInputProps('file').value
                      ? form.getInputProps('file').value.name
                      : 'Enter a file name'
                  }
                  label="File name"
                  {...form.getInputProps('fileName')}
                />
                <Select
                  withAsterisk
                  label="Category"
                  placeholder="Select category"
                  disabled={disableCategorySelection}
                  data={categories}
                  searchable
                  required
                  {...form.getInputProps('category')}
                />

                <Select
                  withAsterisk
                  label="Status"
                  placeholder="Select status"
                  data={statuses.map((status) => ({
                    label: formatSnakeValue(status),
                    value: status,
                  }))}
                  required
                  {...form.getInputProps('userStatus')}
                />
                {showExpirationDate && (
                  <DateInput
                    label="Expiration Date"
                    clearable
                    value={form.values.expirationDate}
                    onChange={(date) =>
                      form.setFieldValue('expirationDate', date)
                    }
                  />
                )}
              </div>
            </>
          )}
          <div className="flex flex-row gap-3 border-t pt-6">
            <Button
              text="Cancel"
              variant="outline"
              onClick={onClose}
              className="flex-1"
            />
            <Button
              className="flex-1"
              type="submit"
              disabled={!form.isValid() || isUploading}
              text="Save"
              variant="filled"
            />
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default UploadDocumentModal
