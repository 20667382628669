const IconHangup = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill={props.fill}
      className={props.className}
    >
      <g clipPath="url(#clip0_7282_47555)">
        <path
          d="M17.2197 13.132C16.015 12.7206 14.7214 12.5025 13.3761 12.5142C12.0309 12.5259 10.7413 12.7666 9.54393 13.199C9.44093 13.2362 9.38944 13.2548 9.33267 13.2865C9.13096 13.3995 8.97309 13.6265 8.93734 13.8549C8.92728 13.9191 8.92784 13.9831 8.92895 14.1111C8.93237 14.5025 8.93408 14.6982 8.90107 14.8609C8.77661 15.4743 8.30241 15.9569 7.69122 16.092C7.52915 16.1279 7.33345 16.1296 6.94205 16.133L6.72389 16.1349C6.12891 16.1401 5.83143 16.1427 5.59019 16.0658C5.11042 15.913 4.73195 15.5411 4.57077 15.064C4.48972 14.8242 4.48712 14.5267 4.48193 13.9317L4.48039 13.7552C4.47522 13.1623 4.47263 12.8658 4.55821 12.5503C4.65317 12.2002 4.90635 11.7603 5.16138 11.5023C5.39122 11.2698 5.59305 11.1549 5.99672 10.9251C8.16606 9.69002 10.6716 8.97516 13.3451 8.95183C16.0185 8.9285 18.5362 9.59952 20.7267 10.7966C21.1343 11.0193 21.3381 11.1307 21.572 11.3591C21.8315 11.6126 22.0923 12.048 22.1934 12.3964C22.2845 12.7104 22.287 13.0069 22.2922 13.5998L22.2938 13.7763C22.2989 14.3713 22.3015 14.6687 22.2247 14.91C22.0719 15.3897 21.6999 15.7682 21.2229 15.9294C20.983 16.0104 20.6856 16.013 20.0906 16.0182L19.8724 16.0201C19.481 16.0236 19.2853 16.0253 19.1227 15.9923C18.5092 15.8678 18.0267 15.3936 17.8915 14.7824C17.8557 14.6203 17.854 14.4246 17.8505 14.0332C17.8494 13.9053 17.8489 13.8413 17.8377 13.7772C17.798 13.5494 17.6361 13.3253 17.4325 13.2159C17.3752 13.1851 17.3234 13.1674 17.2197 13.132Z"
          stroke={props.stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7282_47555">
          <rect
            width="19"
            height="19"
            fill="white"
            transform="translate(26.8691 13.3125) rotate(134.5)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconHangup
