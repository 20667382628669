import { useEffect, useState } from 'react'

import { Accordion, Checkbox, Button as MantineButton } from '@mantine/core'

import IconAdd from 'src/icons/IconAdd'

import ApplicationAccordionItem from '../components/ApplicationAccordionItem'
import ApplicationAddressInput from '../components/ApplicationAddressInput'
import ApplicationCompanyInput from '../components/ApplicationCompanyInput'
import ApplicationScreenWrapper from '../components/ApplicationScreenWrapper'
import ApplicationTextInput from '../components/ApplicationTextInput'
import { formatMonthString, formatMonthYear } from '../utils/formatters'
import { useFormContext } from '../utils/FormContext'
import { findErrors } from '../utils/validators'
import ApplicationPhoneInput from '../components/ApplicationPhoneInput'
import { v1 as uuid } from 'uuid'
import { unformatPhoneNumber } from 'src/lib/phone.utils'
import ApplicationSelectInput from '../components/ApplicationSelectInput'
import dayjs from 'dayjs'

export const defaultExperience = {
  employmentExperienceId: undefined,
  companyName: '',
  position: 'Truck Driver',
  unemploymentReason: 'n/a',
  reasonForLeaving: '',
  startDate: undefined,
  endDate: undefined,
  current: false,
  dotNumber: '',
  contactName: '',
  email: '',
  phone: '',
  faxNumber: '',
  isUnemployment: false,
  isDriverTerminated: false,
  isDriverSubjectToFMCSRs: true,
  isJobDesignatedAsSafetySensitiveFunctionUnderDOT: true,
  shouldContactEmployer: true,
  address: {
    street: '',
    city: '',
    state: '',
    country: '',
    zipCode: '',
    startDate: undefined,
    endDate: undefined,
  },
}

export const defaultUnemployment = {
  employmentExperienceId: undefined,
  companyName: 'n/a',
  position: 'n/a',
  unemploymentReason: '',
  reasonForLeaving: 'n/a',
  startDate: undefined,
  endDate: undefined,
  isUnemployment: true,
  isDriverTerminated: false,
  isDriverSubjectToFMCSRs: true,
  isJobDesignatedAsSafetySensitiveFunctionUnderDOT: true,
  shouldContactEmployer: true,
}

export const MainEmploymentScreen = () => {
  const [accordionValue, setAccordionValue] = useState(null)

  const { form, goBack, handleNext, updateLoading, application } =
    useFormContext()

  const handleCompanySelect = (selected, index) => {
    form.setValues((prev) => {
      const newValues = { ...prev }
      newValues.employmentExperiences[index].companyName = selected.companyName
      newValues.employmentExperiences[index].dotNumber = selected.dotNumber
      newValues.employmentExperiences[index].address.city =
        selected.address.city
      newValues.employmentExperiences[index].address.country =
        selected.address.country
      newValues.employmentExperiences[index].address.state =
        selected.address.state
      newValues.employmentExperiences[index].address.street =
        selected.address.street
      newValues.employmentExperiences[index].address.zipCode =
        selected.address.zipCode

      if (selected.phone) {
        newValues.employmentExperiences[index].phone = selected.phone
      }
      return { ...newValues }
    })
  }

  return (
    <ApplicationScreenWrapper
      title={'Employment History'}
      description="Please submit your last 3-10 years of employment history (including times in which you were unemployed)"
      goBack={goBack}
      updateLoading={updateLoading}
      application={application}
      handleCancel={goBack}
      handleSubmit={() => {
        setAccordionValue(null)
        handleNext({
          employmentExperiences: form.values.employmentExperiences.map(
            ({ employmentExperienceId, current, ...exp }) => {
              return {
                ...exp,
                phone: unformatPhoneNumber(exp.phone),
                faxNumber: unformatPhoneNumber(exp.faxNumber),
              }
            }
          ),
        })
      }}
    >
      <Accordion
        chevronPosition="left"
        variant="separated"
        value={accordionValue}
        onChange={setAccordionValue}
        radius={'md'}
        classNames={{
          root: 'w-full sm:col-span-3',
          control: 'h-16',
          item: 'bg-transparent border-doubleNickel-gray-300 shadow-sm',
        }}
      >
        {form?.values?.employmentExperiences?.map((experience, index) => (
          <ApplicationAccordionItem
            value={experience.employmentExperienceId}
            key={experience.employmentExperienceId}
            showDelete={index !== 0}
            isCurrent={false}
            title={
              experience.isUnemployment
                ? 'Unemployment'
                : form.values.employmentExperiences[index].companyName !== ''
                ? form.values.employmentExperiences[index].companyName
                : `Company Name`
            }
            subTitle={`${dayjs(experience.startDate).format('MM/YYYY')} - ${
              experience.endDate
                ? dayjs(experience.endDate).format('MM/YYYY')
                : 'Present'
            }`}
            hasErrors={
              findErrors(form.errors, 'employmentExperiences', index) > 0
            }
            handleDelete={() =>
              form.removeListItem('employmentExperiences', index)
            }
          >
            {experience?.isUnemployment ? (
              // UNEMPLOYMENT FORM
              <div className=" grid w-full grid-cols-2 gap-x-2 gap-y-8 sm:gap-x-4">
                <ApplicationTextInput
                  label="Start Date"
                  placeholder="MM / YYYY"
                  pattern="\d*"
                  required
                  value={formatMonthString(
                    form.values.employmentExperiences[index]?.startDate
                  )}
                  error={
                    form.errors[`employmentExperiences.${index}.startDate`]
                  }
                  onChange={(event) => {
                    formatMonthYear(
                      `employmentExperiences.${index}.startDate`,
                      event,
                      form
                    )
                  }}
                />

                <ApplicationTextInput
                  label="End Date"
                  placeholder="MM / YYYY"
                  disabled={form.values.employmentExperiences[index].current}
                  pattern="\d*"
                  required
                  value={formatMonthString(
                    form.values.employmentExperiences[index]?.endDate
                  )}
                  error={form.errors[`employmentExperiences.${index}.endDate`]}
                  onChange={(event) => {
                    formatMonthYear(
                      `employmentExperiences.${index}.endDate`,
                      event,
                      form
                    )
                  }}
                />
                <ApplicationTextInput
                  label="Reason for Unemployment"
                  className="col-span-2"
                  required
                  placeholder="Please provide a brief explanation"
                  {...form.getInputProps(
                    `employmentExperiences.${index}.unemploymentReason`
                  )}
                />
              </div>
            ) : (
              // EMPLOYMENT FORM
              <div className="grid w-full grid-cols-1 gap-x-2 gap-y-8 sm:grid-cols-2 sm:gap-x-4">
                <ApplicationCompanyInput
                  label="Company Name"
                  placeholder="Sample Company Inc."
                  required
                  {...form.getInputProps(
                    `employmentExperiences.${index}.companyName`
                  )}
                  onSelect={(selected) => handleCompanySelect(selected, index)}
                />
                <ApplicationTextInput
                  label="Job Title"
                  required
                  placeholder="Example: Driver"
                  {...form.getInputProps(
                    `employmentExperiences.${index}.position`
                  )}
                />
                <ApplicationTextInput
                  label="Start Date"
                  placeholder="MM / YYYY"
                  pattern="\d*"
                  required
                  value={formatMonthString(
                    form.values.employmentExperiences[index]?.startDate
                  )}
                  error={
                    form.errors[`employmentExperiences.${index}.startDate`]
                  }
                  onChange={(event) => {
                    formatMonthYear(
                      `employmentExperiences.${index}.startDate`,
                      event,
                      form
                    )
                  }}
                />

                <div className="flex flex-col gap-2 sm:gap-4">
                  <ApplicationTextInput
                    label="End Date"
                    placeholder="MM / YYYY"
                    disabled={form.values.employmentExperiences[index].current}
                    pattern="\d*"
                    required
                    value={formatMonthString(
                      form.values.employmentExperiences[index]?.endDate
                    )}
                    error={
                      form.errors[`employmentExperiences.${index}.endDate`]
                    }
                    onChange={(event) => {
                      formatMonthYear(
                        `employmentExperiences.${index}.endDate`,
                        event,
                        form
                      )
                    }}
                  />
                  <Checkbox
                    label="This is my current employer"
                    checked={form.values.employmentExperiences[index].current}
                    onChange={(event) => {
                      if (event.currentTarget.checked) {
                        form.setFieldValue(
                          `employmentExperiences.${index}.endDate`,
                          null
                        )
                        form.setFieldValue(
                          `employmentExperiences.${index}.current`,
                          true
                        )
                      } else {
                        form.setFieldValue(
                          `employmentExperiences.${index}.endDate`,
                          ''
                        )
                        form.setFieldValue(
                          `employmentExperiences.${index}.current`,
                          false
                        )
                      }
                    }}
                  />
                </div>

                <ApplicationTextInput
                  label="Reason for Leaving"
                  required
                  placeholder="Please provide a brief explanation"
                  {...form.getInputProps(
                    `employmentExperiences.${index}.reasonForLeaving`
                  )}
                />
                <ApplicationSelectInput
                  label="Were you terminated?"
                  required
                  value={
                    form.values.employmentExperiences[
                      index
                    ].isDriverTerminated?.toString() ??
                    defaultExperience.isDriverTerminated.toString()
                  }
                  data={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                  ]}
                  onChange={(event) => {
                    form.setFieldValue(
                      `employmentExperiences.${index}.isDriverTerminated`,
                      event.currentTarget.value === 'true'
                    )
                  }}
                />
                <ApplicationSelectInput
                  label="Were you subject to FMCSRs while employed?"
                  required
                  value={
                    form.values.employmentExperiences[
                      index
                    ].isDriverSubjectToFMCSRs?.toString() ??
                    defaultExperience.isDriverSubjectToFMCSRs.toString()
                  }
                  data={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                  ]}
                  onChange={(event) => {
                    form.setFieldValue(
                      `employmentExperiences.${index}.isDriverSubjectToFMCSRs`,
                      event.currentTarget.value === 'true'
                    )
                  }}
                />
                <ApplicationSelectInput
                  label="May we contact this employer?"
                  className={`${
                    form.values.employmentExperiences[index].current
                      ? ''
                      : 'hidden'
                  }`}
                  required
                  value={
                    form.values.employmentExperiences[
                      index
                    ].shouldContactEmployer?.toString() ??
                    defaultExperience.shouldContactEmployer.toString()
                  }
                  data={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                  ]}
                  onChange={(event) => {
                    form.setFieldValue(
                      `employmentExperiences.${index}.shouldContactEmployer`,
                      event.currentTarget.value === 'true'
                    )
                  }}
                />
                <ApplicationSelectInput
                  label="Was your job designated as a safety-sensitive function in a DoT regulated mode subject to Drug and Alcohol testing per 49 CFR Part 40?"
                  required
                  value={
                    form.values.employmentExperiences[
                      index
                    ].isJobDesignatedAsSafetySensitiveFunctionUnderDOT?.toString() ??
                    defaultExperience.isJobDesignatedAsSafetySensitiveFunctionUnderDOT.toString()
                  }
                  data={[
                    { label: 'Yes', value: 'true' },
                    { label: 'No', value: 'false' },
                  ]}
                  onChange={(event) => {
                    form.setFieldValue(
                      `employmentExperiences.${index}.isJobDesignatedAsSafetySensitiveFunctionUnderDOT`,
                      event.currentTarget.value === 'true'
                    )
                  }}
                />
                <div className="text-sm font-medium text-doubleNickel-brand-500 sm:col-span-2">
                  The following fields are optional, but filling them out helps
                  our recruiters!
                </div>
                <ApplicationTextInput
                  label="DOT Number"
                  placeholder="DOT Number"
                  {...form.getInputProps(
                    `employmentExperiences.${index}.dotNumber`
                  )}
                />
                <ApplicationAddressInput
                  key={form.values.employmentExperiences[index].companyName}
                  variant="unstyled"
                  label="Address"
                  placeholder="Please select an address from the dropdown"
                  value={form.values.employmentExperiences[index].address}
                  handleChange={(value) => {
                    form.setFieldValue(
                      `employmentExperiences.${index}.address`,
                      value
                    )
                  }}
                />

                <ApplicationTextInput
                  label="Contact Name"
                  placeholder="Contact Name"
                  {...form.getInputProps(
                    `employmentExperiences.${index}.contactName`
                  )}
                />
                <ApplicationTextInput
                  label="Contact Email"
                  placeholder="Contact Email"
                  {...form.getInputProps(
                    `employmentExperiences.${index}.email`
                  )}
                />

                <ApplicationPhoneInput
                  label="Company Phone Number"
                  pattern="\d*"
                  phone={form.values.employmentExperiences[index].phone}
                  error={form.errors[`employmentExperiences.${index}.phone`]}
                  handleChange={(value) => {
                    form.setFieldValue(
                      `employmentExperiences.${index}.phone`,
                      value
                    )
                  }}
                />
                <ApplicationPhoneInput
                  label="Company Fax Number"
                  pattern="\d*"
                  phone={form.values.employmentExperiences[index].faxNumber}
                  error={
                    form.errors[`employmentExperiences.${index}.faxNumber`]
                  }
                  handleChange={(value) => {
                    form.setFieldValue(
                      `employmentExperiences.${index}.faxNumber`,
                      value
                    )
                  }}
                />
              </div>
            )}
          </ApplicationAccordionItem>
        ))}
      </Accordion>
      {form?.values?.employmentExperiences.length > 0 && (
        <div
          className={`col-span-3 flex w-full flex-col gap-4 sm:col-span-1 sm:flex-row`}
        >
          <MantineButton
            size="sm"
            className="h-11 w-full"
            variant="light"
            onClick={() => {
              let experience = { ...defaultExperience }
              experience.employmentExperienceId = uuid()
              setAccordionValue(
                `employmentExperiences-${
                  form.values.employmentExperiences.length + 1
                }`
              )
              form.insertListItem('employmentExperiences', experience)
            }}
          >
            Add another employer
          </MantineButton>
          <MantineButton
            size="sm"
            className="h-11 w-full"
            variant="light"
            onClick={() => {
              setAccordionValue(
                `employmentExperiences-${
                  form.values.employmentExperiences.length + 1
                }`
              )
              let experience = { ...defaultUnemployment }
              experience.employmentExperienceId = uuid()
              form.insertListItem('employmentExperiences', experience)
            }}
          >
            Add unemployment
          </MantineButton>
        </div>
      )}
    </ApplicationScreenWrapper>
  )
}
