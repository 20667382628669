const IconMicrophone = (props) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_7594_27989)">
        <path
          d="M4.10286 10.4298V11.2215C4.10286 14.7193 6.93839 17.5548 10.4362 17.5548C12.3879 17.5548 14.1334 16.6721 15.2951 15.284M2.51953 2.51318L18.3529 18.3465M13.6029 9.16318V6.47152C13.6029 4.72262 12.1851 3.30485 10.4362 3.30485C9.67666 3.30485 8.97958 3.57226 8.43396 4.01806M10.4362 14.3882C8.6873 14.3882 7.26953 12.9704 7.26953 11.2215V7.26318L13.034 13.0328C12.4617 13.8522 11.5115 14.3882 10.4362 14.3882Z"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_7594_27989">
          <rect
            width="19"
            height="19"
            fill="white"
            transform="translate(0.935547 0.929688)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconMicrophone
