export enum LicenseClass {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  NO_CDL = 'NO_CDL',
}

export const LicenseClassLabels = {
  [LicenseClass.A]: 'A',
  [LicenseClass.B]: 'B',
  [LicenseClass.C]: 'C',
  [LicenseClass.D]: 'D',
  [LicenseClass.E]: 'E',
  [LicenseClass.NO_CDL]: 'No CDL',
}

export enum MexicanLicenseClass {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
}

export const MexicanLicenseClassLabels = {
  [MexicanLicenseClass.A]: 'A - Passenger and Tourism',
  [MexicanLicenseClass.B]: 'B - Freight',
  [MexicanLicenseClass.C]: 'C - Freight Two or Three Axles',
  [MexicanLicenseClass.D]: 'D - Tourism',
  [MexicanLicenseClass.E]: 'E - Freight Hazardous Materials',
  [MexicanLicenseClass.F]: 'F - Passenger Ports and Airports',
}

export enum Endorsement {
  H = 'H',
  N = 'N',
  X = 'X',
  P = 'P',
  S = 'S',
  T = 'T',
  V = 'V',
  L = 'L',
}

export const EndorsementDescriptions = {
  [Endorsement.H]: 'Hazardous Materials (Hazmat)',
  [Endorsement.N]: 'Tank Vehicles',
  [Endorsement.X]: 'Combination of Tank Vehicle and Hazardous Materials',
  [Endorsement.P]: 'Passenger Vehicles',
  [Endorsement.S]: 'School Buses',
  [Endorsement.T]: 'Double/Triple Trailers',
  [Endorsement.V]: 'Tank Vehicles Carrying Hazardous Materials',
  [Endorsement.L]: 'Air Brake Restriction',
}
