export const GET_TEXT_TEMPLATES = gql`
  query GET_TEXT_TEMPLATES($pageInfo: PageInfoInput, $orderBy: OrderByInput) {
    textTemplates(pageInfo: $pageInfo, orderBy: $orderBy) {
      totalCount
      pageInfo {
        offset
        limit
      }
      items {
        textTemplateId
        title
        createdAt
        updatedAt
        content
        createdBy {
          employeeId
          firstName
          lastName
        }
      }
    }
  }
`

export const RESOLVE_TEXT_TEMPLATE = gql`
  query RESOLVE_TEXT_TEMPLATE($textTemplateId: String!, $applicantId: String!) {
    resolveTextTemplate(
      textTemplateId: $textTemplateId
      applicantId: $applicantId
    ) {
      content
    }
  }
`

export const EDIT_TEXT_TEMPLATE = gql`
  mutation EDIT_TEXT_TEMPLATE(
    $textTemplateId: String!
    $input: UpdateTextTemplateInput!
  ) {
    updateTextTemplate(textTemplateId: $textTemplateId, input: $input) {
      content
    }
  }
`

export const CREATE_TEXT_TEMPLATE = gql`
  mutation CREATE_TEXT_TEMPLATE($input: CreateTextTemplateInput!) {
    createTextTemplate(input: $input) {
      content
    }
  }
`

export const DELETE_TEXT_TEMPLATE = gql`
  mutation DELETE_TEXT_TEMPLATE($textTemplateId: String!) {
    deleteTextTemplate(textTemplateId: $textTemplateId) {
      content
    }
  }
`
