const IconPlay = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill={props.fill}
      className={props.className}
    >
      <path
        d="M4.6665 4.1576C4.6665 3.3483 4.6665 2.94365 4.83525 2.72059C4.98225 2.52626 5.20694 2.40601 5.45017 2.39149C5.72937 2.37482 6.06606 2.59928 6.73944 3.0482L15.5024 8.89018C16.0588 9.26111 16.337 9.44658 16.434 9.68035C16.5187 9.88473 16.5187 10.1144 16.434 10.3188C16.337 10.5526 16.0588 10.738 15.5024 11.109L6.73944 16.951C6.06606 17.3999 5.72937 17.6243 5.45017 17.6077C5.20694 17.5931 4.98225 17.4729 4.83525 17.2786C4.6665 17.0555 4.6665 16.6509 4.6665 15.8416V4.1576Z"
        stroke={props.stroke}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconPlay
