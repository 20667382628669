import { useQuery } from '@redwoodjs/web'
import { createContext, useContext } from 'react'
import { GET_MY_COMPANIES } from 'src/graphql/users.graphql'

const CurrentCompanyContext = createContext(null)

const CurrentCompanyContextProvider = ({ children }) => {
  const { data } = useQuery(GET_MY_COMPANIES)

  const selectedCompany = data
    ? data.getMyCompanies.find((company) => company.isCurrentEmployeeId)
    : null

  return (
    <CurrentCompanyContext.Provider value={selectedCompany}>
      {children}
    </CurrentCompanyContext.Provider>
  )
}

export function useCurrentCompanyContext() {
  return useContext(CurrentCompanyContext)
}

export { CurrentCompanyContext, CurrentCompanyContextProvider }
