import { useState } from 'react'

import { Checkbox, Box } from '@mantine/core'
import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { z } from 'zod'

import Button from 'src/components/Buttons/Button/Button'
import { PDFPreviewModal } from 'src/components/Overlays/PDFPreviewModal/PDFPreviewModal'

import ApplicationScreenWrapper from '../components/ApplicationScreenWrapper'
import { useFormContext } from '../utils/FormContext'

const ConsentScreen = () => {
  const {
    goBack,
    updateLoading,
    application,
    loadingUpdateConsents: loading,
    onConsentsConfirmed,
  } = useFormContext()

  const schema = z.object({})
  const form = useForm({
    initialValues: {
      consents: [
        ...application.consents
          .filter((consent) => consent.confirmed)
          .map((consent) => consent.consentId),
      ],
    },
    validate: zodResolver(schema),
  })
  const [selectedConsent, setSelectedConsent] = useState(null)

  const consents = [
    {
      consentId: '0',
      title:
        'I have the legal authority to agree to these terms and the information included in my application is accurate to the best of my knowledge.',
    },
    ...application.consents,
    {
      consentId: '1',
      title: 'Terms of use',
      consentTemplate: { fileName: 'Terms+of+Use' },
    },
    {
      consentId: '2',
      title: 'Privacy Policy',
      consentTemplate: { fileName: 'Privacy%20Policy' },
    },
  ].filter((consent) => consent.title !== 'Application')

  const submitApplication = (values) => {
    if (form.isDirty() && values.consents.length === consents.length) {
      onConsentsConfirmed()
    }
    return false
  }

  const onPreview = (consent) => {
    setSelectedConsent(consent)
  }

  const checkAll = () => {
    form.setValues({
      consents: consents.map((consent) => consent.consentId),
    })
  }

  return (
    <ApplicationScreenWrapper
      title={'Consent Forms'}
      description={
        'Complete these agreements in order to submit your application.'
      }
      goBack={goBack}
      updateLoading={loading || updateLoading}
      saveButtontext={'Next'}
      saveButtonDisabled={
        !form.isValid() || form.values.consents.length != consents.length
      }
      application={application}
      handleCancel={goBack}
      handleSubmit={() => {
        submitApplication(form.values)
      }}
    >
      <form className="col-span-3 flex grid-cols-subgrid flex-col gap-10">
        <div>
          <Button
            text="Check all"
            variant="outline"
            className="ml-auto sm:ml-0"
            onClick={checkAll}
          />
        </div>
        <Checkbox.Group {...form.getInputProps(`consents`)}>
          <div className="flex flex-col gap-4">
            {consents.map((consent) => (
              <div
                className="flex flex-row items-center gap-3"
                key={consent.consentId}
              >
                <Checkbox
                  checked={consent.confirmed}
                  value={consent.consentId}
                />
                {consent.consentTemplate && (
                  <Box
                    className="cursor-pointer text-sm font-medium text-doubleNickel-brand-500 underline"
                    onClick={() => onPreview(consent)}
                  >
                    {consent.title}
                  </Box>
                )}
                {!consent.consentTemplate && (
                  <Box className="text-sm font-medium text-doubleNickel-brand-500">
                    {consent.title}
                  </Box>
                )}
              </div>
            ))}
          </div>
        </Checkbox.Group>
        <div className="text-xs text-doubleNickel-gray-700 sm:text-sm sm:font-medium">
          I understand that by inserting my signature I am agreeing to each of
          the above reference documents and Double Nickel will submit my
          application to the requested company. This submission does not
          constitute an offer of employment by Double Nickel or the requested
          company. Additionally, Double Nickel is merely facilitating the
          application process and does not represent the requested company and
          is not a party to any future relationship between you and the
          requested company.
        </div>
      </form>
      {selectedConsent && selectedConsent.consentTemplate && (
        <PDFPreviewModal
          fileName={selectedConsent.title}
          url={`https://dmhhjg7ajzznc.cloudfront.net/consent-templates/${selectedConsent.consentTemplate.fileName}.pdf`}
          isOpen={selectedConsent}
          onClose={() => setSelectedConsent(null)}
          onBack={() => setSelectedConsent(null)}
        />
      )}
    </ApplicationScreenWrapper>
  )
}

export default ConsentScreen
