import { DocumentCategory } from './documents'

export enum StepStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING_REVIEW = 'PENDING_REVIEW',
  COMPLETED = 'COMPLETED',
}

export enum StepType {
  DOT_APP = 'DOT_APP',
  MVR = 'MVR',
  PSP = 'PSP',
  BACKGROUND_CHECK = 'BACKGROUND_CHECK',
  CDL = 'CDL',
  CDLIS = 'CDLIS',
  VERIFICATION_OF_EMPLOYMENT = 'VERIFICATION_OF_EMPLOYMENT',
  CLEARINGHOUSE = 'CLEARINGHOUSE',
  DRUG_AND_ALCOHOL_TESTING = 'DRUG_AND_ALCOHOL_TESTING',
  DOT_MEDICAL = 'DOT_MEDICAL',
  ROAD_TEST = 'ROAD_TEST',
}

export const DQF_STEP_TYPES = [
  StepType.CDL,
  StepType.CDLIS,
  StepType.MVR,
  StepType.PSP,
  StepType.BACKGROUND_CHECK,
  StepType.DOT_APP,
  StepType.VERIFICATION_OF_EMPLOYMENT,
  StepType.DOT_MEDICAL,
  StepType.CLEARINGHOUSE,
  StepType.DRUG_AND_ALCOHOL_TESTING,
  StepType.ROAD_TEST,
]

export const stepTypeToDocumentCategoryMapping: {
  [key in StepType]?: DocumentCategory
} = {
  [StepType.DOT_APP]: DocumentCategory.DOT_APP,
  [StepType.MVR]: DocumentCategory.MVR,
  [StepType.PSP]: DocumentCategory.PSP,
  [StepType.BACKGROUND_CHECK]: DocumentCategory.BACKGROUND_CHECK,
  [StepType.CLEARINGHOUSE]: DocumentCategory.CLEARING_HOUSE, // Adjusted for naming difference
  [StepType.CDL]: DocumentCategory.CDL,
  [StepType.CDLIS]: DocumentCategory.CDLIS,
  [StepType.DOT_MEDICAL]: DocumentCategory.DOT_MEDICAL,
  [StepType.VERIFICATION_OF_EMPLOYMENT]:
    DocumentCategory.VERIFICATION_OF_EMPLOYMENT,
  [StepType.DRUG_AND_ALCOHOL_TESTING]:
    DocumentCategory.DRUG_AND_ALCOHOL_TESTING,
  [StepType.ROAD_TEST]: DocumentCategory.ROAD_TEST,
}
