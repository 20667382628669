import * as Sentry from '@sentry/react'

let dsn = process.env.SENTRY_DSN
let environment = process.env.APP_ENV || 'development'

// Initialize sample rates with default values
let tracesSampleRate = 0.1;
let replaysSessionSampleRate = 0.1;
let replaysOnErrorSampleRate = 1.0;

if (environment === "development") {
  tracesSampleRate = 1.0;
  replaysSessionSampleRate = 1.0; // 100% sessions for session replay
  replaysOnErrorSampleRate = 1.0; // 100% sessions where errors occur
} else if (environment === "production") {
  tracesSampleRate = 0.1; // Example: 10% of transactions for performance monitoring
  replaysSessionSampleRate = 0.05; // Example: 5% of sessions for session replay
  replaysOnErrorSampleRate = 1.0; // Still 100% for sessions where errors occur to ensure all issues are captured
}

Sentry.init({
  dsn,
  environment,
  integrations: [new Sentry.BrowserTracing(), Sentry.replayIntegration(),],
  tracesSampleRate,
  // Session Replay
  replaysSessionSampleRate,
  replaysOnErrorSampleRate,
})

export default Sentry
