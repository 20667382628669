import { Box } from '@mantine/core'

import { navigate } from '@redwoodjs/router'

import Button from 'src/components/Buttons/Button/Button'
import IconCheckmark from 'src/icons/IconCheckmark'
import { removeCountryCode } from 'src/lib/phone.utils'

interface ApplicationCompletedScreenProps {
  title: string
  subTitle: string
  showNavigationButton?: boolean
  applicationId?: string
  phone?: string
}

const ApplicationCompletedScreen = ({
  title,
  subTitle,
  showNavigationButton = false,
  applicationId,
  phone,
}: ApplicationCompletedScreenProps) => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center gap-8">
      <Box className="flex h-24 w-24 flex-col items-center justify-center rounded-full bg-doubleNickel-brand-25">
        <IconCheckmark className="h-14 w-14 fill-none stroke-doubleNickel-brand-500" />
      </Box>
      <div>
        <div className="text-md text-center font-semibold text-doubleNickel-gray-800 sm:text-xl sm:font-bold">
          {title}
        </div>
        <div className="sm:text-md text-center text-sm text-doubleNickel-gray-700">
          {subTitle}
        </div>
      </div>

      {showNavigationButton && (
        <Button
          text="Complete my application now"
          className="bg-doubleNickel-brand-500"
          onClick={() =>
            navigate(
              `/driver-login?redirectTo=/driver-application?applicationId=${applicationId}&phone=${removeCountryCode(
                phone
              )}`
            )
          }
        />
      )}
    </div>
  )
}

export default ApplicationCompletedScreen
