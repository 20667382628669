const IconPhoneCall = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill={props.fill}
      className={props.className}
    >
      <path
        d="M7.48356 7.37779C8.06356 8.58581 8.85422 9.71801 9.85553 10.7193C10.8568 11.7206 11.9891 12.5113 13.1971 13.0913C13.301 13.1412 13.3529 13.1661 13.4187 13.1853C13.6523 13.2534 13.9392 13.2045 14.137 13.0628C14.1927 13.0229 14.2403 12.9753 14.3356 12.88C14.6269 12.5887 14.7726 12.443 14.9191 12.3478C15.4715 11.9886 16.1837 11.9886 16.7361 12.3478C16.8825 12.443 17.0282 12.5887 17.3196 12.88L17.4819 13.0424C17.9248 13.4853 18.1462 13.7067 18.2665 13.9446C18.5058 14.4175 18.5058 14.9761 18.2665 15.449C18.1462 15.6869 17.9248 15.9083 17.4819 16.3512L17.3506 16.4825C16.9092 16.9239 16.6886 17.1446 16.3885 17.3131C16.0556 17.5001 15.5385 17.6346 15.1567 17.6334C14.8126 17.6324 14.5774 17.5657 14.107 17.4322C11.5792 16.7147 9.19387 15.361 7.20388 13.371C5.2139 11.381 3.86017 8.99569 3.1427 6.46786C3.00919 5.99749 2.94244 5.7623 2.94141 5.41818C2.94028 5.03633 3.07475 4.51925 3.26176 4.18633C3.4303 3.88631 3.65098 3.66563 4.09233 3.22428L4.22369 3.09292C4.66656 2.65005 4.888 2.42861 5.12581 2.30833C5.59878 2.0691 6.15734 2.0691 6.6303 2.30832C6.86812 2.42861 7.08955 2.65005 7.53242 3.09291L7.69481 3.25531C7.98615 3.54665 8.13182 3.69231 8.22706 3.8388C8.58622 4.3912 8.58622 5.10336 8.22706 5.65576C8.13182 5.80225 7.98615 5.94791 7.69481 6.23925C7.59955 6.33451 7.55192 6.38214 7.51206 6.43782C7.37038 6.63568 7.32146 6.92256 7.38957 7.15619C7.40873 7.22193 7.43367 7.27389 7.48356 7.37779Z"
        stroke={props.stroke}
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconPhoneCall
