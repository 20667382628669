export const SEARCH_FMCSA_COMPANY_BY_NAME = gql`
  query SEARCH_FMCSA_COMPANY_BY_NAME($name: String!, $pageInfo: PageInfoInput) {
    searchResults: searchFMCSACompanyByName(name: $name, pageInfo: $pageInfo) {
      lastPage
      pageInfo {
        offset
        limit
      }
      companies {
        dotNumber
        companyName
        alternativeCarrierName
        phone
        address {
          street
          city
          state
          country
          zipCode
        }
      }
    }
  }
`