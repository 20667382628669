import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react'

import { useLazyQuery } from '@apollo/client'
import { useDisclosure } from '@mantine/hooks'
import { Device, Call } from '@twilio/voice-sdk'

import { useAuth } from 'src/auth'
import { GET_TWILIO_ACCESS_TOKEN } from 'src/graphql/twilio.graphql'
import { RoleType } from 'src/graphql/types/employees'
import {
  PhoneDeviceActionType,
  phoneDeviceReducer,
} from 'src/lib/phone-device.reducer'

export interface PhoneDeviceContextState {
  phoneDevice: Device
  callState: CallStatus
  call: Call
  phone: string
  applicantId: string
  applicantFullName: string
  openDeviceModal: () => void
  closeDeviceModal: () => void
  openedDeviceModal: boolean
  activityLogId: string
}

/**
 * https://www.twilio.com/docs/voice/sdks/javascript/twiliodevice#events
 *
 * DESTROYED - Emitted when the Device has been destroyed.
 * ERROR - Emitted when the Device instance receives an error. The event listener will receive a TwilioError and when applicable, a reference to the Call object that was active when the error occured.
 * INCOMING - Emitted when an incoming Call is received. An event listener will receive the Call object representing the incoming Call.
 * REGISTERED - Emitted when the Device instance is registered and able to receive incoming calls.
 * REGISTERING - Emitted when the Device instance is registering with Twilio to receive incoming calls.
 * TOKEN_WILL_EXPIRE - Emitted when the Device instance's AccessToken is about to expire. By default is 10 seconds before expiration
 * UNREGISTERED - Emitted when the Device instance has unregistered with Twilio.
 */
export enum DeviceEvent {
  DESTROYED = 'destroyed',
  ERROR = 'error',
  INCOMING = 'incoming',
  REGISTERED = 'registered',
  REGISTERING = 'registering',
  TOKEN_WILL_EXPIRE = 'tokenWillExpire',
  UNREGISTERED = 'unregistered',
}

/**
 * https://www.twilio.com/docs/voice/sdks/javascript/twiliocall#events
 *
 * ACCEPT - Emitted when an incoming Call is accepted. For outgoing calls, the'accept' event is emitted when the media session for the call has finished being set up.
 * CANCEL - Emitted when the Call instance has been canceled and the call.status() has transitioned to 'closed'. A Call instance can be canceled in two ways: Invoking call.ignore() on an incoming call or Invoking call.disconnect() on an outgoing call before the recipient has answered
 * DISCONNECT - Emitted when the media session associated with the Call instance is disconnected.
 * ERROR - Emitted when the Call instance receives an error. The event listener will receive a TwilioError object.
 * MUTE - The event listener will receive a TwilioError object.
 * RECONNECTED - Emitted when the Call instance has regained media and/or signaling connectivity.
 * RECONNECTING - Emitted when the Call instance has lost media and/or signaling connectivity and is reconnecting. The event listener will receive a TwilioError object describing the error that caused the media and/or signaling connectivity loss.
 * REJECT - Emitted when call.reject() was invoked and the call.status() is closed.
 * RINGING - Emitted when the Call has entered the ringing state.
 * VOLUME - Emitted every 50 milliseconds with the current input and output volumes on every animation frame. The event listener will receive inputVolume and outputVolume as percentages of maximum volume represented by a floating point number between 0.0 and 1.0 (inclusive). This value represents a range of relative decibel values between -100dB and -30dB.
 * WARNING - Emitted when a call quality metric has crossed a threshold.
 * WARNING_CLEARED - Emitted when a call quality metric has returned to normal.
 * CLOSED = 'closed' - The media session associated with the call has been disconnected.
 * CONNECTING = 'connecting' - The call was accepted by or initiated by the local Device instance and the media session is being set up.
 * OPEN = 'open' - The media session associated with the call has been established.
 * PENDING = 'pending' -The call is incoming and hasn't yet been accepted.
 */
export enum CallEvent {
  ACCEPT = 'accept',
  CANCEL = 'cancel',
  DISCONNECT = 'disconnect',
  MUTE = 'mute',
  RECONNECTED = 'reconnected',
  RECONNECTING = 'reconnecting',
  REJECT = 'reject',
  RINGING = 'ringing',
  VOLUME = 'volume',
  WARNING = 'warning',
  WARNING_CLEARED = 'warning-cleared',
  CLOSED = 'closed',
  CONNECTING = 'connecting',
  OPEN = 'open',
  PENDING = 'pending',
}

export enum CallStatus {
  INITIATED = 'INITIATED',
  RINGING = 'RINGING',
  IN_PROGRESS = 'IN_PROGRESS',
  NO_ANSWER = 'NO_ANSWER',
  COMPLETED = 'COMPLETED',
  LEFT_VOICEMAIL = 'LEFT_VOICEMAIL',
  LEAVING_VOICEMAIL = 'LEAVING_VOICEMAIL',
  BUSY = 'BUSY',
  FAILED = 'FAILED',
}

const PhoneDeviceContext = createContext<PhoneDeviceContextState>({
  phoneDevice: null,
  callState: null,
  call: null,
  phone: null,
  applicantId: null,
  applicantFullName: null,
  openDeviceModal: () => {},
  closeDeviceModal: () => {},
  openedDeviceModal: false,
  activityLogId: null,
})
const PhoneDeviceDispatchContext = createContext(null)

const PhoneDeviceProvider = ({ children }) => {
  const [opened, { open, close }] = useDisclosure(false)
  const { currentUser } = useAuth()

  const [state, dispatch] = useReducer(phoneDeviceReducer, {})

  let phoneDevice = null

  const [getAccessToken] = useLazyQuery(GET_TWILIO_ACCESS_TOKEN, {
    onCompleted: (data) => {
      const { accessToken } = data.getTwilioAccessToken
      phoneDevice = new Device(accessToken)
      handleDeviceStates(phoneDevice)

      dispatch({
        type: PhoneDeviceActionType.SET_PHONE_DEVICE,
        payload: phoneDevice,
      })
    },
  })

  useEffect(() => {
    state.deviceModalOpened ? open() : close()
  }, [state.deviceModalOpened, close, open])

  useEffect(() => {
    if (
      currentUser?.roles?.includes(RoleType.RECRUITER) ||
      currentUser?.roles?.includes(RoleType.ADMIN)
    ) {
      getAccessToken()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const handleDeviceStates = useCallback(
    (device) => {
      device.on(DeviceEvent.ERROR, () => {
        console.error('error')
      })

      //requires the device to be registered (device.register())
      device.on(DeviceEvent.TOKEN_WILL_EXPIRE, async () => {
        const { data } = await getAccessToken()
        const { accessToken } = data.getTwilioAccessToken

        // Use the local 'device' reference here
        device?.updateToken(accessToken)
      })
      device.on(DeviceEvent.INCOMING, (call) => {
        dispatch({
          type: PhoneDeviceActionType.SET_CALL_STATE,
          payload: {
            callState: DeviceEvent.INCOMING,
            call,
          },
        })
      })
    },
    [phoneDevice]
  )

  useEffect(() => {
    if (state.call) {
      handleCallStates(state.call)
    }
  }, [state?.call])

  const handleCallStates = useCallback((call) => {
    call.on(CallEvent.DISCONNECT, () => {
      dispatch({
        type: PhoneDeviceActionType.SET_CALL_STATE,
        payload: {
          call: null,
        },
      })
    })
  }, [])

  return (
    <PhoneDeviceContext.Provider value={state}>
      <PhoneDeviceDispatchContext.Provider value={dispatch}>
        {children}
      </PhoneDeviceDispatchContext.Provider>
    </PhoneDeviceContext.Provider>
  )
}

export function usePhoneDevice() {
  return useContext(PhoneDeviceContext)
}

export function usePhoneDeviceDispatch() {
  return useContext(PhoneDeviceDispatchContext)
}

export { PhoneDeviceContext, PhoneDeviceProvider }
