const IconLogout = (props) => {
  return (
    <svg
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      width="17"
      height="18"
      viewBox="0 0 17 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8333 13.1403L16 8.97363M16 8.97363L11.8333 4.80697M16 8.97363H6M8.5 13.1403C8.5 13.9153 8.5 14.3028 8.41482 14.6207C8.18365 15.4834 7.50978 16.1573 6.64705 16.3884C6.32913 16.4736 5.94164 16.4736 5.16667 16.4736H4.75C3.58515 16.4736 3.00272 16.4736 2.54329 16.2833C1.93072 16.0296 1.44404 15.5429 1.1903 14.9303C1 14.4709 1 13.8885 1 12.7236V5.22363C1 4.05878 1 3.47635 1.1903 3.01692C1.44404 2.40435 1.93072 1.91767 2.54329 1.66393C3.00272 1.47363 3.58515 1.47363 4.75 1.47363H5.16667C5.94164 1.47363 6.32913 1.47363 6.64705 1.55882C7.50978 1.78999 8.18365 2.46386 8.41482 3.32659C8.5 3.6445 8.5 4.03199 8.5 4.80697"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconLogout
