import { MultiSelect } from '@mantine/core'

const ApplicationMultiSelectInput = (props) => {
  const labelStyles = 'text-sm text-doubleNickel-gray-600 font-medium'
  const wrapperStyles = 'border-b-[1px] border-doubleNickel-gray-300'
  const inputStyles = `text-base font-medium`
  const rootStyles = `w-full`

  return (
    <MultiSelect
      variant="unstyled"
      classNames={{
        label: labelStyles,
        wrapper: wrapperStyles,
        input: inputStyles,
      }}
      className={rootStyles}
      {...props}
    />
  )
}

export default ApplicationMultiSelectInput
