const IconDocument = (props) => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.41667 1.66602H11.6667C13.0668 1.66602 13.7669 1.66602 14.3016 1.9385C14.772 2.17818 15.1545 2.56063 15.3942 3.03104C15.6667 3.56582 15.6667 4.26588 15.6667 5.66602V14.3327C15.6667 15.7328 15.6667 16.4329 15.3942 16.9677C15.1545 17.4381 14.772 17.8205 14.3016 18.0602C13.7669 18.3327 13.0668 18.3327 11.6667 18.3327H6.33333C4.9332 18.3327 4.23314 18.3327 3.69836 18.0602C3.22795 17.8205 2.8455 17.4381 2.60582 16.9677C2.33333 16.4329 2.33333 15.7328 2.33333 14.3327V13.7493M12.3333 10.8327H8.58333M12.3333 7.49935H9.41667M12.3333 14.166H5.66667M4 8.33268V3.74935C4 3.05899 4.55964 2.49935 5.25 2.49935C5.94036 2.49935 6.5 3.05899 6.5 3.74935V8.33268C6.5 9.71339 5.38071 10.8327 4 10.8327C2.61929 10.8327 1.5 9.71339 1.5 8.33268V4.99935"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconDocument
