const IconDashboard = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 1.66016H2.83333C2.36662 1.66016 2.13327 1.66016 1.95501 1.75098C1.79821 1.83088 1.67072 1.95836 1.59083 2.11516C1.5 2.29342 1.5 2.52678 1.5 2.99349V6.16016C1.5 6.62687 1.5 6.86022 1.59083 7.03848C1.67072 7.19528 1.79821 7.32277 1.95501 7.40266C2.13327 7.49349 2.36662 7.49349 2.83333 7.49349H6C6.46671 7.49349 6.70007 7.49349 6.87833 7.40266C7.03513 7.32277 7.16261 7.19528 7.24251 7.03848C7.33333 6.86022 7.33333 6.62687 7.33333 6.16016V2.99349C7.33333 2.52678 7.33333 2.29342 7.24251 2.11516C7.16261 1.95836 7.03513 1.83088 6.87833 1.75098C6.70007 1.66016 6.46671 1.66016 6 1.66016Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1667 1.66016H12C11.5333 1.66016 11.2999 1.66016 11.1217 1.75098C10.9649 1.83088 10.8374 1.95836 10.7575 2.11516C10.6667 2.29342 10.6667 2.52678 10.6667 2.99349V6.16016C10.6667 6.62687 10.6667 6.86022 10.7575 7.03848C10.8374 7.19528 10.9649 7.32277 11.1217 7.40266C11.2999 7.49349 11.5333 7.49349 12 7.49349H15.1667C15.6334 7.49349 15.8667 7.49349 16.045 7.40266C16.2018 7.32277 16.3293 7.19528 16.4092 7.03848C16.5 6.86022 16.5 6.62687 16.5 6.16016V2.99349C16.5 2.52678 16.5 2.29342 16.4092 2.11516C16.3293 1.95836 16.2018 1.83088 16.045 1.75098C15.8667 1.66016 15.6334 1.66016 15.1667 1.66016Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.1667 10.8268H12C11.5333 10.8268 11.2999 10.8268 11.1217 10.9177C10.9649 10.9975 10.8374 11.125 10.7575 11.2818C10.6667 11.4601 10.6667 11.6934 10.6667 12.1602V15.3268C10.6667 15.7935 10.6667 16.0269 10.7575 16.2051C10.8374 16.362 10.9649 16.4894 11.1217 16.5693C11.2999 16.6602 11.5333 16.6602 12 16.6602H15.1667C15.6334 16.6602 15.8667 16.6602 16.045 16.5693C16.2018 16.4894 16.3293 16.362 16.4092 16.2051C16.5 16.0269 16.5 15.7935 16.5 15.3268V12.1602C16.5 11.6934 16.5 11.4601 16.4092 11.2818C16.3293 11.125 16.2018 10.9975 16.045 10.9177C15.8667 10.8268 15.6334 10.8268 15.1667 10.8268Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 10.8268H2.83333C2.36662 10.8268 2.13327 10.8268 1.95501 10.9177C1.79821 10.9975 1.67072 11.125 1.59083 11.2818C1.5 11.4601 1.5 11.6934 1.5 12.1602V15.3268C1.5 15.7935 1.5 16.0269 1.59083 16.2051C1.67072 16.362 1.79821 16.4894 1.95501 16.5693C2.13327 16.6602 2.36662 16.6602 2.83333 16.6602H6C6.46671 16.6602 6.70007 16.6602 6.87833 16.5693C7.03513 16.4894 7.16261 16.362 7.24251 16.2051C7.33333 16.0269 7.33333 15.7935 7.33333 15.3268V12.1602C7.33333 11.6934 7.33333 11.4601 7.24251 11.2818C7.16261 11.125 7.03513 10.9975 6.87833 10.9177C6.70007 10.8268 6.46671 10.8268 6 10.8268Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconDashboard
