import { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js'

import { createAuth } from '@redwoodjs/auth-auth0-web'

const redirectUrl = process.env.AUTH0_REDIRECT_URI ?? window.location.origin

const auth0Options: Auth0ClientOptions = {
  domain: process.env.AUTH0_DOMAIN || '',
  clientId: process.env.AUTH0_CLIENT_ID || '',
  cacheLocation: 'localstorage',
  authorizationParams: {
    redirect_uri: redirectUrl,
    audience: process.env.AUTH0_AUDIENCE,
    scope: 'openid email profile read:current_user offline_access',
  },
}

const auth0 = new Auth0Client(auth0Options)

export const { AuthProvider, useAuth } = createAuth(auth0)
