import { DateInput as MantineDateInput } from '@mantine/dates'

// This component extends Mantine's DateInput with the following:
// -Disabled state styling
const DateInput = ({ editing = true, ...props }) => {
  //Disabled styles:
  const inputStyles = `[&[data-disabled]]:bg-doubleNickel-white [&[data-disabled]]:opacity-100 [&[data-disabled]]:text-doubleNickel-gray-900`

  return (
    <MantineDateInput
      valueFormat="MM/DD/YYYY"
      placeholder="MM/DD/YYYY"
      minDate={new Date('1900-01-01')}
      maxDate={new Date('2100-01-01')}
      variant={editing ? 'default' : 'unstyled'}
      disabled={!editing}
      classNames={{
        label: 'text-sm font-medium text-doubleNickel-gray-700',
        input: inputStyles,
      }}
      {...props}
    />
  )
}

export default DateInput
