export enum ActivityLogType {
  NOTE = 'NOTE',
  SMS = 'SMS',
  CALL = 'CALL',
  EMAIL = 'EMAIL',
  APPLICANT = 'APPLICANT',
  DOCUMENT = 'DOCUMENT',
}

export enum SMSDirection {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
}

export enum CallDirection {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
}

export enum CallStatus {
  INITIATED = 'INITIATED',
  RINGING = 'RINGING',
  IN_PROGRESS = 'IN_PROGRESS',
  NO_ANSWER = 'NO_ANSWER',
  COMPLETED = 'COMPLETED',
  LEFT_VOICEMAIL = 'LEFT_VOICEMAIL',
  LEAVING_VOICEMAIL = 'LEAVING_VOICEMAIL',
  BUSY = 'BUSY',
  FAILED = 'FAILED',
}
