const IconApplicant = (props) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 20 18"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3333 16.6602V14.9935C18.3333 13.4403 17.271 12.1352 15.8333 11.7652M12.9166 1.90246C14.1382 2.39694 15 3.59458 15 4.99349C15 6.3924 14.1382 7.59003 12.9166 8.08452M14.1666 16.6602C14.1666 15.107 14.1666 14.3304 13.9129 13.7179C13.5746 12.9011 12.9257 12.2522 12.1089 11.9139C11.4963 11.6602 10.7198 11.6602 9.16663 11.6602H6.66663C5.11349 11.6602 4.33692 11.6602 3.72435 11.9139C2.90759 12.2522 2.25867 12.9011 1.92036 13.7179C1.66663 14.3304 1.66663 15.107 1.66663 16.6602M11.25 4.99349C11.25 6.83444 9.75758 8.32682 7.91663 8.32682C6.07568 8.32682 4.58329 6.83444 4.58329 4.99349C4.58329 3.15254 6.07568 1.66016 7.91663 1.66016C9.75758 1.66016 11.25 3.15254 11.25 4.99349Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconApplicant
