export const GET_SIGNATURE_LINK = gql`
  query GetSignatureLink($applicationId: String!) {
    signatureLink(applicationId: $applicationId) {
      signatureLink
    }
  }
`

export const MARK_CONSENTS_AS_CONFIRMED = gql`
  mutation MarkConsentsAsConfirmed($applicationId: String!) {
    markConsentsAsConfirmed(applicationId: $applicationId)
  }
`

export const GET_DRIVER_CONSENT_URL_TO_DOWNLOAD = gql`
  query GetDriverConsentUrlToDownload($applicationId: String!) {
    getDriverConsentFileDownloadUrlByApplication(applicationId: $applicationId)
  }
`
