import AnvilEmbedFrame from '@anvilco/anvil-embed-frame'

export const Signature = ({
  signatureUrl,
  onSignatureComplete,
  onSignatureError,
}) => {
  return (
    <AnvilEmbedFrame
      iframeURL={signatureUrl}
      onEvent={(event) => {
        if (event.action === 'signerComplete') {
          onSignatureComplete(event)
        }
        if (event.action === 'signerError') {
          onSignatureError(event)
        }
      }}
      scroll={'auto'}
      className="flex-1"
    />
  )
}
