import { PhoneDeviceProvider } from 'src/context/PhoneDeviceContext'
import { FiltersProvider } from 'src/pages/ApplicantsPage/context/ApplicantFiltersContext'
import { WebSocketsProvider } from 'src/pages/ApplicantsPage/context/WebSocketsContext'

const AllContextProviders = ({ children }) => {
  return (
    <PhoneDeviceProvider>
      <WebSocketsProvider>
        <FiltersProvider>{children}</FiltersProvider>
      </WebSocketsProvider>
    </PhoneDeviceProvider>
  )
}

export default AllContextProviders
