import { ActionIcon } from '@mantine/core'

import Button from 'src/components/Buttons/Button/Button'
import IconChevronLeft from 'src/icons/IconChevronLeft'

import ApplicationProgressBar from './ApplicationProgressBar'

const ApplicationScreenWrapper = ({
  groupName = '',
  title = '',
  description = '',
  error = '',
  children,
  application = {},
  handleSubmit = () => {},
  handleCancel = () => {},
  goBack = () => {},
  updateLoading = false,
  hideBackButton = false,
  hideButtons = false,
  saveButtonDisabled = false,
  saveButtontext = null,
}) => {
  const isSignatureScreen = title === 'Sign your application'

  return (
    <div
      className={`flex h-[100dvh] flex-col gap-5 py-3 ${
        isSignatureScreen && 'bg-[#FBFBFC]'
      }`}
    >
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1, resizes-content"
      />
      <div className="flex flex-1 flex-col gap-5">
        <ApplicationProgressBar application={application} />

        <div
          className={`flex h-full flex-col items-center justify-between px-4 pt-4`}
        >
          <div className="flex w-[90%] flex-col items-center gap-3 sm:items-start">
            <div className="text-lg font-semibold text-doubleNickel-gray-900 sm:text-left">
              {groupName}
            </div>
            <div className="flex w-full flex-row justify-start">
              {!hideBackButton && (
                <div className="flex-1 sm:hidden">
                  <ActionIcon onClick={goBack} variant="subtle">
                    <IconChevronLeft className="h-4 w-4 fill-none stroke-doubleNickel-gray-500" />
                  </ActionIcon>
                </div>
              )}
              <div className="flex-2 text-2xl font-semibold text-doubleNickel-gray-800 sm:text-left">
                {title}
              </div>
              {!hideBackButton && <div className="flex-1 sm:hidden"></div>}
            </div>

            <div className="w-full text-base text-doubleNickel-gray-600">
              {description}
            </div>
          </div>

          <div className="flex w-[90%] flex-col items-center gap-10 py-10 sm:grid sm:grid-cols-3 ">
            {children}
          </div>

          <div className="text-md invisible font-medium text-doubleNickel-error-500">
            {error}
          </div>
          {!hideButtons && (
            <div className="flex w-full flex-col gap-4 py-4 sm:flex-row-reverse">
              <Button
                text={saveButtontext ?? 'Continue'}
                onClick={handleSubmit}
                className="h-12 w-full sm:h-10 sm:w-40"
                loading={updateLoading}
                disabled={saveButtonDisabled}
              />
              <Button
                text={'Back'}
                onClick={handleCancel}
                variant="light"
                className="hidden h-12 w-full sm:ml-auto sm:block sm:h-10 sm:w-40"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default ApplicationScreenWrapper
