import { useEffect, useRef, useState } from 'react'

import { formatAddress, parseGoogleAddress } from 'src/lib/address.utils'

import { useApiIsLoaded, useMapsLibrary } from '@vis.gl/react-google-maps'
import { TextInput } from '@mantine/core'
import ApplicationTextInput from 'src/pages/DriverApplicationPage/components/ApplicationTextInput'

const GooglePlacePicker = ({
  label = null,
  value,
  applicationInput = false,
  required = false,
  countries = ['US', 'MX'],
  type = 'address', //Alternatively: '(cities)'
  displayStreetOnly = false,
  handleChange,
  ...props
}) => {
  const isMobile = window.innerWidth < 768

  //This handles the editable text in the TextInput
  const [address, setAddress] = useState(
    displayStreetOnly ? value?.street || '' : formatAddress(value)
  )

  //This handles the parsed address object we send to the parent component
  const [addressObj, setAddressObj] = useState(value || {})

  //This handles the Google Maps Places Autocomplete component
  const [placeAutocomplete, setPlaceAutocomplete] = useState(null)
  const inputRef = useRef(null)
  const isLoaded = useApiIsLoaded()
  const placesLibrary = useMapsLibrary('places')
  const places = isLoaded ? placesLibrary : null

  useEffect(() => {
    if (!places || !inputRef.current) return

    const options = {
      //TODO: specify necessary fields to reduce payload and billing costs
      // fields: ['geometry', 'name', 'formatted_address'],
      types: [type],
      componentRestrictions: { country: countries },
    }

    setPlaceAutocomplete(new places.Autocomplete(inputRef.current, options))
  }, [places])

  useEffect(() => {
    if (!placeAutocomplete) return
    placeAutocomplete.addListener('place_changed', () => {
      const place = placeAutocomplete.getPlace()
      //This updates the text in the TextInput
      if (displayStreetOnly) {
        setAddress(place.name)
      } else {
        setAddress(place.formatted_address || place.name)
      }
      const placeObject = parseGoogleAddress(place)
      //This updates the address object we send to the parent component
      setAddressObj(placeObject)
    })
  }, [handleChange, placeAutocomplete])

  //Update the parent component with the address object
  useEffect(() => {
    if (addressObj) {
      handleChange(addressObj)
    }
  }, [addressObj])

  return (
    <div className="flex w-full flex-col gap-1">
      {label && (
        <div
          className={`flex flex-row justify-start gap-1 text-sm font-medium text-doubleNickel-gray-700`}
        >
          {label}
          {required && <span className="text-red-500">*</span>}
        </div>
      )}

      {applicationInput ? (
        <ApplicationTextInput
          ref={inputRef}
          value={address}
          onChange={(event) => {
            setAddress(event.target.value)
            //This allows us to manually change the street value
            if (displayStreetOnly) {
              setAddressObj({ ...addressObj, street: event.target.value })
            }
          }}
          {...props}
        />
      ) : (
        <TextInput
          ref={inputRef}
          value={address}
          onChange={(event) => {
            setAddress(event.target.value)
            //This allows us to manually change the street value
            if (displayStreetOnly) {
              setAddressObj({ ...addressObj, street: event.target.value })
            }
          }}
          {...props}
        />
      )}
    </div>
  )
}

export default GooglePlacePicker
