import { ReactNode } from 'react'

import IconAlertCircle from 'src/icons/IconAlertCircle'

interface AlertProps {
  title?: string
  description?: string | ReactNode
  variant?: 'brand' | 'warning' | 'error'
}

const StyledAlert = ({ title, description, variant = 'brand' }: AlertProps) => {
  const colorVariant = {
    bg: 'bg-doubleNickel-brand-25',
    icon: 'stroke-doubleNickel-brand-500',
    text: 'text-doubleNickel-brand-500',
  }

  if (variant === 'warning') {
    colorVariant.bg = 'bg-doubleNickel-warning-50'
    colorVariant.icon = 'stroke-doubleNickel-warning-500'
    colorVariant.text = 'text-doubleNickel-warning-500'
  } else if (variant === 'error') {
    colorVariant.bg = 'bg-doubleNickel-error-50'
    colorVariant.icon = 'stroke-doubleNickel-error-500'
    colorVariant.text = 'text-doubleNickel-error-500'
  }

  return (
    <div
      className={`flex flex-row items-center gap-6 rounded-lg ${colorVariant.bg} p-4`}
    >
      <IconAlertCircle className={`fill-none ${colorVariant.icon}`} />
      <div
        className={`flex flex-col justify-center gap-1 ${colorVariant.text}`}
      >
        {title && (
          <div className="sm:text-md text-sm font-semibold">{title}</div>
        )}
        {description && <div className="sm:text-md text-sm">{description}</div>}
      </div>
    </div>
  )
}

export default StyledAlert
