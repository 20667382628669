export const GET_APPLICATION_BY_APPLICANT_ID = gql`
  query ApplicationByApplicantId($applicantId: String!) {
    applicationByApplicantId(applicantId: $applicantId) {
      applicationId
      status
      firstName
      middleName
      lastName
      email
      phone
      dob
      ssn
      issuingCountry
      availableForWork
      createdAt
      updatedAt
      drivingExperiences {
        drivingExperienceId
        equipmentClass
        equipmentType
        startDate
        endDate
        approxMilesDriven
      }
      trackingLink {
        trackingLinkId
        linkType
        linkName
        link
      }
      applicant {
        applicantId
        yearsOfExperience
        applicationDate
        hiringStage
        applicantStage
        jobListing {
          jobListingId
          title
        }
      }
      license {
        licenseId
        licenseNumber
        licenseClass
        state
        country
        expirationDate
        medicalExamExpirationDate
        endorsements
        createdAt
        updatedAt
        isLicenseSuspended
      }
      twicCard {
        twicCardId
        createdAt
        updatedAt
        cardNumber
        expirationDate
      }
      equipment {
        equipmentId
        createdAt
        updatedAt
        type
        year
        make
        model
        color
        vin
        weight
        mileage
        fifthWheelHeightInches
      }
      addresses {
        addressId
        street
        city
        state
        country
        zipCode
        startDate
        endDate
      }
      accidents {
        accidentId
        createdAt
        updatedAt
        accidentDate
        city
        state
        country
        description
        isPreventable
        isCommercialMotorVehicleInvolved
      }
      employmentExperiences {
        isUnemployment
        unemploymentReason
        employmentExperienceId
        createdAt
        updatedAt
        startDate
        endDate
        companyName
        phone
        faxNumber
        position
        dotNumber
        contactName
        email
        reasonForLeaving
        isDriverTerminated
        isDriverSubjectToFMCSRs
        isJobDesignatedAsSafetySensitiveFunctionUnderDOT
        shouldContactEmployer
        address {
          street
          city
          state
          country
          zipCode
        }
      }
      consents {
        consentId
        title
        confirmed
        signed
        document {
          documentId
          fileName
          path
          fileType
        }
      }
    }
  }
`

export const GET_APPLICATION = gql`
  query Application($id: String!) {
    application(id: $id) {
      applicationId
      status
      firstName
      middleName
      lastName
      email
      phone
      dob
      ssn
      issuingCountry
      yearsOfExperience
      availableForWork
      createdAt
      updatedAt
      company {
        companyId
      }
      drivingExperiences {
        drivingExperienceId
        equipmentClass
        equipmentType
        startDate
        endDate
        approxMilesDriven
      }
      trackingLink {
        trackingLinkId
        linkType
        linkName
        link
      }
      accidents {
        accidentId
        createdAt
        updatedAt
        accidentDate
        city
        state
        country
        description
        isPreventable
        isCommercialMotorVehicleInvolved
      }
      applicant {
        applicantId
        yearsOfExperience
        applicationDate
        hiringStage
        applicantStage
        jobListing {
          jobListingId
          title
        }
      }
      license {
        licenseId
        licenseNumber
        licenseClass
        state
        country
        expirationDate
        medicalExamExpirationDate
        endorsements
        createdAt
        updatedAt
        isLicenseSuspended
      }
      twicCard {
        twicCardId
        createdAt
        updatedAt
        cardNumber
        expirationDate
      }
      equipment {
        equipmentId
        createdAt
        updatedAt
        type
        year
        make
        model
        color
        vin
        weight
        mileage
        fifthWheelHeightInches
      }
      addresses {
        addressId
        street
        city
        state
        country
        zipCode
        startDate
        endDate
      }
      employmentExperiences {
        employmentExperienceId
        createdAt
        updatedAt
        startDate
        endDate
        companyName
        phone
        faxNumber
        position
        dotNumber
        contactName
        email
        reasonForLeaving
        isUnemployment
        unemploymentReason
        isDriverTerminated
        isDriverSubjectToFMCSRs
        isJobDesignatedAsSafetySensitiveFunctionUnderDOT
        shouldContactEmployer
        address {
          street
          city
          state
          country
          zipCode
        }
      }
      consents {
        consentId
        title
        confirmed
        signed
        consentTemplate {
          fileName
        }
      }
      company {
        companyId
        companyName
      }
    }
  }
`

export const UPDATE_APPLICATION = gql`
  mutation updateApplication($id: String!, $input: UpdateApplicationInput!) {
    updateApplication(id: $id, input: $input) {
      applicationId
      status
      firstName
      middleName
      lastName
      email
      phone
      dob
      ssn
      issuingCountry
      yearsOfExperience
      availableForWork
      createdAt
      updatedAt
      license {
        licenseId
        licenseNumber
        licenseClass
        state
        country
        expirationDate
        medicalExamExpirationDate
        endorsements
        isLicenseSuspended
      }
      twicCard {
        twicCardId
        createdAt
        updatedAt
        cardNumber
        expirationDate
      }
      equipment {
        equipmentId
        createdAt
        updatedAt
        type
        year
        make
        model
        color
        vin
        weight
        mileage
        fifthWheelHeightInches
      }
      accidents {
        accidentId
        createdAt
        updatedAt
        accidentDate
        city
        state
        country
        description
        isPreventable
        isCommercialMotorVehicleInvolved
      }
      drivingExperiences {
        drivingExperienceId
        equipmentClass
        equipmentType
        startDate
        endDate
        approxMilesDriven
      }
      addresses {
        addressId
        street
        city
        state
        country
        zipCode
        startDate
        endDate
      }
      employmentExperiences {
        employmentExperienceId
        createdAt
        updatedAt
        startDate
        endDate
        companyName
        phone
        faxNumber
        position
        dotNumber
        email
        contactName
        reasonForLeaving
        isDriverTerminated
        isDriverSubjectToFMCSRs
        isJobDesignatedAsSafetySensitiveFunctionUnderDOT
        shouldContactEmployer
        isUnemployment
        unemploymentReason
        address {
          addressId
          street
          city
          state
          country
          zipCode
        }
      }
    }
  }
`

export const MARK_APPLICATION_AS_SUBMITTED = gql`
  mutation markApplicationAsSubmitted($id: String!) {
    markApplicationAsSubmitted(id: $id)
  }
`

export const COPY_APPLICATION = gql`
  mutation copyApplication($input: CopyApplicationInput!) {
    copyApplication(input: $input) {
      newApplicationId
      newApplicantId
    }
  }
`
