import { useQuery } from '@redwoodjs/web'
import { createContext } from 'react'
import { GET_EMPLOYEES_ONLY } from 'src/graphql/employees.graphql'

const RecruitersContext = createContext(null)

const RecruitersContextProvider = ({ children }) => {
  const { data: employees, loading: employeesLoading } = useQuery(
    GET_EMPLOYEES_ONLY,
    {
      variables: {
        filters: { roleTypes: 'RECRUITER' },
      },
    }
  )
  const recruiters = employeesLoading
    ? []
    : employees?.employees?.items?.map((employee) => ({
        label: employee.firstName + ' ' + employee.lastName,
        value: employee.employeeId,
      })) ?? []

  return (
    <RecruitersContext.Provider value={recruiters}>
      {children}
    </RecruitersContext.Provider>
  )
}

export { RecruitersContext, RecruitersContextProvider }
