export enum WebSocketsActionType {
  NEW_NOTIFICATION,
  CLEAR_NEW_NOTIFICATION,
}

export const websocketsReducer = (state, action) => {
  const { payload } = action
  switch (action.type) {
    case WebSocketsActionType.NEW_NOTIFICATION: {
      return {
        ...state,
        newNotification: payload.new,
      }
    }
    case WebSocketsActionType.CLEAR_NEW_NOTIFICATION: {
      return {
        ...state,
        newNotification: null,
      }
    }
    default: {
      return state
    }
  }
}
