import { useState } from 'react'

import { Space } from '@mantine/core'

import { Logo, Name } from 'src/icons'

export interface HeaderProps {
  companyId?: string
}

const Header = ({ companyId }: HeaderProps) => {
  const [logoError, setLogoError] = useState(false)

  return (
    <div className="flex w-full flex-row items-center justify-between border-b-[1px]  bg-white px-4 py-5 sm:px-6 sm:py-4">
      <div className={`flex flex-row items-center gap-3`}>
        {logoError ? (
          <div className="flex flex-row gap-2">
            <img src={Logo} alt="Logo" className="h-8 " />
            <img src={Name} alt="Name" className="h-8 " />
          </div>
        ) : (
          <img
            src={`${process.env.AWS_PUBLIC_CDN_DOMAIN}/company/${companyId}/logo.png`}
            onError={() => {
              setLogoError(true)
            }}
            alt="Logo"
            className={`h-8 `}
          />
        )}
      </div>
      <Space />
    </div>
  )
}

export default Header
