const IconSettings = (props) => {
  return (
    <svg
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.82924 15.3031L7.31628 16.3984C7.46106 16.7245 7.69734 17.0016 7.99647 17.196C8.29559 17.3904 8.6447 17.4939 9.00146 17.4938C9.35822 17.4939 9.70734 17.3904 10.0065 17.196C10.3056 17.0016 10.5419 16.7245 10.6866 16.3984L11.1737 15.3031C11.3471 14.9144 11.6387 14.5904 12.007 14.3772C12.3777 14.1633 12.8065 14.0723 13.232 14.117L14.4237 14.2438C14.7784 14.2813 15.1364 14.2151 15.4543 14.0533C15.7721 13.8914 16.0362 13.6407 16.2144 13.3318C16.3929 13.023 16.4779 12.669 16.4592 12.3129C16.4405 11.9567 16.3188 11.6136 16.1089 11.3253L15.4033 10.3559C15.1521 10.0081 15.0178 9.58949 15.02 9.16048C15.0199 8.73264 15.1554 8.31577 15.407 7.96974L16.1126 7.0003C16.3225 6.71194 16.4442 6.36887 16.4629 6.01271C16.4816 5.65655 16.3966 5.3026 16.2181 4.99382C16.0399 4.68484 15.7758 4.43422 15.458 4.27234C15.1401 4.11045 14.7821 4.04426 14.4274 4.08178L13.2357 4.20863C12.8102 4.25332 12.3814 4.16225 12.0107 3.94844C11.6417 3.73403 11.35 3.40828 11.1774 3.01789L10.6866 1.92252C10.5419 1.59646 10.3056 1.31941 10.0065 1.12498C9.70734 0.93055 9.35822 0.82709 9.00146 0.827148C8.6447 0.82709 8.29559 0.93055 7.99647 1.12498C7.69734 1.31941 7.46106 1.59646 7.31628 1.92252L6.82924 3.01789C6.65668 3.40828 6.36497 3.73403 5.99591 3.94844C5.62525 4.16225 5.19647 4.25332 4.77091 4.20863L3.57554 4.08178C3.22081 4.04426 2.86282 4.11045 2.54497 4.27234C2.22711 4.43422 1.96305 4.68484 1.7848 4.99382C1.60632 5.3026 1.52128 5.65655 1.54002 6.01271C1.55876 6.36887 1.68046 6.71194 1.89035 7.0003L2.59591 7.96974C2.84753 8.31577 2.98302 8.73264 2.98295 9.16048C2.98302 9.58833 2.84753 10.0052 2.59591 10.3512L1.89035 11.3207C1.68046 11.609 1.55876 11.9521 1.54002 12.3083C1.52128 12.6644 1.60632 13.0184 1.7848 13.3271C1.96323 13.636 2.22732 13.8864 2.54513 14.0483C2.86294 14.2102 3.22084 14.2765 3.57554 14.2392L4.76721 14.1123C5.19276 14.0676 5.62155 14.1587 5.99221 14.3725C6.36265 14.5863 6.65571 14.9121 6.82924 15.3031Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.99997 11.6605C10.3807 11.6605 11.5 10.5412 11.5 9.16048C11.5 7.77977 10.3807 6.66048 8.99997 6.66048C7.61926 6.66048 6.49998 7.77977 6.49998 9.16048C6.49998 10.5412 7.61926 11.6605 8.99997 11.6605Z"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconSettings
