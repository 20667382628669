import { Anchor, Breadcrumbs, Progress, Stepper } from '@mantine/core'

import { useLocation } from '@redwoodjs/router'

import Header from './Header'

const StyledBreadcrumb = ({ title, index, progress }) => {
  return (
    <Anchor
      underline="never"
      key={index}
      className={`font-semibold ${
        index === progress
          ? 'text-doubleNickel-brand-500'
          : index < progress
          ? 'text-black'
          : 'text-doubleNickel-gray-500'
      }`}
    >
      {title}
    </Anchor>
  )
}

const ApplicationProgressBar = ({ application }) => {
  const { pathname } = useLocation()
  const currentScreen = pathname.split('/').pop()

  const calculateProgress = () => {
    switch (currentScreen) {
      case 'address-information':
        return 2
      case 'license-information':
        return 3
      case 'equipment-information':
        return 4
      case 'accident-history':
        return 5
      case 'employment-history':
        return 6
      case 'consents':
        return 7
      case 'review':
        return 8
      case 'submit':
        return 9
      default:
        return 1
    }
  }
  const progress = calculateProgress()

  return (
    <>
      <Header companyId={application?.company?.companyId} />
      <div className="flex w-full flex-row items-center gap-2 px-10 pt-6 sm:hidden">
        <Progress
          value={(progress / 2) * 100}
          className="flex-1"
          radius="xs"
          size="sm"
        />
        <Progress
          value={progress > 2 ? 100 : 0}
          className="flex-1"
          radius="xs"
          size="sm"
        />
        <Progress
          value={progress > 3 ? 100 : 0}
          className="flex-1"
          radius="xs"
          size="sm"
        />
        <Progress
          value={progress > 4 ? 100 : 0}
          className="flex-1"
          radius="xs"
          size="sm"
        />
        <Progress
          value={progress > 5 ? 100 : 0}
          className="flex-1"
          radius="xs"
          size="sm"
        />
        <Progress
          value={progress > 6 ? 100 : 0}
          className="flex-1"
          radius="xs"
          size="sm"
        />
        <Progress
          value={progress > 7 ? 100 : 0}
          className="flex-1"
          radius="xs"
          size="sm"
        />
        <Progress
          value={progress > 8 ? 100 : 0}
          className="flex-1"
          radius="xs"
          size="sm"
        />
      </div>
      <div className="hidden px-[5%] sm:block">
        <Breadcrumbs separator=">" separatorMargin="md" mt="xs">
          <StyledBreadcrumb
            title="Personal Information"
            index={1}
            progress={progress}
          />
          <StyledBreadcrumb title="Addresses" index={2} progress={progress} />
          <StyledBreadcrumb title="Licenses" index={3} progress={progress} />
          <StyledBreadcrumb title="Equipment" index={4} progress={progress} />
          <StyledBreadcrumb title="Accidents" index={5} progress={progress} />
          <StyledBreadcrumb
            title="Employment History"
            index={6}
            progress={progress}
          />
          <StyledBreadcrumb title="Consents" index={7} progress={progress} />
          <StyledBreadcrumb title="Review" index={8} progress={progress} />
        </Breadcrumbs>
      </div>
    </>
  )
}

export default ApplicationProgressBar
