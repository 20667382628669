const IconDocumentCheck = (props) => {
  return (
    <svg
      width="17"
      height="20"
      viewBox="0 0 17 20"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6663 10.4167V5.66675C14.6663 4.26662 14.6663 3.56655 14.3939 3.03177C14.1542 2.56137 13.7717 2.17892 13.3013 1.93923C12.7665 1.66675 12.0665 1.66675 10.6663 1.66675H5.33301C3.93288 1.66675 3.23281 1.66675 2.69803 1.93923C2.22763 2.17892 1.84517 2.56137 1.60549 3.03177C1.33301 3.56655 1.33301 4.26662 1.33301 5.66675V14.3334C1.33301 15.7335 1.33301 16.4336 1.60549 16.9684C1.84517 17.4388 2.22763 17.8212 2.69803 18.0609C3.23281 18.3334 3.93288 18.3334 5.33301 18.3334H7.99967M9.66634 9.16675H4.66634M6.33301 12.5001H4.66634M11.333 5.83341H4.66634M10.083 15.8334L11.7497 17.5001L15.4997 13.7501"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconDocumentCheck
