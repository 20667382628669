// FormContext.js
import React, { createContext, useContext } from 'react'

import { datadogRum } from '@datadog/browser-rum'
import { useForm } from '@mantine/form'
import { zodResolver } from 'mantine-form-zod-resolver'
import { v1 as uuid } from 'uuid'

import { navigate, routes, useLocation, useParams } from '@redwoodjs/router'
import { useMutation, useQuery } from '@redwoodjs/web'

import { useAuth } from 'src/auth'
import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  GET_APPLICATION,
  MARK_APPLICATION_AS_SUBMITTED,
  UPDATE_APPLICATION,
} from 'src/graphql/application.graphql'
import { MARK_CONSENTS_AS_CONFIRMED } from 'src/graphql/consent.graphql'

import { defaultExperience } from '../screens/EmploymentScreen'

import { generateInitialValues, schema } from './form'
import { validateAllFields } from './validators'

const FormLoader = ({ application, children }) => {
  //Initialize form with the application data
  const form = useForm({
    validateInputOnBlur: true,
    initialValues: generateInitialValues(application),
    validate: zodResolver(schema),
  })

  //Add placeholder for current address
  const defaultAddress = {
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: '',
    startDate: undefined,
    endDate: null,
  }

  if (form.values.addresses.length === 0) {
    form.setFieldValue('addresses', [defaultAddress])
  }

  if (form.values.employmentExperiences.length === 0) {
    const experience = { ...defaultExperience }
    experience.employmentExperienceId = uuid()
    form.setFieldValue('employmentExperiences', [experience])
  }

  //Return the form context
  return <FormContext.Provider value={form}>{children}</FormContext.Provider>
}

const FormContext = createContext(null)

export const FormProvider = ({ children }) => {
  const { applicationId } = useParams()
  const { currentUser } = useAuth()

  const { data: applicationResponse, loading } = useQuery(GET_APPLICATION, {
    variables: {
      id: applicationId,
    },
    onCompleted: (data) => {
      if (data?.application === null) {
        window.location.href = '/404'
        return
      }
      if (
        data?.application?.applicationId &&
        data.application.applicationId !== applicationId
      ) {
        navigate(
          `/driver-application?applicationId=${data.application.applicationId}`
        )
      }
      datadogRum.setUser({
        id: currentUser.userId,
        email: currentUser.email,
        phone: currentUser.phone,
        roles: currentUser.roles,
        company: data?.application.company.companyId,
        companyName: data?.application.company.companyName,
      })
    },
    onError: () => {
      navigate(routes.forbidden())
    },
  })

  if (loading) return null

  return (
    <>
      {applicationResponse && (
        <FormLoader application={applicationResponse.application}>
          {children}
        </FormLoader>
      )}
    </>
  )
}

export const useFormContext = () => {
  const form = useContext(FormContext)
  const { applicationId } = useParams()
  const { pathname } = useLocation()
  const currentScreen = pathname.split('/').pop()

  const goBack = () => {
    switch (currentScreen) {
      case 'address-information':
        navigate(`/driver-application?applicationId=${applicationId}`)
        break
      case 'license-information':
        navigate(
          `/driver-application/address-information?applicationId=${applicationId}`
        )
        break
      case 'equipment-information':
        navigate(
          `/driver-application/license-information?applicationId=${applicationId}`
        )
        break
      case 'accident-history':
        navigate(
          `/driver-application/equipment-information?applicationId=${applicationId}`
        )
        break
      case 'employment-history':
        navigate(
          `/driver-application/accident-history?applicationId=${applicationId}`
        )
        break
      case 'consents':
        navigate(
          `/driver-application/employment-history?applicationId=${applicationId}`
        )
        break
      case 'review':
        navigate(`/driver-application/consents?applicationId=${applicationId}`)
        break
      case 'submit':
        navigate(`/driver-application/review?applicationId=${applicationId}`)
        break
      default:
        navigate(`/driver-application?applicationId=${applicationId}`)
        break
    }
  }

  const handleNavigation = () => {
    switch (currentScreen) {
      case 'driver-application':
        navigate(
          `/driver-application/address-information?applicationId=${applicationId}`
        )
        break
      case 'address-information':
        navigate(
          `/driver-application/license-information?applicationId=${applicationId}`
        )
        break
      case 'license-information':
        navigate(
          `/driver-application/equipment-information?applicationId=${applicationId}`
        )
        break
      case 'equipment-information':
        navigate(
          `/driver-application/accident-history?applicationId=${applicationId}`
        )
        break
      case 'accident-history':
        navigate(
          `/driver-application/employment-history?applicationId=${applicationId}`
        )
        break
      case 'employment-history':
        navigate(`/driver-application/consents?applicationId=${applicationId}`)
        break
      case 'consents':
        navigate(`/driver-application/review?applicationId=${applicationId}`)
        break
      case 'review':
        navigate(`/driver-application/submit?applicationId=${applicationId}`)
        break
      default:
        navigate(`/driver-application?applicationId=${applicationId}`)
        break
    }
  }

  const [updateApplication, { loading: updateLoading }] = useMutation(
    UPDATE_APPLICATION,
    {
      onCompleted: () => {
        handleNavigation()
      },
      onError: () => {
        toast('Unable to save application. Please try again later.', 'error')
      },
      refetchQueries: [
        {
          query: GET_APPLICATION,
          variables: {
            id: applicationId,
          },
        },
      ],
    }
  )

  const { data: applicationResponse } = useQuery(GET_APPLICATION, {
    variables: {
      id: applicationId,
    },
    onCompleted: (data) => {
      if (data?.application === null) {
        window.location.href = '/404'
        return
      }
    },
    onError: () => {
      navigate(routes.forbidden())
    },
  })

  const application = applicationResponse?.application || null

  const [markApplicationAsSubmitted] = useMutation(
    MARK_APPLICATION_AS_SUBMITTED,
    {
      variables: {
        id: applicationId,
      },
      onCompleted: () => {
        toast('Application updated successfully', 'success')
        handleNavigation()
      },
      onError: () => {
        toast('Unable to save application. Please try again later.', 'error')
      },
    }
  )

  const onSignatureComplete = () => {
    markApplicationAsSubmitted()
  }

  const [markConsentsAsConfirmed, { loading: loadingUpdateConsents }] =
    useMutation(MARK_CONSENTS_AS_CONFIRMED, {
      variables: {
        applicationId,
      },
      onCompleted: () => {
        handleNavigation()
      },
      onError: () => {
        toast('Unable to save application. Please try again later.', 'error')
      },
    })

  const onConsentsConfirmed = () => {
    markConsentsAsConfirmed()
  }

  const handleNext = (input) => {
    const validationHasError = validateAllFields(form, input)
    if (!validationHasError) {
      //TODO: check what happens to the inputs if this fails, may have to refetch the application
      updateApplication({
        variables: {
          id: applicationId,
          input: input,
        },
      })
    }
  }

  return {
    form,
    goBack,
    handleNext,
    updateLoading,
    onSignatureComplete,
    onConsentsConfirmed,
    loadingUpdateConsents,
    applicationId,
    application,
  }
}
