const IconAlertCircle = (props) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.3">
        <rect x="6" y="6" width="22" height="22" rx="11" strokeWidth="2" />
      </g>
      <g opacity="0.1">
        <rect x="1" y="1" width="32" height="32" rx="16" strokeWidth="2" />
      </g>
      <g clipPath="url(#clip0_5411_8518)">
        <path
          d="M16.9999 14.333V16.9997M16.9999 19.6663H17.0066M23.6666 16.9997C23.6666 20.6816 20.6818 23.6663 16.9999 23.6663C13.318 23.6663 10.3333 20.6816 10.3333 16.9997C10.3333 13.3178 13.318 10.333 16.9999 10.333C20.6818 10.333 23.6666 13.3178 23.6666 16.9997Z"
          strokeWidth="1.33333"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5411_8518">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(9 9)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export default IconAlertCircle
