export enum ApplicantStage {
  PROSPECT = 'PROSPECT',
  HIRED = 'HIRED',
  TERMINATED = 'TERMINATED',
  NOT_INTERESTED = 'NOT_INTERESTED',
  DISQUALIFIED = 'DISQUALIFIED',
}

export enum HiringStage {
  LEAD = 'LEAD',
  APPLICATION = 'APPLICATION',
  QUALIFICATION = 'QUALIFICATION',
  ONBOARDING = 'ONBOARDING',
}

export enum NotInterestedReason {
  ACCEPTED_ANOTHER_JOB = 'ACCEPTED_ANOTHER_JOB',
  BENEFITS = 'BENEFITS',
  CAMERAS = 'CAMERAS',
  DECLINED_JOB_OFFER = 'DECLINED_JOB_OFFER',
  DISLIKES_TEAM_DRIVING = 'DISLIKES_TEAM_DRIVING',
  EQUIPMENT_PREFERENCES = 'EQUIPMENT_PREFERENCES',
  FAMILY_ISSUES = 'FAMILY_ISSUES',
  HAPPY_WITH_CURRENT_JOB = 'HAPPY_WITH_CURRENT_JOB',
  HOME_TIME_PREFERENCES = 'HOME_TIME_PREFERENCES',
  INTEREST_IN_LOCAL_ROLES = 'INTEREST_IN_LOCAL_ROLES',
  MILEAGE_CONCERNS = 'MILEAGE_CONCERNS',
  NO_CONTACT_REQUESTED = 'NO_CONTACT_REQUESTED',
  NOT_CONTACTED = 'NOT_CONTACTED',
  OTHER = 'OTHER',
  PAY_CONCERNS = 'PAY_CONCERNS',
  POSITION_NOT_AVAILABLE = 'POSITION_NOT_AVAILABLE',
  PREFERRED_POSITION_UNAVAILABLE = 'PREFERRED_POSITION_UNAVAILABLE',
}

export enum DisqualifiedReason {
  ACCIDENT = 'ACCIDENT',
  BELOW_AGE_REQUIREMENT = 'BELOW_AGE_REQUIREMENT',
  CRIMINAL_RECORD = 'CRIMINAL_RECORD',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  DRIVING_SCHOOL = 'DRIVING_SCHOOL',
  DRUG_SCREEN_FAILURE = 'DRUG_SCREEN_FAILURE',
  EMPLOYMENT_HISTORY = 'EMPLOYMENT_HISTORY',
  LACK_OF_EXPERIENCE = 'LACK_OF_EXPERIENCE',
  LEASE_NOT_APPROVED = 'LEASE_NOT_APPROVED',
  LOCATION_MISMATCH = 'LOCATION_MISMATCH',
  MEDICAL_REASONS = 'MEDICAL_REASONS',
  MOTOR_VEHICLE_RECORD = 'MOTOR_VEHICLE_RECORD',
  NO_FAST = 'NO_FAST',
  OTHER = 'OTHER',
  OUT_OF_HIRING_AREA = 'OUT_OF_HIRING_AREA',
  POOR_REFERENCES = 'POOR_REFERENCES',
  ROAD_TEST = 'ROAD_TEST',
  SAP = 'SAP',
}

export const TerminatedReason = {
  ATTENDANCE_ISSUES: 'ATTENDANCE_ISSUES',
  END_OF_CONTRACT: 'END_OF_CONTRACT',
  FAILED_DRUG_SCREEN: 'FAILED_DRUG_SCREEN',
  INSUBORDINATION: 'INSUBORDINATION',
  LAYOFF_REDUNDANCY: 'LAYOFF_REDUNDANCY',
  OTHER: 'OTHER',
  PERFORMANCE_ISSUES: 'PERFORMANCE_ISSUES',
  VIOLATION_OF_COMPANY_POLICY: 'VIOLATION_OF_COMPANY_POLICY',
  VOLUNTARY_TERMINATION: 'VOLUNTARY_TERMINATION',
}
