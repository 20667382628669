import { forwardRef } from 'react'

import { Avatar, UnstyledButton } from '@mantine/core'

import { useAuth } from 'src/auth'
import IconChevronDown from 'src/icons/IconChevronDown'

interface ProfileMenuButtonProps
  extends React.ComponentPropsWithoutRef<'button'> {}

export const ProfileMenuButton = forwardRef<
  HTMLButtonElement,
  ProfileMenuButtonProps
>(({ ...props }, ref) => {
  const { userMetadata, currentUser } = useAuth()
  return (
    <UnstyledButton
      ref={ref}
      {...props}
      className="rounded-md p-2 hover:bg-doubleNickel-gray-100"
    >
      <div className="flex flex-row items-center gap-4">
        <Avatar src={userMetadata?.picture} radius="lg" size={32} />
        <div className="hidden flex-col sm:flex">
          <div className="text-xs font-semibold capitalize text-doubleNickel-gray-700">
            {currentUser?.firstName && ` ${currentUser?.firstName}`}
            {currentUser?.lastName && ` ${currentUser?.lastName}`}
          </div>
          <div className="text-xs text-doubleNickel-gray-600">
            {userMetadata?.email ?? 'firstname@email.com'}
          </div>
        </div>
        <IconChevronDown className="h-4 w-4 fill-none stroke-doubleNickel-gray-600" />
      </div>
    </UnstyledButton>
  )
})
