import { forwardRef, ReactNode } from 'react'

import { Button as MantineButton } from '@mantine/core'

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children?: ReactNode
  text: string
  lefticon?: React.ReactElement
  righticon?: React.ReactElement
  variant?:
    | 'filled'
    | 'outline'
    | 'link'
    | 'transparent'
    | 'error'
    | 'subtle'
    | 'light'
    | 'link_no_underline'
  loading?: boolean
}

export type Ref = HTMLButtonElement

const Button = forwardRef<Ref, ButtonProps>(
  (
    {
      text,
      lefticon,
      righticon,
      variant = 'filled',
      loading = false,
      ...props
    },
    ref
  ) => {
    return (
      <MantineButton
        ref={ref}
        {...props}
        variant={variant}
        loading={loading}
        leftSection={
          lefticon &&
          React.cloneElement(lefticon, {
            className: `h-4 w-4 fill-none stroke-doubleNickel-brand-500
            ${variant === 'outline' ? 'stroke-doubleNickel-gray-700' : ''}
            ${
              variant === 'filled'
                ? `${
                    props.disabled
                      ? 'stroke-doubleNickel-black'
                      : 'stroke-doubleNickel-white'
                  }`
                : ''
            }
            ${
              variant === 'subtle'
                ? 'fill-none stroke-doubleNickel-brand-500'
                : ''
            }
            ${variant === 'error' ? 'fill-none stroke-doubleNickel-white' : ''}
            ${variant === 'transparent' ? 'stroke-doubleNickel-gray-600' : ''}
            ${lefticon.props.className || ''}`,
          })
        }
        rightSection={
          righticon &&
          React.cloneElement(righticon, {
            className: `h-4 w-4 fill-none stroke-doubleNickel-brand-500
            ${variant === 'outline' ? 'stroke-doubleNickel-gray-700' : ''}
            ${
              variant === 'filled'
                ? `${
                    props.disabled
                      ? 'stroke-doubleNickel-black'
                      : 'stroke-doubleNickel-white'
                  }`
                : ''
            }
            ${
              variant === 'subtle'
                ? 'fill-none stroke-doubleNickel-brand-500'
                : ''
            }
            ${variant === 'error' ? 'fill-none stroke-doubleNickel-white' : ''}
            ${variant === 'transparent' ? 'stroke-doubleNickel-gray-600' : ''}
            ${righticon.props.className || ''}`,
          })
        }
        className={`rounded-lg text-xs drop-shadow-sm
          ${props.disabled && 'opacity-50'}
          ${
            variant === 'outline' &&
            'border border-doubleNickel-gray-300 bg-doubleNickel-white text-doubleNickel-gray-700 hover:bg-doubleNickel-gray-50'
          }
          ${
            variant === 'link' &&
            'hover:none bg-transparent px-0 text-doubleNickel-brand-500 underline drop-shadow-none'
          }
          ${
            variant === 'transparent' &&
            'text-doubleNickel-gray-600 hover:bg-doubleNickel-gray-50'
          }
          ${
            variant === 'error' &&
            'bg-doubleNickel-error-500 text-doubleNickel-white hover:bg-doubleNickel-error-700 disabled:bg-doubleNickel-error-100 disabled:text-doubleNickel-gray-400'
          }
          ${
            variant === 'filled' &&
            'bg-doubleNickel-brand-500 text-doubleNickel-white hover:bg-doubleNickel-brand-700 disabled:bg-doubleNickel-gray-100 disabled:text-doubleNickel-black'
          }
          ${
            variant === 'link_no_underline' &&
            'hover:none bg-transparent px-0 text-doubleNickel-brand-500 drop-shadow-none'
          }
          ${props.className || ''} `}
      >
        {text}
      </MantineButton>
    )
  }
)

export default Button
