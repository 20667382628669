const IconEmptyInbox = (props) => {
  return (
    <svg
      width="250"
      height="201"
      viewBox="0 0 250 201"
      fill={props.fill}
      stroke={props.stroke}
      className={props.className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="250" height="200" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63 134.5H154C154.515 134.5 155.017 134.444 155.5 134.339C155.983 134.444 156.485 134.5 157 134.5H209C212.866 134.5 216 131.366 216 127.5C216 123.634 212.866 120.5 209 120.5H203C199.134 120.5 196 117.366 196 113.5C196 109.634 199.134 106.5 203 106.5H222C225.866 106.5 229 103.366 229 99.5C229 95.634 225.866 92.5 222 92.5H200C203.866 92.5 207 89.366 207 85.5C207 81.634 203.866 78.5 200 78.5H136C139.866 78.5 143 75.366 143 71.5C143 67.634 139.866 64.5 136 64.5H79C75.134 64.5 72 67.634 72 71.5C72 75.366 75.134 78.5 79 78.5H39C35.134 78.5 32 81.634 32 85.5C32 89.366 35.134 92.5 39 92.5H64C67.866 92.5 71 95.634 71 99.5C71 103.366 67.866 106.5 64 106.5H24C20.134 106.5 17 109.634 17 113.5C17 117.366 20.134 120.5 24 120.5H63C59.134 120.5 56 123.634 56 127.5C56 131.366 59.134 134.5 63 134.5ZM226 134.5C229.866 134.5 233 131.366 233 127.5C233 123.634 229.866 120.5 226 120.5C222.134 120.5 219 123.634 219 127.5C219 131.366 222.134 134.5 226 134.5Z"
        fill="#F2F4F7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.119 112.807C113.04 113.36 113 113.925 113 114.5C113 121.127 118.373 126.5 125 126.5C131.627 126.5 137 121.127 137 114.5C137 113.925 136.96 113.36 136.881 112.807H166V139.5C166 141.157 164.657 142.5 163 142.5H87C85.3431 142.5 84 141.157 84 139.5V112.807H113.119Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M138 112.5C138 119.68 132.18 125.5 125 125.5C117.82 125.5 112 119.68 112 112.5C112 112.267 112.006 112.036 112.018 111.807H84L93.5604 83.5389C93.9726 82.3202 95.1159 81.5 96.4023 81.5H153.598C154.884 81.5 156.027 82.3202 156.44 83.5389L166 111.807H137.982C137.994 112.036 138 112.267 138 112.5Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M136.098 113.455C136.098 119.002 131.129 124.5 125 124.5C118.871 124.5 113.902 119.002 113.902 113.455C113.902 113.275 113.908 112.096 113.918 111.919H93L101.161 92.0755C101.513 91.1338 102.489 90.5 103.587 90.5H146.413C147.511 90.5 148.487 91.1338 148.839 92.0755L157 111.919H136.082C136.092 112.096 136.098 113.275 136.098 113.455Z"
        fill="#E8F4FB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M85.25 112.012V138.5C85.25 139.466 86.0335 140.25 87 140.25H163C163.966 140.25 164.75 139.466 164.75 138.5V112.012L155.255 83.9393C155.015 83.2285 154.348 82.75 153.598 82.75H96.4023C95.6519 82.75 94.985 83.2285 94.7446 83.9393L85.25 112.012Z"
        stroke="#D0D5DD"
        strokeWidth="2.5"
      />
      <path
        d="M98 111.5C101.937 111.5 106.185 111.5 110.745 111.5C112.621 111.5 112.621 112.819 112.621 113.5C112.621 120.127 118.117 125.5 124.897 125.5C131.677 125.5 137.173 120.127 137.173 113.5C137.173 112.819 137.173 111.5 139.05 111.5H164M90.5737 111.5H93H90.5737Z"
        stroke="#D0D5DD"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M150.1 58.8027L139 71.2559M124.1 54.5V71.2559V54.5ZM98 58.8027L109.1 71.2559L98 58.8027Z"
        stroke="#A4D4EF"
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default IconEmptyInbox
