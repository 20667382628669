export enum EquipmentClass {
  STRAIGHT_TRUCK = 'STRAIGHT_TRUCK',
  DRY_VAN = 'DRY_VAN',
  TANKER = 'TANKER',
  DUMP = 'DUMP',
  FLATBED = 'FLATBED',
}

export enum EquipmentType {
  VAN = 'VAN',
  REEFER = 'REEFER',
  TANK = 'TANK',
}
