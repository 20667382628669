import { Endorsement, LicenseClass } from 'src/graphql/types/licenses'

export const getEndorsementsByLicenseClass = (licenseClass) => {
  switch (licenseClass) {
    case LicenseClass.A:
      return [
        Endorsement.H,
        Endorsement.N,
        Endorsement.X,
        Endorsement.P,
        Endorsement.S,
        Endorsement.T,
      ]
    case LicenseClass.B:
      return [Endorsement.H, Endorsement.N, Endorsement.P, Endorsement.S]
    case LicenseClass.C:
      return [Endorsement.H, Endorsement.P, Endorsement.S]
    case LicenseClass.D:
      return [Endorsement.S, Endorsement.H, Endorsement.T, Endorsement.N]
    case LicenseClass.E:
      return [Endorsement.S, Endorsement.H, Endorsement.N]
    default:
      return [
        Endorsement.H,
        Endorsement.N,
        Endorsement.X,
        Endorsement.P,
        Endorsement.S,
        Endorsement.T,
      ]
  }
}
