import { CountryConfig } from 'src/lib/country.utils'

// Example: 123-45-6789 -> 123456789
export const unformatSsn = (value) => {
  if (!value) return ''
  return value.replace(/\D/g, '')
}

// Example: 123456789 -> 123-45-6789 (no form)
export const formatSsnString = (issuingCountry, ssn) => {
  let formattedValue = ''

  if (issuingCountry === CountryConfig.US.code) {
    formattedValue = formatUSASSN(ssn)
  } else if (issuingCountry === CountryConfig.MX.code) {
    formattedValue = ssn
  } else {
    // Default to USA format for unknown issuingCountry
    formattedValue = formatUSASSN(ssn)
  }

  return formattedValue
}

const formatUSASSN = (ssn) => {
  let formattedValue = ''

  const char = { 3: '-', 5: '-' }
  for (let i = 0; i < ssn.length; i++) {
    formattedValue += (char[i] || '') + ssn[i]
  }
  return formattedValue
}
