import { useState } from 'react'
import ApplicationScreenWrapper from '../components/ApplicationScreenWrapper'
import ApplicationSelectInput from '../components/ApplicationSelectInput'
import ApplicationTextInput from '../components/ApplicationTextInput'
import { useFormContext } from '../utils/FormContext'
import ApplicationRadioQuestion from '../components/ApplicationRadioQuestion'
import _ from 'lodash'
import { EquipmentClass } from 'src/graphql/types/drivingExperiences'
import { formatSnakeValue } from 'src/lib/formatters'

const equipmentTypes = Object.values(EquipmentClass).map((value) => ({
  value,
  label: formatSnakeValue(value),
}))

const defaultEquipment = {
  type: EquipmentClass.STRAIGHT_TRUCK,
  make: undefined,
  model: undefined,
  year: undefined,
  vin: undefined,
  weight: undefined,
  mileage: undefined,
  fifthWheelHeightInches: undefined,
}

export const EquipmentScreen = () => {
  const { form, goBack, handleNext, updateLoading, application } =
    useFormContext()
  const [hasEquipment, setHasEquipment] = useState(
    form.values.equipment.length > 0
  )

  return (
    <ApplicationScreenWrapper
      title={'Owner Equipment'}
      goBack={goBack}
      updateLoading={updateLoading}
      application={application}
      handleCancel={goBack}
      handleSubmit={() => {
        let parsedValues = []
        //Parse the values before submitting
        form.values.equipment.forEach((element) => {
          let temp = { ...element }
          temp.year = parseInt(temp.year)
          temp.weight = parseFloat(temp.weight)
          temp.mileage = parseFloat(temp.mileage)
          temp.fifthWheelHeightInches = parseFloat(temp.fifthWheelHeightInches)
          parsedValues.push(temp)
        })
        handleNext({
          equipment: [...parsedValues],
        })
      }}
    >
      <div className="w-full sm:col-span-3 sm:w-1/3">
        <ApplicationRadioQuestion
          required
          question="Do you have your own vehicle/equipment?"
          value={hasEquipment ? 'true' : 'false'}
          handleChange={(e) => {
            if (e === 'false') {
              setHasEquipment(false)
              form.setFieldValue('equipment', [])
            } else {
              setHasEquipment(true)
              form.setFieldValue('equipment', [
                ...form.values.equipment,
                defaultEquipment,
              ])
            }
          }}
        />
      </div>

      {hasEquipment && (
        <>
          <ApplicationSelectInput
            label="What type of vehicle do you have?"
            placeholder={'Equipment Type'}
            data={equipmentTypes}
            value={form.values.equipment[0].type}
            error={form.errors[`equipment.${0}.type`] ?? ''}
            onChange={(event) => {
              form.setFieldValue(`equipment.${0}.type`, event.target.value)
            }}
          />
          <ApplicationTextInput
            label="What is the vehicle's make?"
            placeholder={'Make'}
            {...form.getInputProps(`equipment.${0}.make`)}
          />
          <ApplicationTextInput
            label="What is the vehicle's model?"
            placeholder={'Model'}
            {...form.getInputProps(`equipment.${0}.model`)}
          />
          <ApplicationTextInput
            label="What is the vehicle's year?"
            type="number"
            inputMode="numeric"
            placeholder={'Year'}
            {...form.getInputProps(`equipment.${0}.year`)}
          />
          <ApplicationTextInput
            label="What is the vehicle's mileage?"
            type="number"
            inputMode="numeric"
            placeholder={'Mileage'}
            {...form.getInputProps(`equipment.${0}.mileage`)}
          />
          <ApplicationTextInput
            label="What is the vehicle's VIN?"
            placeholder={'VIN'}
            {...form.getInputProps(`equipment.${0}.vin`)}
          />
          <ApplicationTextInput
            label="What is the vehicle's weight in pounds?"
            type="number"
            inputMode="decimal"
            placeholder={'Weight'}
            {...form.getInputProps(`equipment.${0}.weight`)}
          />
          <ApplicationTextInput
            label="What is the vehicle's height in inches?"
            type="number"
            inputMode="decimal"
            placeholder={'Fifth Wheel Height (inches)'}
            {...form.getInputProps(`equipment.${0}.fifthWheelHeightInches`)}
          />
        </>
      )}
    </ApplicationScreenWrapper>
  )
}
