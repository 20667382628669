import { useEffect, useState } from 'react'

import { Avatar, Drawer, UnstyledButton } from '@mantine/core'
import { useDisclosure, useHotkeys } from '@mantine/hooks'

import { routes } from '@redwoodjs/router'

import { useAuth } from 'src/auth'
import Header from 'src/components/Navigation/Header/Header'
import SidebarLink from 'src/components/Navigation/SidebarLink/SidebarLink'
import Notifications from 'src/components/Notifications/Notifications'
import { PhoneDeviceModal } from 'src/components/Overlays/PhoneDeviceModal/PhoneDeviceModal'
import IconApplicant from 'src/icons/IconApplicant'
import IconDashboard from 'src/icons/IconDashboard'
import IconEmployment from 'src/icons/IconEmployment'
import IconJobs from 'src/icons/IconJobs'
import IconRightArrows from 'src/icons/IconRightArrows'
import IconSettings from 'src/icons/IconSettings'
import Sentry from 'src/lib/sentry'

import SidebarCompanyMenu from './components/SidebarCompanyMenu'
import { Logo, Name } from 'src/icons'
import AdminContainer from 'src/components/AdminContainer/AdminContainer'
import IconPieChart from 'src/icons/IconPieChart'
import SearchModal from 'src/components/Overlays/SearchModal/SearchModal'
import DashboardContexts from 'src/context/DashboardContexts'

type DashboardLayoutProps = {
  children?: React.ReactNode
}

const DashboardLayout = ({ children }: DashboardLayoutProps) => {
  const { currentUser } = useAuth()
  const [drawerOpened, drawerHandlers] = useDisclosure(false)
  const expanded = localStorage.getItem('sidebarExpanded')
  const [sidebarExpanded, setSidebarExpanded] = useState(expanded === 'true')
  const [showSidebarOnMobile, setShowSidebarOnMobile] = useState(false)
  const [opened, { open, close }] = useDisclosure(false)

  useEffect(() => {
    localStorage.setItem('sidebarExpanded', sidebarExpanded.toString())
  }, [sidebarExpanded])

  useEffect(() => Sentry.setUser(currentUser), [currentUser])

  useHotkeys([['mod+K', () => open()]])

  return (
    <DashboardContexts>
      <div className="flex h-screen flex-row overflow-y-hidden bg-doubleNickel-gray-100">
        {drawerOpened && (
          <Drawer
            opened={drawerOpened}
            offset={8}
            radius="md"
            withCloseButton={true}
            onClose={drawerHandlers.close}
            position="right"
            size="30vw"
            removeScrollProps={{ enabled: true, removeScrollBar: false }}
            lockScroll={false}
            title="Notifications"
            overlayProps={{
              backgroundOpacity: 0.2,
              // blur: 2,
            }}
            classNames={{
              title: 'text-base font-semibold text-doubleNickel-gray-900 ',
              // content: 'p-0 h-full',
              // root: 'p-0 h-full ',
              // inner: 'p-0 h-full',
            }}
          >
            <div className="h-[95vh]">
              <Notifications />
            </div>
          </Drawer>
        )}

        <div
          className={`flex h-full flex-col items-center bg-doubleNickel-gray-100 px-3 py-2 transition-all duration-500
        ${sidebarExpanded ? 'w-[13.25rem]' : 'w-[4rem]'}
        ${!showSidebarOnMobile && 'hidden sm:flex'} `}
        >
          <SidebarCompanyMenu sidebarExpanded={sidebarExpanded} />

          <div className="mt-10 flex flex-col gap-3">
            <SidebarLink
              label="Dashboard"
              route={routes.dashboard()}
              icon={IconDashboard}
              expanded={sidebarExpanded}
            />
            <SidebarLink
              label="Applicants"
              route={routes.applicants()}
              icon={IconApplicant}
              expanded={sidebarExpanded}
            />
            <SidebarLink
              label="Job Listings"
              route={routes.jobs()}
              icon={IconJobs}
              expanded={sidebarExpanded}
            />
            <SidebarLink
              label="VoE"
              route={routes.voeRequests()}
              icon={IconEmployment}
              expanded={sidebarExpanded}
            />
            <SidebarLink
              label="Analytics"
              route={routes.analytics()}
              icon={IconPieChart}
              expanded={sidebarExpanded}
            />
            <SidebarLink
              label="Settings"
              route={routes.settings()}
              icon={IconSettings}
              expanded={sidebarExpanded}
            />
          </div>
          <div className="mt-auto flex w-full flex-col items-center gap-6">
            <UnstyledButton
              onClick={() => setSidebarExpanded((prev) => !prev)}
              className={`mb-6 ${sidebarExpanded ? 'ml-auto' : ''} `}
            >
              <IconRightArrows
                className={`fill-none stroke-doubleNickel-gray-500 transition-all duration-500 ${
                  sidebarExpanded ? 'rotate-180' : ''
                }`}
              />
            </UnstyledButton>
          </div>
          <div
            className={`mb-6 flex flex-row items-center justify-center ${
              sidebarExpanded && 'gap-2'
            }`}
          >
            <Avatar
              src={Logo}
              radius={'sm'}
              size={40}
              alt="Double Nickel Logo"
            />
            <img
              src={Name}
              alt="Double Nickel Logo Name"
              className={`transition-all ${sidebarExpanded ? 'h-10' : 'h-0'}`}
            />
          </div>
        </div>
        <div className="m-2 flex flex-1 flex-col gap-1 rounded-lg">
          <Header
            toggleNotifications={drawerHandlers}
            showSidebarOnMobile={showSidebarOnMobile}
            setShowSidebarOnMobile={setShowSidebarOnMobile}
            openSearch={open}
          />
          <div className="h-[93%] overflow-auto rounded-lg bg-white pt-5">
            {children}
          </div>
        </div>
        <PhoneDeviceModal />
        {opened && <SearchModal opened={opened} close={close} />}
      </div>
    </DashboardContexts>
  )
}

export default DashboardLayout
