import { Box, Radio } from '@mantine/core'

const ApplicationRadioQuestion = ({
  question,
  defaultValue = undefined,
  value,
  handleChange,
  ...props
}) => {
  return (
    <div className="flex w-full flex-col items-start py-3">
      <Radio.Group
        label={question}
        defaultValue={defaultValue}
        value={value}
        classNames={{
          root: 'flex flex-col w-full',
          label: 'text-sm font-medium text-doubleNickel-gray-600 h-11',
        }}
        {...props}
      >
        <div className="flex flex-row gap-3">
          <Box
            onClick={() => handleChange('true')}
            className={`shadow-xs flex-1 rounded-lg  border px-4 py-3 hover:border-doubleNickel-brand-500 ${
              value === 'true'
                ? 'border-doubleNickel-brand-500'
                : 'border-doubleNickel-gray-300'
            }`}
          >
            <Radio value="true" label="Yes" />
          </Box>
          <Box
            onClick={() => handleChange('false')}
            className={`shadow-xs flex-1 rounded-lg border px-4 py-3 hover:border-doubleNickel-brand-500 ${
              value === 'false'
                ? 'border-doubleNickel-brand-500'
                : 'border-doubleNickel-gray-300'
            }`}
          >
            <Radio value="false" label="No" />
          </Box>
        </div>
      </Radio.Group>
    </div>
  )
}

export default ApplicationRadioQuestion
