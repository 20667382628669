import { useState } from 'react'

import { useLazyQuery } from '@apollo/client'
import { ActionIcon, Group, Loader, Menu, Text } from '@mantine/core'
import { Dropzone, IMAGE_MIME_TYPE, PDF_MIME_TYPE } from '@mantine/dropzone'

import { useQuery } from '@redwoodjs/web'

import StyledMenu from 'src/components/Overlays/StyledMenu/StyledMenu'
import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  GET_TEXT_TEMPLATES,
  RESOLVE_TEXT_TEMPLATE,
} from 'src/graphql/textTemplates.graphql'
import IconAdd from 'src/icons/IconAdd'
import IconAlertCircle from 'src/icons/IconAlertCircle'
import IconChevronLeft from 'src/icons/IconChevronLeft'
import IconUpload from 'src/icons/IconUpload'

const MEGABYTE_TO_BYTE = 1024 ** 2
const MEGABYTE_LIMIT = 5

const SMSInputMenu = ({
  applicantId,
  selectedFiles,
  setSelectedFiles,
  onTemplateSelect,
}) => {
  const [menuType, setMenuType] = useState('default')
  const {
    data: textTemplatesList,
    loading: textTemplatesListLoading,
    error: textTemplatesListError,
  } = useQuery(GET_TEXT_TEMPLATES, {
    variables: {
      pageInfo: {
        limit: 100,
        offset: 0,
      },
      orderBy: {
        field: 'title',
        direction: 'asc',
      },
    },
  })

  const [resolveTextTemplate] = useLazyQuery(RESOLVE_TEXT_TEMPLATE, {
    onCompleted: (results) => {
      onTemplateSelect && onTemplateSelect(results.resolveTextTemplate.content)
    },
    onError: (error) => toast(error.message, 'error'),
  })

  const handleItemClick = (item) => {
    resolveTextTemplate({
      variables: { textTemplateId: item.textTemplateId, applicantId },
    })
  }

  const onDrop = (files) => {
    const newFiles = files.filter((file) => {
      return !selectedFiles.some(
        (selectedFile) => selectedFile.name === file.name
      )
    })
    //Confirm that the total number of files is less than or equal to 10
    if (selectedFiles.length + newFiles.length > 10 || newFiles.length > 10) {
      toast('You can only upload a maximum of 10 files', 'error')
      return
    }

    //Confirm that the total size of the files is less than or equal to 5MB
    const totalSize =
      selectedFiles.reduce((acc, file) => acc + file.size, 0) +
      newFiles.reduce((acc, file) => acc + file.size, 0)
    if (totalSize > MEGABYTE_LIMIT * MEGABYTE_TO_BYTE) {
      toast(
        `Total file size exceeds the maximum limit of ${MEGABYTE_LIMIT}MB`,
        'error'
      )
      return
    }

    setSelectedFiles([...selectedFiles, ...newFiles])
  }

  const onReject = (fileRejections) => {
    fileRejections.every((rejection) => {
      if (rejection.errors[0].code === 'file-too-large') {
        toast(
          `File size exceeds the maximum limit of ${MEGABYTE_LIMIT}MB`,
          'error'
        )
        return true
      } else if (rejection.errors[0].code === 'too-many-files') {
        toast('You can only upload a maximum of 10 files', 'error')
        return false
      } else {
        toast(`Unknown error occurred: ${rejection.errors[0].code}`, 'error')
        return true
      }
    })
  }

  const renderItems = () => {
    if (textTemplatesList?.textTemplates?.items?.length) {
      return textTemplatesList.textTemplates.items.map((item) => (
        <Menu.Item
          key={item.textTemplateId}
          onClick={() => handleItemClick(item)}
          title={item.title}
        >
          {item.title}
        </Menu.Item>
      ))
    }

    if (textTemplatesListLoading) {
      return (
        <Menu.Item>
          <span className="flex flex-row items-center">
            <Loader size={16} />
            &nbsp; Loading...
          </span>
        </Menu.Item>
      )
    }

    if (textTemplatesListError) {
      return (
        <Menu.Item>
          <span className="flex flex-row items-center text-doubleNickel-error-500">
            <IconAlertCircle className="fill-none stroke-doubleNickel-error-500" />
            &nbsp; Something went wrong
          </span>
        </Menu.Item>
      )
    }

    return <Menu.Item>No templates found</Menu.Item>
  }

  return (
    <StyledMenu width={250}>
      <Menu.Target>
        <ActionIcon variant="subtle">
          <IconAdd className={'fill-none stroke-doubleNickel-gray-500'} />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        {menuType === 'default' && (
          <>
            <Menu.Item
              rightSection={<IconChevronLeft className="rotate-180" />}
              closeMenuOnClick={false}
              onClick={() => {
                setMenuType('template')
              }}
            >
              Select a template
            </Menu.Item>
            <Menu.Item
              closeMenuOnClick={false}
              onClick={() => {
                setMenuType('upload')
              }}
            >
              Upload from my computer
            </Menu.Item>
          </>
        )}
        {menuType === 'template' && (
          <>
            <Menu.Item
              leftSection={<IconChevronLeft />}
              onClick={() => setMenuType('default')}
              closeMenuOnClick={false}
            >
              Back
            </Menu.Item>
            {renderItems()}
          </>
        )}
        {menuType === 'upload' && (
          <>
            <Menu.Item
              leftSection={<IconChevronLeft />}
              onClick={() => setMenuType('default')}
              closeMenuOnClick={false}
            >
              Back
            </Menu.Item>
            <Dropzone
              accept={[...IMAGE_MIME_TYPE, ...PDF_MIME_TYPE]}
              maxFiles={10}
              maxSize={MEGABYTE_LIMIT * MEGABYTE_TO_BYTE}
              onDrop={onDrop}
              onReject={onReject}
            >
              <Group className="flex flex-col">
                <Dropzone.Accept>
                  <p>Drag and drop the file here</p>
                </Dropzone.Accept>
                <Dropzone.Reject>
                  <p>File does not meet requirements</p>
                </Dropzone.Reject>
                <Dropzone.Idle>
                  <ActionIcon
                    variant="subtle"
                    className="h-10 w-10 rounded-lg border border-doubleNickel-gray-200 bg-doubleNickel-white shadow-sm"
                  >
                    <IconUpload className="fill-none stroke-doubleNickel-gray-700" />
                  </ActionIcon>
                </Dropzone.Idle>
                <div className="flex flex-col items-center gap-1">
                  <Text className="text-sm text-doubleNickel-gray-600">
                    <span className="font-semibold text-doubleNickel-brand-500">
                      {'Click to upload '}
                    </span>
                    or drag and drop
                  </Text>
                  <Text
                    size="sm"
                    className="text-xs text-doubleNickel-gray-600"
                  >
                    PDF, PNG, JPG or JPEG (Max {MEGABYTE_LIMIT}MB)
                  </Text>
                </div>
              </Group>
            </Dropzone>
          </>
        )}
      </Menu.Dropdown>
    </StyledMenu>
  )
}

export default SMSInputMenu
